import _ from 'lodash';
import { createSelector } from 'reselect';

import CadminCompaniesAx from 'app/actions/company-admin/companies';
import ToastAx           from 'app/actions/toast';
import history           from 'app/history';
import reducerUtils      from 'app/reducers/utils';
import CadminSlx         from 'app/selectors/company-admin/';
import EntitiesSlx       from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_MS_TEAMS_SETTINGS_LOAD',
};

const Ax = {

  load: ({params}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(params.companySlug)),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
};

const reducer = reducerUtils.createReducer(initialState, {

  // [`${Types.LOAD}_PENDING`]: (state, action) => {
  //   return {...state,
  //   };
  // },
  // [`${Types.LOAD}_RESOLVED`]: (state, action) => {
  //   return {...state,
  //   };
  // },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selFoo = (state) => state.companyAdmin.pageMsTeamsSettings.foo;

  return {
    foo: selFoo,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
