
import utils from 'app/helpers/utils';

const editAttrs = [
  'name',
  'description',
  'bannerImgPath',
  'logoImgPath',
  'highlights',
  'stats',
  'resources',
  'nonprofitIds',
  'volEventIds',
  'campaignIds',
  'groupTypeId',
];

const textAttrs = [
  'name',
  'description',
  'bannerImgPath',
  'logoImgPath',
];

// {imgPath: "uploaded-input/....png", text: "lorem ipsum"}
const getHighlightValidations = (highlight) => {
  const validations = {};
  const hasImg = !!(highlight.imgPath || '').trim();
  const hasText = !!(highlight.text || '').trim();
  if (hasText && !hasImg) validations['imgPath'] = ['required'];
  if (hasImg && !hasText) validations['text'] = ['required'];
  return Object.keys(validations).length
    ? validations
    : null;
};

// {number: "42%", text: "lorem ipsum"}
const getStatValidations = (stat) => {
  const validations = {};
  const hasNum = !!(stat.number || '').trim();
  const hasText = !!(stat.text || '').trim();
  if (hasText && !hasNum) validations['number'] = ['required'];
  if (hasNum && !hasText) validations['text'] = ['required'];
  return Object.keys(validations).length
    ? validations
    : null;
};

const getResourceValidations = (resource) => {
  const validations = {};
  const hasUrl = !!(resource.url || '').trim();
  const urlValid = !!(!hasUrl || utils.isUrl(resource.url));
  if (!hasUrl) validations['url'] = ['required'];
  if (!urlValid) validations['url'] = ['must be valid URL'];
  return Object.keys(validations).length
    ? validations
    : null;
};

export default {
  editAttrs,
  textAttrs,
  getHighlightValidations,
  getStatValidations,
  getResourceValidations,
};
