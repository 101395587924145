import { createSelector } from 'reselect';

// import CadminSlx   from 'app/selectors/company-admin/';
import BuilderSlx  from 'app/selectors/company-admin/builder-group';
import EntitiesSlx from 'app/selectors/entities';

const selGroup = createSelector(
  [BuilderSlx.saveAttrs, EntitiesSlx.volEvents],
  (saveAttrs, volEvents) => {
    return {
      ...saveAttrs,
      volEvents: saveAttrs.volEventIds.map(id => volEvents[id]).filter(ve => ve),
    };
  }
);

export default {
  group: selGroup,
};
