import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import ConnectorLine     from 'app/components/common/connector-line';
import Icon              from 'app/components/common/icon';
import CadminLayout      from 'app/components/company-admin/layout/';
import PageLoading       from 'app/components/layout/page-loading';
import paths             from 'app/paths';
import CadminSlx         from 'app/selectors/company-admin/';

class PageCadminMsTeamsSettings extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { company } = this.props;
    if (!company) return <PageLoading />;

    const consentedAtText = company.teamsAdminConsentedAt
      ? moment(company.teamsAdminConsentedAt).format('MMMM D YYYY, h:mm:ss a')
      : 'Never';

    return (
      <CadminLayout className="ca-ms-teams" company={company} activeItem="ms-teams">
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Teams Settings</h1>
          </div>

          <div className="ca-box-body">
            <p>
              The Millie Teams integration uses Microsoft Graph to perform operations on your company's data, including:
            </p>
            <ul>
              <li>Adding teams and channels to your Teams environment;</li>
              <li>Adding a Teams bot to your Millie teams;</li>
              <li>Obtaining the name and email address of your Teams users.</li>
            </ul>
            <p>
              An employee at your company with the Azure AD <strong>Global Administrator</strong> role must grant permission for Millie to perform these operations. When you are ready to do that, click the <strong>Admin Consent</strong> button; you will be directed to a Microsoft server where you can login and accept the request.
            </p>
            <p>
              After you consent, the <strong>Millie Teams</strong> app will be added to your <strong>Enterprise Applications</strong> within Azure AD. That app must remain in your directory for as long as you use the Millie Teams integration.
            </p>

            <div className="ca-ms-teams-consent">
              <a href={paths.cadminMsTeamsConsent(company.id)} className="btn special slack">
                Admin Consent
              </a>

              <table className="ca-keyvals"><tbody>
                <tr>
                  <th>Last Consented</th>
                  <td>{consentedAtText}</td>
                </tr>
              </tbody></table>
            </div>

            <p>
              After consenting, contact <a href="mailto:tech@milliegiving.com">tech@milliegiving.com</a> to continue the Teams setup process.
            </p>
          </div>
        </div>
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
});

const dispatchToProps = (dispatch) => ({
});

export default connect(stateToProps, dispatchToProps)(PageCadminMsTeamsSettings);
