
const selUpdatingAvatar      = state => state.account.updatingAvatar;
const selUpdatingAccount     = state => state.account.updatingAccount;
const selEmailChangeSent     = state => state.account.emailChangeSent;
const selValidations         = state => state.account.validations;
const selSetNewPwPending     = state => state.account.setNewPwPending;
const selSetNewPwSuccess     = state => state.account.setNewPwSuccess;
const selSetNewPwFailed      = state => state.account.setNewPwFailed;
const selSetNewPwValidations = state => state.account.setNewPwValidations;

export default {
  updatingAvatar: selUpdatingAvatar,
  updatingAccount: selUpdatingAccount,
  emailChangeSent: selEmailChangeSent,
  validations: selValidations,
  setNewPwPending: selSetNewPwPending,
  setNewPwSuccess: selSetNewPwSuccess,
  setNewPwFailed: selSetNewPwFailed,
  setNewPwValidations: selSetNewPwValidations,
};
