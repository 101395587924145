
import CadminBankAccountsAx from 'app/actions/company-admin/bank-accounts';
import CadminCompaniesAx    from 'app/actions/company-admin/companies';
import CadminCreditCardsAx  from 'app/actions/company-admin/credit-cards';
import NonprofitsAx         from 'app/actions/nonprofits';
import history              from 'app/history';
import paths                from 'app/paths';
import CadminSlx            from 'app/selectors/company-admin/';
import PageSlx              from 'app/selectors/company-admin/page-grants-new';
import EntitiesSlx          from 'app/selectors/entities';

const Types = {
  LOAD: 'CADMIN_PAGE_GRANTS_NEW_LOAD',
};

const Actions = {

  load: ({params: {companySlug}, query}) => (dispatch, getState) => {
    const state = getState();
    const company = CadminSlx.company(state);
    const nonprofit = EntitiesSlx.nonprofitById(state, query.nonprofitId);
    const paymentMethodLabel = PageSlx.paymentMethodLabel(state);
    const promises = [];
    if (!company) {
      promises.push(dispatch(CadminCompaniesAx.get(companySlug)));
    }
    if (query.nonprofitId && !nonprofit) {
      promises.push(dispatch(NonprofitsAx.get(query.nonprofitId)));
    }
    if (!paymentMethodLabel) {
      promises.push(dispatch(CadminBankAccountsAx.fetch(companySlug)));
      promises.push(dispatch(CadminCreditCardsAx.fetch(companySlug)));
    }
    const promise = Promise.all(promises);
    return dispatch({type: Types.LOAD, promise});
  },

  setNonprofit: (nonprofit) => (dispatch, getState) => {
    dispatch(NonprofitsAx.set(nonprofit));
    const companySlug = CadminSlx.companySlug(getState());
    const path = paths.cadminGrantsCreate(companySlug, {nonprofitId: nonprofit.id});
    history.push(path);
  },

};

export {
  Types,
};

export default Actions;
