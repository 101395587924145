
import CadminCompaniesAx         from 'app/actions/company-admin/companies';
import CadminGroupsAx            from 'app/actions/company-admin/groups';
import CadminVolEventsDuck       from 'app/ducks/company-admin/vol-events';
import paths                     from 'app/paths';
import history                   from 'app/history';
import CadminSlx                 from 'app/selectors/company-admin/';
import PageSlx                   from 'app/selectors/company-admin/page-vol-events';

const Types = {
  LOAD: 'CADMIN_PAGE_VOL_EVENTS_LOAD',
  VIEW_SLACK: 'CADMIN_PAGE_VOL_EVENTS_VIEW_SLACK',
  VIEW_MS_TEAMS: 'CADMIN_PAGE_VOL_EVENTS_VIEW_MS_TEAMS',
};

const Actions = {

  load: ({params: {companySlug}, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(companySlug)),
      dispatch(CadminVolEventsDuck.Ax.search(companySlug, query)),
      ...(query.groupId ? [
        dispatch(CadminGroupsAx.get(companySlug, query.groupId))
      ] : []),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

  viewSlack: (volEventId) => {
    return {type: Types.VIEW_SLACK, volEventId};
  },

  viewMsTeams: (volEventId) => {
    return {type: Types.VIEW_MS_TEAMS, volEventId};
  },

  setSearchParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentParams = PageSlx.searchParams(state);
    const params = {...currentParams, ...newParams};
    const path = paths.cadminVolEvents(companySlug, params);
    history.push(path);
  },

};

export {
  Types,
};

export default Actions;
