import PropTypes from 'prop-types';
import React from 'react';

import DateRangePicker  from 'app/components/common/date-range-picker';
import Icon             from 'app/components/common/icon';
import SectionCampaigns from 'app/components/company-dashboards/section-campaigns'
import SectionCommunity from 'app/components/company-dashboards/section-community';
import SectionGift      from 'app/components/company-dashboards/section-gift';
import SectionGrant     from 'app/components/company-dashboards/section-grant';
import SectionMatch     from 'app/components/company-dashboards/section-match';
import SectionTopStats  from 'app/components/company-dashboards/section-top-stats';
import SectionVolunteer from 'app/components/company-dashboards/section-volunteer';
import config           from 'app/config';
import companiesHelpers from 'app/helpers/companies';
import AuthSlx          from 'app/selectors/auth';

class CompanyDashboard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onSelectDateRange = this.onSelectDateRange.bind(this);
  }

  onSelectDateRange({startDateStr: startDate, endDateStr: endDate}) {
    this.props.onChangeDateRange && this.props.onChangeDateRange({startDate, endDate});
  }

  renderMission({company, dashboard}) {
    const logoUrl = dashboard.logoPath
      ? encodeURI(`${config.s3BaseUrl}/${dashboard.logoPath}`)
      : company.logoUrl;
    return (
      <div className="cdash-mission">
        {logoUrl && (
          <img className="cdash-mission-logo" src={logoUrl} alt={company.name} />
        )}
        {!!dashboard.missionText && (
          <div className="cdash-mission-blurb">
            {dashboard.missionText.split('\n').map((p, i) => <p key={i}>{p}</p>)}
          </div>
        )}
      </div>
    );
  }

  renderDateRange(company, data) {
    const currentFiscalYear = companiesHelpers.getCurrentFiscalYear(company);
    return (
      <div className="cdash-year">
        <DateRangePicker
          onSelect={this.onSelectDateRange}
          currentFiscalYear={currentFiscalYear}
          startDateStr={data.startDate}
          endDateStr={data.endDate}
        />
      </div>
    );
  }

  render() {
    const { isLoading, company, dashboard, data } = this.props;
    const showLoading = isLoading || !company || !dashboard || !data;
    return (
      <div className="cdash">
        <div className="cdash-widther">
          {showLoading ? (
            <Icon.Loading className="cdash-loading" />
          ) : (<>
            {this.renderMission({company, dashboard})}
            {this.renderDateRange(company, data)}
            <SectionTopStats {...{dashboard, data}} />
            <SectionCampaigns {...{dashboard}} />
            <SectionMatch {...{dashboard, data}} />
            <SectionGrant />
            <SectionGift {...{dashboard, data}} />
            <SectionVolunteer {...{dashboard, data}} />
            <SectionCommunity {...{company, dashboard, data}} />
          </>)}
        </div>
      </div>
    );
  }

}

CompanyDashboard.propTypes = {
  onChangeDateRange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  company: PropTypes.object,
  dashboard: PropTypes.object,
  data: PropTypes.object,
};

CompanyDashboard.defaultProps = {
  isLoading: false,
};

export default CompanyDashboard;
