import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';

class CadminBuilderIterable extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickDelete = this.onClickDelete.bind(this);
  }

  onClickDelete() {
    this.props.onDelete(this.props.index);
  }

  render() {
    const { children, className } = this.props;

    return (
      <div className={`cadmin-builder-iterable ${className}`}>
        <div className="cadmin-builder-iterable-actions">
          <button className="cadmin-builder-iterable-actions-delete" onClick={this.onClickDelete}>
            <Icon.Bin />
          </button>
        </div>
        <div className="cadmin-builder-iterable-content">
          {children}
        </div>
      </div>
    );
  }

}

CadminBuilderIterable.propTypes = {
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

CadminBuilderIterable.defaultProps = {
  className: '',
};

export default CadminBuilderIterable;
