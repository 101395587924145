import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Link          from 'app/components/common/link';
import Modal         from 'app/components/common/modal';
import StandardInput from 'app/components/common/standard-input';
import ModalChannel  from 'app/components/company-admin/ms-teams/modal-channel';
import config        from 'app/config';
import VolEventsDuck from 'app/ducks/company-admin/vol-events';
import paths         from 'app/paths';
import CadminSlx     from 'app/selectors/company-admin/';

class ModalTeamsChannelEvent extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onUnlink = this.onUnlink.bind(this);
    this.onClickAnnounce = this.onClickAnnounce.bind(this);
    this.onClickRemind = this.onClickRemind.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  onCreate(teamsChannel) {
    const { company, volEvent, update } = this.props;
    return update(company.id, volEvent.id, {teamsChannelId: teamsChannel.id});
  }

  onUnlink() {
    const { company, volEvent, update } = this.props;
    return update(company.id, volEvent.id, {teamsChannelId: null});
  }

  onClickAnnounce(event) {
    event.target.disabled = true;
    const {volEvent, announce} = this.props;
    announce(volEvent.id);
  }

  onClickRemind(event) {
    event.target.disabled = true;
    const {volEvent, remind} = this.props;
    remind(volEvent.id);
  }

  renderSubHeader() {
    const { volEvent } = this.props;
    return (
      <div className="ca-modal-schannel-subheader">
        <img src={`${config.s3BaseUrl}/${volEvent.imgPath}`} />
        {volEvent.title}
      </div>
    );
  }

  renderActions() {
    const {company, volEvent} = this.props;
    const announceDisabled = !company.isMsTeamsSetup;
    const remindDisabled = !volEvent.teamsChannelId;
    return (<>
      <div className={`ca-modal-schannel-action ${announceDisabled ? 'disabled' : ''}`}>
        <p>Announce this opportunity in the main Teams channel</p>
        <button onClick={this.onClickAnnounce} className="btn special slack secondary" disabled={announceDisabled}>Post Announcement</button>
      </div>
      <div className={`ca-modal-schannel-action ${remindDisabled ? 'disabled' : ''}`}>
        <p>Post a reminder to the opportunity's Teams channel</p>
        <button onClick={this.onClickRemind} className="btn special slack secondary" disabled={remindDisabled}>Post Reminder</button>
      </div>
    </>);
  }

  render() {
    const { volEvent } = this.props;

    return (
      <ModalChannel
        title="Volunteer Opportunity Settings"
        onClose={this.onClose}
        onCreate={this.onCreate}
        onUnlink={this.onUnlink}
        id={volEvent.teamsChannelId}
        subHeader={this.renderSubHeader()}
        defaultName={volEvent.title}
        actions={this.renderActions()}
      />
    );
  }

}

ModalTeamsChannelEvent.propTypes = {
  onClose: PropTypes.func.isRequired,
  volEvent: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
});

const dispatchToProps = (dispatch) => ({
  update: (companyId, volEventId, attrs) => dispatch(VolEventsDuck.Ax.update(companyId, volEventId, attrs)),
  announce: (volEventId) => dispatch(VolEventsDuck.Ax.announceInSlack(undefined, volEventId)),
  remind: (volEventId) => dispatch(VolEventsDuck.Ax.remindInSlack(undefined, volEventId)),
});

export default connect(stateToProps, dispatchToProps)(ModalTeamsChannelEvent);
