import _ from 'lodash';

import { Types } from 'app/actions/auth';
import utils from 'app/reducers/utils';

const initialState = {
  currentUserId: null,
  isMasquerading: false,
  loginPending: false,
  loginFailed: false,
  loginValidations: null,
  loginMustReset: false,
  ssoLoginPending: false,
  ssoLoginFailed: false,
  ssoLoginValidations: null,
  signupPending: false,
  signupFailed: false,
  signupValidations: null,
  resendEvPending: false,
  resendEvFailed: false,
  resendEvSuccess: false,
  sendResetPending: false,
  sendResetSuccess: false,
  sendResetFailed: false,
  sendResetValidations: null,
  resendInvitePending: false,
  resendInviteSuccess: false,
  resendInviteFailed: false,
  resendInviteValidations: null,
  empEmailSignupPending: false,
  empEmailSignupSuccess: null,
  empEmailSignupFailed: false,
  empEmailSignupValidations: null,
};

const reducer = utils.createReducer(initialState, {

  [Types.SET_CURRENT_USER]: (state, {currentUser, isMasquerading}) => {
    return {...state,
      currentUserId: currentUser ? currentUser.id : null,
      isMasquerading,
    };
  },

  [`${Types.GET_CURRENT_USER}_RESOLVED`]: (state, {result: {currentUser}}) => {
    return {...state,
      currentUserId: currentUser ? currentUser.id : null,
    };
  },

  [`${Types.PW_LOGIN}_PENDING`]: (state, _action) => {
    return {...state,
      loginPending: true,
      loginMustReset: false,
      loginFailed: false,
      loginValidations: null,
    };
  },
  [`${Types.PW_LOGIN}_RESOLVED`]: (state, {result: {currentUser}}) => {
    return {...state,
      loginPending: false,
      loginMustReset: false,
      loginFailed: false,
      loginValidations: null,
      currentUserId: currentUser.id,
    };
  },
  [`${Types.PW_LOGIN}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    const loginMustReset = !!_.get(error, 'response.data.error.resetRequired', null);
    return {...state,
      loginPending: false,
      loginMustReset,
      loginFailed: true,
      loginValidations: validations,
    };
  },

  [`${Types.SSO_LOGIN}_PENDING`]: (state, _action) => {
    return {...state,
      ssoLoginPending: true,
      ssoLoginFailed: false,
      ssoLoginValidations: null,
    };
  },
  // [`${Types.SSO_LOGIN}_RESOLVED`]: (state, action) => {
  //   return {...state,
  //     ssoLoginPending: false,
  //     ssoLoginFailed: false,
  //     ssoLoginValidations: null,
  //   };
  // },
  [`${Types.SSO_LOGIN}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      ssoLoginPending: false,
      ssoLoginFailed: true,
      ssoLoginValidations: validations,
    };
  },

  [`${Types.SIGN_UP}_PENDING`]: (state, _action) => {
    return {...state,
      signupPending: true,
      signupFailed: false,
      signupValidations: null,
    };
  },
  [`${Types.SIGN_UP}_RESOLVED`]: (state, {result: {currentUser}}) => {
    return {...state,
      signupPending: false,
      signupFailed: false,
      signupValidations: null,
      currentUserId: currentUser.id,
    };
  },
  [`${Types.SIGN_UP}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      signupPending: false,
      signupFailed: true,
      signupValidations: validations,
    };
  },

  [`${Types.RESEND_EV}_PENDING`]: (state, _action) => {
    return {...state,
      resendEvPending: true,
      resendEvFailed: false,
      resendEvSuccess: false,
    };
  },
  [`${Types.RESEND_EV}_RESOLVED`]: (state, _action) => {
    return {...state,
      resendEvPending: false,
      resendEvFailed: false,
      resendEvSuccess: true,
    };
  },
  [`${Types.RESEND_EV}_REJECTED`]: (state, _action) => {
    return {...state,
      resendEvPending: false,
      resendEvFailed: true,
      resendEvSuccess: false,
    };
  },

  [`${Types.SEND_PW_RESET}_PENDING`]: (state, _action) => {
    return {...state,
      sendResetPending: true,
      sendResetSuccess: false,
      sendResetFailed: false,
      sendResetValidations: null,
    };
  },
  [`${Types.SEND_PW_RESET}_RESOLVED`]: (state, _action) => {
    return {...state,
      sendResetPending: false,
      sendResetSuccess: true,
      sendResetFailed: false,
      sendResetValidations: null,
    };
  },
  [`${Types.SEND_PW_RESET}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      sendResetPending: false,
      sendResetSuccess: false,
      sendResetFailed: true,
      sendResetValidations: validations,
    };
  },

  [`${Types.RESEND_EMP_INVITE}_PENDING`]: (state, _action) => {
    return {...state,
      resendInvitePending: true,
      resendInviteSuccess: false,
      resendInviteFailed: false,
      resendInviteValidations: null,
    };
  },
  [`${Types.RESEND_EMP_INVITE}_RESOLVED`]: (state, _action) => {
    return {...state,
      resendInvitePending: false,
      resendInviteSuccess: true,
      resendInviteFailed: false,
      resendInviteValidations: null,
    };
  },
  [`${Types.RESEND_EMP_INVITE}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      resendInvitePending: false,
      resendInviteSuccess: false,
      resendInviteFailed: true,
      resendInviteValidations: validations,
    };
  },

  [`${Types.EMP_EMAIL_SIGNUP}_PENDING`]: (state, _action) => {
    return {...state,
      empEmailSignupPending: true,
      empEmailSignupSuccess: null,
      empEmailSignupFailed: false,
      empEmailSignupValidations: null,
    };
  },
  [`${Types.EMP_EMAIL_SIGNUP}_RESOLVED`]: (state, {result}) => {
    return {...state,
      empEmailSignupPending: false,
      empEmailSignupSuccess: result,
      empEmailSignupFailed: false,
      empEmailSignupValidations: null,
    };
  },
  [`${Types.EMP_EMAIL_SIGNUP}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      empEmailSignupPending: false,
      empEmailSignupSuccess: null,
      empEmailSignupFailed: true,
      empEmailSignupValidations: validations,
    };
  },

});

export default reducer;
