import _ from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment';

import CadminCompaniesAx from 'app/actions/company-admin/companies';
import CadminApi         from 'app/apis/company-admin';
import history           from 'app/history';
import Metrics           from 'app/metrics';
import paths             from 'app/paths';
import reducerUtils      from 'app/reducers/utils';
import CadminSlx         from 'app/selectors/company-admin/';
import EntitiesSlx       from 'app/selectors/entities';
import RoutingSlx        from 'app/selectors/routing';

const locationToApiParams = ({params, query}) => {
  const preset = Metrics.presets[params.presetKey];
  const presetDefaults = _.pick(preset, ['sortCol', 'sortDir', 'groupBy', 'showEmptyRows']);
  return {...presetDefaults, ...query};
};

/*
 *  Actions
 */

const Types = {
  LOAD_PAGE: 'CADMIN_STATS2_LOAD_PAGE',
  FETCH: 'CADMIN_STATS2_FETCH',
  SET_METRICS: 'CADMIN_STATS2_SET_METRICS',
};

const Ax = {

  loadPage: ({params, query}) => (dispatch, getState) => {
    const apiParams = locationToApiParams({params, query});
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(params.companySlug)),
      dispatch(Ax.fetch(params.companySlug, apiParams)),
    ]);
    return dispatch({type: Types.LOAD_PAGE, promise});
  },

  fetch: (companyId, params) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.analyticsFetch(companyId, params);
    const key = `${Math.random()}`;
    return dispatch({type: Types.FETCH, promise, key, _entities: ['campaigns', 'volEvents', 'nonprofits', 'brackets', 'employees', 'companies']});
  },

  setMetrics: (keys) => (dispatch, getState) => {
    const preset = Slx.preset(getState());
    const includeKeys = keys.filter(key => !preset.metrics.includes(key));
    const excludeKeys = preset.metrics.filter(key => !keys.includes(key));
    const keysToQs = ks => ks.map(k => Metrics.byKey[k].code).filter(c => c).join(',');
    const params = {
      include: keysToQs(includeKeys),
      exclude: keysToQs(excludeKeys),
    };
    return dispatch(Ax.setRouteParams(params));
  },

  setRouteParams: (newParams) => (dispatch, getState) => {
    const state = getState();
    const companySlug = CadminSlx.companySlug(state);
    const currentQuery = Slx.queryParams(state);
    const currentPresetKey = Slx.presetKey(state);
    const {preset: newPresetKey, ...newQuery} = newParams;
    const query = {...currentQuery, ...newQuery};
    const presetKey = newPresetKey || currentPresetKey;
    const path = paths.cadminAnalytics(companySlug, query, presetKey);
    history.push(path);
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,

  fetchKey: null,
  fetchIsPending: false,

  summary: null,
  rows: null,
  pagination: null,

  metricKeys: ['employeeImpactAmount', 'companyImpactAmount', 'totalImpactAmount', 'driveItemCount', 'volMinutes'],

};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD_PAGE}_PENDING`]: (state, action) => {
    return {...state,
      isLoading: true,
    };
  },
  [`${Types.LOAD_PAGE}_RESOLVED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },
  [`${Types.LOAD_PAGE}_REJECTED`]: (state, action) => {
    return {...state,
      isLoading: false,
    };
  },

  [`${Types.FETCH}_PENDING`]: (state, {key}) => {
    return {...state,
      fetchKey: key,
      fetchIsPending: true,
    };
  },
  [`${Types.FETCH}_RESOLVED`]: (state, {key, result}) => {
    if (key !== state.fetchKey) return state;
    return {...state,
      fetchIsPending: false,
      rows: result.rows,
      pagination: result.pagination,
      summary: result.summary,
    };
  },
  [`${Types.FETCH}_REJECTED`]: (state, {key, error}) => {
    if (key !== state.fetchKey) return state;
    return {...state,
      fetchIsPending: false,
    };
  },

  [Types.SET_METRICS]: (state, {keys}) => {
    return {...state,
      metricKeys: keys,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selFetchIsPending = (state) => state.companyAdmin.pageAnalytics.fetchIsPending;
  const selSummary        = (state) => state.companyAdmin.pageAnalytics.summary;
  const selPagination     = (state) => state.companyAdmin.pageAnalytics.pagination;
  const selRows           = (state) => state.companyAdmin.pageAnalytics.rows;
  const selMetricKeys     = (state) => state.companyAdmin.pageAnalytics.metricKeys;

  const selQueryParams = createSelector(
    [RoutingSlx.query],
    (query) => {
      return _.pick(query, [
        'groupBy', 'include', 'exclude', 'page', 'startDate', 'endDate', 'sortDir', 'sortCol', 'showEmptyRows',
        'employeeId', 'nonprofitId', 'campaignId', 'bracketId', 'volEventId', 'groupEventId', 'companyId',
      ]);
    }
  );

  const selPresetKey = createSelector(
    [RoutingSlx.params],
    (params) => {
      return params.presetKey || 'impact';
    }
  );

  const selPreset = createSelector(
    [selPresetKey],
    (presetKey) => {
      return Metrics.presets[presetKey];
    }
  );

  const selGroupby = createSelector(
    [selQueryParams, selPreset],
    (query, preset) => {
      if (query.groupBy) return query.groupBy;
      if (preset.groupBy) return preset.groupBy;
      return 'month';
    }
  );

  const selShowEmptyRows = createSelector(
    [selQueryParams, selPreset],
    (query, preset) => {
      if (query.showEmptyRows) return (query.showEmptyRows !== 'false');
      if (_.isBoolean(preset.showEmptyRows)) return preset.showEmptyRows;
      return true;
    }
  );

  const selSelectedMetrics = createSelector(
    [selQueryParams, selPreset],
    (query, preset) => {
      const keySet = new Set(preset.metrics);
      const includeKeys = Metrics.codesToKeys((query.include || '').split(','));
      includeKeys.forEach((key) => keySet.add(key));
      const excludeKeys = Metrics.codesToKeys((query.exclude || '').split(','));
      excludeKeys.forEach((key) => keySet.delete(key));
      return [...keySet].map((key) => Metrics.byKey[key]);
    }
  );

  const selSortCol = createSelector(
    [selQueryParams, selGroupby, selPreset],
    (query, groupBy, preset) => {
      if (query.sortCol) return query.sortCol;
      if (preset.sortCol) return preset.sortCol;
      const isInterval = ['month', 'quarter', 'year'].includes(groupBy);
      return isInterval ? 'joincol' : 'totalImpactAmount';
    }
  );

  const selSortDir = createSelector(
    [selQueryParams, selPreset],
    (query, preset) => {
      if (query.sortDir) return query.sortDir;
      if (preset.sortDir) return preset.sortDir;
      return 'desc';
    }
  );

  const selFilterBy = createSelector(
    [selQueryParams],
    (query) => {
      return Metrics.entityFilterParams.find((fb) => query[fb]) || null;
    }
  );

  const selApiParams = createSelector(
    [RoutingSlx.query, RoutingSlx.params],
    (query, params) => {
      return locationToApiParams({query, params});
    }
  );

  const selEntityFilterLabel = createSelector(
    [selPreset, selApiParams, EntitiesSlx.employees, EntitiesSlx.nonprofits, EntitiesSlx.brackets, EntitiesSlx.campaigns, EntitiesSlx.volEvents, EntitiesSlx.companies],
    (preset, apiParams, employees, nonprofits, brackets, campaigns, volEvents, companies) => {
      if (apiParams.employeeId === '*')   return 'All Employees';
      if (apiParams.campaignId === '*')   return 'All Campaigns';
      if (apiParams.bracketId === '*')    return 'All Brackets';
      if (apiParams.volEventId === '*')   return 'All Volunteer Events';
      if (apiParams.groupEventId === '*') return 'All Group Events';
      if (apiParams.nonprofitId === '*')  return 'All Nonprofits';
      if (apiParams.companyId === '*')    return 'All Companies';
      const employee = employees[apiParams.employeeId];
      if (employee) return `${employee.firstName} ${employee.lastName}`;
      const nonprofit = nonprofits[apiParams.nonprofitId];
      if (nonprofit) return nonprofit.name;
      const bracket = brackets[apiParams.bracketId];
      if (bracket) return bracket.name;
      const campaign = campaigns[apiParams.campaignId];
      if (campaign) return campaign.name;
      const volEvent = volEvents[apiParams.volEventId || apiParams.groupEventId];
      if (volEvent) return volEvent.title;
      const company = companies[apiParams.companyId];
      if (company) return company.name;
      return null;
    }
  );

  const selCsvHref = createSelector(
    [selApiParams, CadminSlx.company, selSelectedMetrics],
    (apiParams, company, metrics) => {
      if (!company) return null;
      const metricCodes = metrics.map(m => m.code).join(',');
      return paths.cadminAnalyticsCsv(company.slug, {...apiParams, metricCodes});
    },
  );

  return {
    fetchIsPending: selFetchIsPending,
    summary: selSummary,
    pagination: selPagination,
    rows: selRows,
    preset: selPreset,
    presetKey: selPresetKey,
    groupBy: selGroupby,
    showEmptyRows: selShowEmptyRows,
    filterBy: selFilterBy,
    sortCol: selSortCol,
    sortDir: selSortDir,
    selectedMetrics: selSelectedMetrics,
    queryParams: selQueryParams,
    entityFilterLabel: selEntityFilterLabel,
    csvHref: selCsvHref,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
