import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import MillieApi              from 'app/apis/millie';
import CampaignsAx            from 'app/actions/campaigns';
import DonationsAx            from 'app/actions/donations';
import RecDonationsAx         from 'app/actions/recurring-donations';
import RecPayrollDeductionsAx from 'app/actions/recurring-payroll-deductions';
import SessionStorageAx       from 'app/actions/session-storage';
import ToastAx                from 'app/actions/toast';
import WalletAx               from 'app/actions/wallet';
import {
  DonationFreqTypes as FreqTypes,
  UserBalanceTypes as BalanceTypes,
  DonatableTypes,
}                             from 'app/constants';
import CcDuck                 from 'app/ducks/credit-cards';
import FxDuck                 from 'app/ducks/fx-rates';
import paths                  from 'app/paths';
import history                from 'app/history';
import reducerUtils           from 'app/reducers/utils';
import AuthSlx                from 'app/selectors/auth';
import EntitiesSlx            from 'app/selectors/entities';
import DonationsSlx           from 'app/selectors/donations';
import RecDonationsSlx        from 'app/selectors/recurring-donations';



/*
 *  Actions
 */

const Types = {
  SHOW: 'MODAL_CONFIRM_DONATION_SHOW',
  DISMISS: 'MODAL_CONFIRM_DONATION_DISMISS',
  SET_CAMPAIGN: 'MODAL_CONFIRM_DONATION_SET_CAMPAIGN',
  SET_BALANCE_TYPE: 'MODAL_CONFIRM_DONATION_SET_BALANCE_TYPE',
  SET_CREDIT_CARD: 'MODAL_CONFIRM_DONATION_SET_CREDIT_CARD',
  SET_IS_ANON: 'MODAL_CONFIRM_DONATION_SET_IS_ANON',
  SET_NOTE: 'MODAL_CONFIRM_DONATION_SET_NOTE',
  SET_AMOUNT: 'MODAL_CONFIRM_DONATION_SET_AMOUNT',
  CHARGE: 'MODAL_CONFIRM_DONATION_CHARGE',
  CREATE: 'MODAL_CONFIRM_DONATION_CREATE',
  GET_MATCH_ESTIMATE: 'MODAL_CONFIRM_DONATION_GET_MATCH_ESTIMATE',
};

const Ax = {

  show: ({freqType, amount, currencyCode, donatableType, donatableId, campaignId}) => (dispatch, getState) => {
    // sanity check the types
    if (!Object.values(DonatableTypes).includes(donatableType)) throw new Error('ruh roh donatableType');
    if (!Object.values(FreqTypes).includes(freqType)) throw new Error('ruh roh freqType');
    //
    const isNonprofit = donatableType === DonatableTypes.NONPROFIT;
    // handle not signed in
    const currentUser = AuthSlx.currentUser(getState());
    if (!currentUser) {
      if (isNonprofit) dispatch(SessionStorageAx.setDonationTargetById(donatableId));
      history.push(paths.signup());
      return;
    }
    //
    const promise = dispatch(CampaignsAx.fetch({[isNonprofit ? 'nonprofitId' : 'fundId']: donatableId, isActive: true}));
    // default campaign selection
    promise.then(({campaigns}) => {
      if (campaigns.length && !campaignId) {
        return dispatch(Ax.setCampaignId(campaigns[0].id));
      }
    }).then(() => {
      dispatch(Ax.getMatchEstimate());
    });
    const balanceType = (() => {
      if (currencyCode !== 'USD') return BalanceTypes.CC;
      // TODO: check intl vs us
      // if (balanceType) return balanceType;
      if (currentUser.giftBalanceAmount && freqType !== FreqTypes.RECURRING) return BalanceTypes.GIFT;
      return BalanceTypes.MAIN;
    })();
    dispatch(CcDuck.Ax.fetchAll());
    dispatch(FxDuck.Ax.fetchLatest());
    const ccId = currentUser?.defaultCreditCardId || null;
    return dispatch({type: Types.SHOW, promise, freqType, amount, currencyCode, donatableType, donatableId, balanceType, campaignId, ccId});
  },

  getMatchEstimate: () => (dispatch, getState) => {
    const state = getState();
    const campaignId = Slx.selectedCampaignId(state);
    const donatableType = Slx.donatableType(state);
    const donatableId = Slx.donatableId(state);
    const key = `${donatableType}|${donatableId}|${campaignId}`;
    const promise = MillieApi.matchesEstimate({campaignId, donatableType, donatableId});
    return dispatch({type: Types.GET_MATCH_ESTIMATE, promise, key});
  },

  dismiss: ({goToPath} = {}) => {
    if (goToPath) history.push(goToPath);
    return { type: Types.DISMISS };
  },

  setCampaignId: (campaignId) => (dispatch, getState) => {
    dispatch({type: Types.SET_CAMPAIGN, campaignId});
    dispatch(Ax.getMatchEstimate());
  },

  setBalanceType: (balanceType) => {
    return { type: Types.SET_BALANCE_TYPE, balanceType };
  },

  setCreditCard: (ccId) => {
    return { type: Types.SET_CREDIT_CARD, ccId };
  },

  setIsAnonymous: (isAnonymous) => {
    return { type: Types.SET_IS_ANON, isAnonymous };
  },

  setNote: (note) => {
    return { type: Types.SET_NOTE, note };
  },

  setAmount: ({amount, currencyCode}) => {
    return { type: Types.SET_AMOUNT, amount, currencyCode };
  },

  charge: ({amount, currencyCode, creditCardId}) => (dispatch, getState) => {
    const promise = dispatch(WalletAx.load({amount, currencyCode, creditCardId}));
    return dispatch({type: Types.CHARGE, promise});
  },

  create: () => (dispatch, getState) => {
    const state = getState();
    const freqType = Slx.freqType(state);
    const balanceType = Slx.balanceType(state);
    const donatableId = Slx.donatableId(state);
    const donatableType = Slx.donatableType(state);
    const creditCardId = Slx.ccId(state);
    const currencyCode = Slx.currencyCode(state);
    const campaignId = Slx.selectedCampaignId(state);
    const anonymous = Slx.isAnonymous(state);
    const note = (Slx.note(state) || '').trim() || null;
    const amount = Slx.amount(state);
    const shouldCharge = (freqType !== FreqTypes.RECURRING) && (balanceType === BalanceTypes.CC);
    // sanity check the params
    const hasParams = !!(donatableType && donatableId && _.isBoolean(anonymous) && freqType && (amount > 0) && balanceType && currencyCode && (creditCardId || balanceType !== BalanceTypes.CC));
    if (!hasParams) throw new Error('tried to donate without all params');
    // different actions for Donation vs RecurringDonation vs RecurringPayrollDeduction
    const axCreate = (freqType === FreqTypes.RECURRING)
      ? (balanceType === BalanceTypes.PAYROLL)
        ? RecPayrollDeductionsAx.create
        : RecDonationsAx.create
      : DonationsAx.create;
    console.log('---------------- shouldCharge', shouldCharge);
    const chargeProm = shouldCharge
      ? dispatch(Ax.charge({amount, currencyCode, creditCardId})).then(({walletLoad}) => {
        return {currencyCode: 'USD', balanceType: BalanceTypes.MAIN, amount: walletLoad.amountInCents};
      })
      : Promise.resolve({asdf: 'hello'});
    const promise = chargeProm.then((overrideParams) => {
      console.log('--------- overrideParams', overrideParams);
      return dispatch(axCreate({
        donatableType,
        donatableId,
        nonprofitId: donatableId,
        amount,
        anonymous,
        campaignId,
        balanceType,
        note,
        creditCardId,
        currencyCode,
        ...overrideParams,
      }));
    });
    // promise.catch((error) => {
    //   dispatch(ToastAx.error('Oops! Something went wrong while making donation. Please check if funds have been deducted from your balance before trying again.'));
    // });
    return dispatch({type: Types.CREATE, promise});
  },
  
};



/*
 *  Reducer
 */

const initialState = {
  donatableType: null,
  donatableId: null,
  campaignIds: null,
  freqType: null,
  modalLoading: false,
  targetAmount: null,
  targetCurrencyCode: null,
  amount: null,
  currencyCode: null,
  balanceType: null,
  ccId: null,
  selectedCampaignId: null,
  isAnonymous: false,
  note: null,
  matchEstimateKey: null,
  matchEstimatePending: false,
  matchEstimate: null,
  createPending: false,
  createSuccess: false,
  createError: false,
  chargePending: false,
  chargeSuccess: false,
  chargeError: false,
  chargeErrorStripeMsg: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.SHOW}_PENDING`]: (state, {freqType, amount, currencyCode, donatableType, donatableId, balanceType, campaignId, ccId}) => {
    return {...state,
      modalLoading: true,
      freqType,
      targetAmount: amount,
      targetCurrencyCode: currencyCode,
      amount,
      currencyCode,
      donatableType,
      donatableId,
      balanceType,
      campaignIds: null,
      selectedCampaignId: null,
      isAnonymous: false,
      note: null,
      ccId,
      createPending: false,
      createSuccess: false,
      createError: false,
      chargePending: false,
      chargeSuccess: false,
      chargeError: false,
      chargeErrorStripeMsg: null,
    };
  },
  [`${Types.SHOW}_RESOLVED`]: (state, action) => {
    const { campaigns } = action.result;
    const campaignIds = campaigns.map(c => c.id);
    const newState = {...state,
      modalLoading: false,
      campaignIds,
    };
    if (action.campaignId) {
      newState.selectedCampaignId = campaignIds.find(cid => cid === action.campaignId) || (campaignIds.length ? campaignIds[0] : null);
    }
    return newState;
  },

  [`${Types.GET_MATCH_ESTIMATE}_PENDING`]: (state, action) => {
    return {...state,
      matchEstimateKey: action.key,
      matchEstimatePending: true,
      matchEstimate: null,
    };
  },
  [`${Types.GET_MATCH_ESTIMATE}_RESOLVED`]: (state, action) => {
    if (state.matchEstimateKey !== action.key) return state;
    return {...state,
      matchEstimatePending: false,
      matchEstimate: action.result.matchEstimate,
    };
  },
  [`${Types.GET_MATCH_ESTIMATE}_REJECTED`]: (state, action) => {
    if (state.matchEstimateKey !== action.key) return state;
    return {...state,
      matchEstimatePending: false,
    };
  },

  [Types.DISMISS]: (state, action) => {
    return {...state,
      campaignIds: null,
      donatableType: null,
      donatableId: null,
      freqType: null,
      amount: null,
      currencyCode: null,
      targetAmount: null,
      targetCurrencyCode: null,
      balanceType: null,
    };
  },

  [Types.SET_CAMPAIGN]: (state, action) => {
    return {...state,
      selectedCampaignId: action.campaignId,
    };
  },

  [Types.SET_BALANCE_TYPE]: (state, {balanceType}) => {
    const newState = {...state, balanceType};
    const isCc = balanceType === BalanceTypes.CC;
    if (!isCc) newState.ccId = null;
    return newState;
  },

  [Types.SET_CREDIT_CARD]: (state, {ccId}) => {
    return {...state,
      ccId,
      balanceType: BalanceTypes.CC,
    };
  },

  [Types.SET_IS_ANON]: (state, {isAnonymous}) => {
    return {...state, isAnonymous};
  },

  [Types.SET_NOTE]: (state, {note}) => {
    return {...state, note};
  },

  [Types.SET_AMOUNT]: (state, {amount, currencyCode}) => {
    const isTarget = currencyCode === state.targetCurrencyCode;
    const newState = {...state, amount, currencyCode};
    if (isTarget) {
      newState.targetAmount = amount;
    }
    return newState;
  },

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
      createSuccess: false,
      createError: false,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      createPending: false,
      createSuccess: true,
      createError: false,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, action) => {
    return {...state,
      createPending: false,
      createSuccess: false,
      createError: true,
    };
  },

  [`${Types.CHARGE}_PENDING`]: (state, action) => {
    return {...state,
      chargePending: true,
      chargeSuccess: false,
      chargeError: false,
      chargeErrorStripeMsg: null,
    };
  },
  [`${Types.CHARGE}_RESOLVED`]: (state, action) => {
    return {...state,
      chargePending: false,
      chargeSuccess: true,
      chargeError: false,
      chargeErrorStripeMsg: null,
    };
  },
  [`${Types.CHARGE}_REJECTED`]: (state, action) => {
    const stripeMsg = _.get(action, 'error.response.data.error.stripeMessage') || null;
    return {...state,
      chargePending: false,
      chargeSuccess: false,
      chargeError: true,
      chargeErrorStripeMsg: stripeMsg,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selDonatableType        = state => state.modalConfirmDonation.donatableType;
  const selDonatableId          = state => state.modalConfirmDonation.donatableId;
  const selCampaignIds          = state => state.modalConfirmDonation.campaignIds;
  const selModalLoading         = state => state.modalConfirmDonation.modalLoading;
  const selFreqType             = state => state.modalConfirmDonation.freqType;
  const selAmount               = state => state.modalConfirmDonation.amount;
  const selCurrencyCode         = state => state.modalConfirmDonation.currencyCode;
  const selTargetAmount         = state => state.modalConfirmDonation.targetAmount;
  const selTargetCurrencyCode   = state => state.modalConfirmDonation.targetCurrencyCode;
  const selBalanceType          = state => state.modalConfirmDonation.balanceType;
  const selCcId                 = state => state.modalConfirmDonation.ccId;
  const selSelectedCampaignId   = state => state.modalConfirmDonation.selectedCampaignId;
  const selIsAnonymous          = state => state.modalConfirmDonation.isAnonymous;
  const selNote                 = state => state.modalConfirmDonation.note;
  const selMatchEstimatePending = state => state.modalConfirmDonation.matchEstimatePending;
  const selMatchEstimate        = state => state.modalConfirmDonation.matchEstimate;
  const selCreatePending        = state => state.modalConfirmDonation.createPending;
  const selCreateSuccess        = state => state.modalConfirmDonation.createSuccess;
  const selCreateError          = state => state.modalConfirmDonation.createError;
  const selChargePending        = state => state.modalConfirmDonation.chargePending;
  const selChargeSuccess        = state => state.modalConfirmDonation.chargeSuccess;
  const selChargeError          = state => state.modalConfirmDonation.chargeError;
  const selChargeErrorStripeMsg = state => state.modalConfirmDonation.chargeErrorStripeMsg;

  // amountInCents

  const selDonatable = createSelector(
    [selDonatableId, EntitiesSlx.donatables],
    (donatableId, donatables) => {
      return donatables[donatableId];
    }
  );

  const selCampaigns = createSelector(
    [selCampaignIds, EntitiesSlx.campaigns],
    (campaignIds, campaigns) => {
      if (!campaignIds || !campaigns) return null;
      return campaignIds.map(cid => campaigns[cid]);
    }
  );

  // const selDonatePending = createSelector(
  //   [DonationsSlx.createPending, RecDonationsSlx.createPending],
  //   (isOneOffDonationPending, isRecurringDonationPending) => {
  //     return isOneOffDonationPending || isRecurringDonationPending;
  //   }
  // );

//   const selDonationSuccess = createSelector(
//     [selCreateSuccess, selDonatable, selAmount, selFreqType, selBalanceType, AuthSlx.currentUser, selDonatableType],
//     (isSuccess, donatable, amount, freqType, balanceType, currentUser, donatableType) => {
//       if (!isSuccess || freqType !== FreqTypes.ONE_OFF) return null;
//       return {
//         amount,
//         currentUserName: currentUser.firstName,
//         nonprofitName: donatable.name,
//         balanceType,
//         donatableType,
//       };
//     }
//   );
// 
//   const selRecDonationSuccess = createSelector(
//     [selCreateSuccess, selDonatable, selAmount, selFreqType, AuthSlx.currentUser, selBalanceType],
//     (isSuccess, donatable, amount, freqType, currentUser, balanceType) => {
//       if (!isSuccess || freqType !== FreqTypes.RECURRING) return null;
//       return {
//         amount,
//         currentUserName: currentUser.firstName,
//         nonprofitName: donatable.name,
//         dayOfMonth: Math.min(28, moment.utc().date()),
//         balanceType,
//       };
//     }
//   );

  const selShow = createSelector(
    [selDonatable, AuthSlx.currentUser],
    (donatable, currentUser) => !!(donatable && currentUser)
  );

  return {
    show: selShow,
    selectedCampaignId: selSelectedCampaignId,
    isAnonymous: selIsAnonymous,
    note: selNote,
    amount: selAmount,
    currencyCode: selCurrencyCode,
    targetAmount: selTargetAmount,
    targetCurrencyCode: selTargetCurrencyCode,
    ccId: selCcId,
    balanceType: selBalanceType,
    // donatePending: selDonatePending,
    modalLoading: selModalLoading,
    freqType: selFreqType,
    donatableType: selDonatableType,
    donatableId: selDonatableId,
    donatable: selDonatable,
    // nonprofitId: selNonprofitId,
    campaignIds: selCampaignIds,
    campaigns: selCampaigns,
    // nonprofit: selNonprofit,
    // donationSuccess: selDonationSuccess,
    // recDonationSuccess: selRecDonationSuccess,
    matchEstimatePending: selMatchEstimatePending,
    matchEstimate: selMatchEstimate,
    createPending: selCreatePending,
    createSuccess: selCreateSuccess,
    createError: selCreateError,
    chargePending: selChargePending,
    chargeSuccess: selChargeSuccess,
    chargeError: selChargeError,
    chargeErrorStripeMsg: selChargeErrorStripeMsg,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
