import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx          from 'app/actions/company-admin/builder-vol-event';
import Checkbox           from 'app/components/common/checkbox';
import Confirm            from 'app/components/common/confirm';
import EmployeeInput      from 'app/components/common/employee-input';
import Icon               from 'app/components/common/icon';
import AddressInput       from 'app/components/common/address-input';
import GroupInput         from 'app/components/common/group-input';
import NonprofitInput     from 'app/components/common/nonprofit-input';
import NteePicker         from 'app/components/common/ntee-picker';
import StandardInput      from 'app/components/common/standard-input';
import TimezoneInput      from 'app/components/common/timezone-input';
import UploadedImageInput from 'app/components/common/uploaded-image-input';
import BuilderLink        from 'app/components/company-admin/common/builder-link';
import Builder            from 'app/components/company-admin/layout/builder';
import BuilderFooter      from 'app/components/company-admin/layout/builder-footer';
import BuilderIterable    from 'app/components/company-admin/layout/builder-iterable';
import BuilderSection     from 'app/components/company-admin/layout/builder-section';
import ShiftBuilder       from 'app/components/company-admin/volunteer/builder-shift';
import config             from 'app/config';
import {
  VolEventTypes,
  VolEventTags as Tags,
  VolEventTagNames as TagNames,
  VolEventTagIcons as TagIcons,
}                         from 'app/constants';
import CadminSlx          from 'app/selectors/company-admin/';
import BuilderSlx         from 'app/selectors/company-admin/builder-vol-event';

class VolEventBuilder extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      shiftDeleteIndex: null,
    };

    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeImg = this.onChangeImg.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeExternalUrl = this.onChangeExternalUrl.bind(this);
    this.onChangeDetails = this.onChangeDetails.bind(this);
    this.onChangeContact = this.onChangeContact.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onChangeNteeCode = this.onChangeNteeCode.bind(this);
    this.onChangeLeadEmployee = this.onChangeLeadEmployee.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeTimezone = this.onChangeTimezone.bind(this);
    this.onChangeIsOngoing = this.onChangeIsOngoing.bind(this);
    this.onChangeIsRemote = this.onChangeIsRemote.bind(this);
    this.onClickAddShift = this.onClickAddShift.bind(this);
    this.onRemoveShift = this.onRemoveShift.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onRemoveGroup = this.onRemoveGroup.bind(this);
    this.onClickAddGroup = this.onClickAddGroup.bind(this);
    this.onRemoveLink = this.onRemoveLink.bind(this);
    this.onChangeLink = this.onChangeLink.bind(this);
    this.onClickAddLink = this.onClickAddLink.bind(this);
    this.onResponseDeleteShift = this.onResponseDeleteShift.bind(this);
  }

  get isEvent() {
    return this.props.type === VolEventTypes.EVENT;
  }

  get hasIntl() {
    return !!this.props.company?.features?.international;
  }

  onChangeTitle(event) {
    const title = event.target.value;
    this.props.setKeyVal('title', title);
  }

  onChangeImg(imgPath) {
    this.props.setKeyVal('imgPath', imgPath);
  }

  onChangeDescription(event) {
    const description = event.target.value;
    this.props.setKeyVal('description', description);
  }

  onChangeExternalUrl(event) {
    const externalUrl = event.target.value;
    this.props.setKeyVal('externalUrl', externalUrl);
  }

  onChangeDetails(event) {
    const details = event.target.value;
    this.props.setKeyVal('details', details);
  }

  onChangeContact(event) {
    const contact = event.target.value;
    this.props.setKeyVal('contact', contact);
  }

  onChangeNonprofit(nonprofit) {
    this.props.setNonprofit(nonprofit);
  }

  onChangeNteeCode(nteeCode) {
    this.props.setKeyVal('nteeCode', nteeCode);
  }

  onChangeTag(tag, event) {
    const checked = event.target.checked;
    this.props.setTag(tag, checked);
  }

  onChangeLeadEmployee(user) {
    this.props.setLeadEmployee(user);
  }

  onChangeAddress(address) {
    const keyVals = _.pick(address, ['addressLine1', 'city', 'state', 'postalCode', 'latitude', 'longitude', 'timezone']);
    this.props.setKeyVals(keyVals);
  }

  onChangeTimezone(timezone) {
    this.props.setKeyVal('timezone', timezone);
  }

  onChangeIsOngoing(event) {
    const isOngoing = event.target.checked;
    this.props.setKeyVal('isOngoing', isOngoing);
  }

  onChangeIsRemote(event) {
    const isRemote = event.target.checked;
    this.props.setKeyVal('isRemote', isRemote);
  }

  onClickAddShift() {
    this.props.addShift();
  }

  onRemoveShift(index) {
    const shift = this.props.shifts[index];
    if (shift?.id) {
      this.setState({shiftDeleteIndex: index});
    } else {
      this.props.removeShift(index);
    }
  }
  onResponseDeleteShift(didConfirm) {
    const {shiftDeleteIndex} = this.state;
    this.setState({shiftDeleteIndex: null});
    if (!didConfirm) return;
    this.props.removeShift(shiftDeleteIndex);
  }

  onClickSave() {
    this.props.save();
  }

  onClickAddGroup() {
    const index = this.props.groups.length;
    this.props.setGroup(null, index);
  }
  onRemoveGroup(index) {
    this.props.removeGroup(index);
  }
  onChangeGroup(index, group) {
    this.props.setGroup(group, index);
  }

  onClickAddLink() {
    this.props.addLink();
  }
  onChangeLink(index, link) {
    this.props.updateLink(index, link);
  }
  onRemoveLink(index) {
    this.props.removeLink(index);
  }

  /*
   *  Render Sections
   */

  renderSectionTitle() {
    const { title, validations } = this.props;
    const heading = this.isEvent ? 'Group Event Name' : 'Volunteer Event Name';
    return (
      <BuilderSection title={heading} isRequired>
        <StandardInput label="Name" name="title" value={title || ''} validations={validations} onChange={this.onChangeTitle} />
      </BuilderSection>
    );
  }

  renderSectionImg() {
    const { imgPath, validations } = this.props;
    return (
      <BuilderSection title="Image" isRequired>
        <UploadedImageInput
          name="imgPath"
          label="Image"
          onChange={this.onChangeImg}
          s3PathParts={['vol-events']}
          maxImageWidth={2048}
          pathValue={imgPath}
          validations={validations}
        />
      </BuilderSection>
    );
  }

  renderSectionDescription() {
    const { description, externalUrl, isSystemEvent, validations, links, hasSubmitted } = this.props;
    return (
      <BuilderSection title="Short Description" isRequired>
        <StandardInput type="textarea" label="Description" name="description" value={description || ''} onChange={this.onChangeDescription} validations={validations} />
        {isSystemEvent && (
          <StandardInput type="text" label="External URL" name="externalUrl" value={externalUrl || ''} onChange={this.onChangeExternalUrl} validations={validations} />
        )}
        {links.map((link, index) => {
          return (
            <BuilderIterable index={index} key={index} onDelete={this.onRemoveLink}>
              <BuilderLink link={link} index={index} onChange={this.onChangeLink} hasSubmitted={hasSubmitted} />
            </BuilderIterable>
          );
        })}
        <button className="btn small blue cadmin-builder-iterable-add" onClick={this.onClickAddLink}>+ Add Link</button>
      </BuilderSection>
    );
  }

  renderSectionLocation() {
    const { addressLine1, city, state, postalCode, timezone, isRemote, validations } = this.props;
    const address = addressLine1 ? {addressLine1, city, state, postalCode, timezone} : null;
    const topRight = (
      <div className="cadmin-builder-checkbox">
        <Checkbox id="checkbox-is-remote" onChange={this.onChangeIsRemote} checked={isRemote} />
        <label htmlFor="checkbox-is-remote">Remote</label>
      </div>
    );
    return (
      <BuilderSection title="Location" topRight={topRight} isRequired>
        {isRemote ? (
          <TimezoneInput onChange={this.onChangeTimezone} timezone={timezone} name="timezone" validations={validations} />
        ) : (
          <AddressInput label="Address" name="address" address={address} onChange={this.onChangeAddress} validations={validations} />
        )}
      </BuilderSection>
    );
  }

  renderSectionTime() {
    const { isOngoing, shifts, hasSubmitted, type, showShiftRequiredValidation } = this.props;
    const topRight = (
      <div className="cadmin-builder-checkbox">
        <Checkbox id="checkbox-is-ongoing" onChange={this.onChangeIsOngoing} checked={isOngoing} />
        <label htmlFor="checkbox-is-ongoing">Ongoing</label>
      </div>
    );
    return (
      <BuilderSection title="Time" topRight={topRight} isRequired>
        {showShiftRequiredValidation && (
          <p className="cadmin-builder-section-vmsg">When not ongoing, at least one shift is required.</p>
        )}
        {!isOngoing && (<>
          {shifts.map((shift, i) => {
            if (shift._delete) return null;
            return (
              <BuilderIterable index={i} key={i} onDelete={this.onRemoveShift} className="cadmin-veb-shift-iterable">
                <ShiftBuilder shift={shift} index={i} type={type} hasSubmitted={hasSubmitted} />
              </BuilderIterable>
            );
          })}
          <button className="btn small blue cadmin-builder-iterable-add" onClick={this.onClickAddShift}>+ Add Shift</button>
        </>)}
      </BuilderSection>
    );
  }

  renderSectionNonprofit() {
    const { nonprofit, type } = this.props;
    return (
      <BuilderSection title="Nonprofit We'll Be Working With">
        <NonprofitInput onChange={this.onChangeNonprofit} selectedNonprofit={nonprofit} intl={this.hasIntl} />
      </BuilderSection>
    );
  }

  renderSectionCategory() {
    if (this.isEvent) return null;
    const { nteeCode, validations } = this.props;
    return (
      <BuilderSection title="Category" isRequired>
        <NteePicker nteeCode={nteeCode} onChange={this.onChangeNteeCode} allowMajor allowClear validations={validations} name="nteeCode" />
      </BuilderSection>
    );
  }

  renderSectionTags() {
    if (this.isEvent) return null;
    const {tags} = this.props;
    const ShowTags = Object.values(Tags).filter((tag) => {
      return ![Tags.REMOTE, Tags.IN_PERSON].includes(tag);
    });
    return (
      <BuilderSection title="Tags" className="cadmin-veb-tags">
        {ShowTags.map((showTag) => {
          const name = TagNames[showTag];
          const id = `cb-tag-${showTag}`;
          const checked = tags.includes(showTag);
          const IconComp = Icon[TagIcons[showTag]];
          return (
            <div key={id} className={`cadmin-veb-tags-tag ${checked ? 'on' : 'off'}`}>
              <Checkbox id={id} checked={checked} onChange={this.onChangeTag.bind(this, showTag)} isToggle offOk />
              <label htmlFor={id}><IconComp className="cadmin-veb-tags-tag-icon" /> {name}</label>
            </div>
          );
        })}
      </BuilderSection>
    );
  }

  renderSectionGroups() {
    const { groups, company } = this.props;
    if (!company.features.groups) return null;
    return (
      <BuilderSection title="Group Support">
        {groups.map((group, index) => {
          return (
            <BuilderIterable index={index} key={index} onDelete={this.onRemoveGroup}>
              <GroupInput onChange={this.onChangeGroup.bind(this, index)} selectedGroup={group} companyId={company.id} />
            </BuilderIterable>
          );
        })}
        <button className="btn small blue cadmin-builder-iterable-add" onClick={this.onClickAddGroup}>+ Add Group</button>
      </BuilderSection>
    );
  }

  renderSectionDetails() {
    const { details, contact, company, leadEmployee } = this.props;
    const detailsPlaceholder = 'We’re going to meet at the location at 8:45. Come wearing your company t-shirts and get ready for a fun, impactful 4 hours! We’ll try to grab lunch together afterwards.';
    const contactPlaceholder = 'If you have any questions feel free to reach out to Charlie — 123-123-1234 or…';

    return (
      <BuilderSection title="Details">
        <label>Event Details</label>
        <StandardInput type="textarea" label={detailsPlaceholder} name="details" value={details || ''} onChange={this.onChangeDetails} />
        <label>Event Lead</label>
        <EmployeeInput onChange={this.onChangeLeadEmployee} name="leadEmployeeId" companyId={company.id} selectedEmployee={leadEmployee} />
        <label>Contact</label>
        <StandardInput type="textarea" label={contactPlaceholder} name="contact" value={contact || ''} onChange={this.onChangeContact} />
      </BuilderSection>
    );
  }

  renderSectionSharing() {
    return null;
    // const {  } = this.props;
    return (
      <BuilderSection title="Sharing Settings">
      </BuilderSection>
    );
  }

  renderFooter() {
    const { hasTouched, savePending, isValid, volEventId, hasSubmitted } = this.props;
    const showValidation = hasSubmitted && !isValid;
    const disabled = !hasTouched || savePending || showValidation;
    const btnText = volEventId
      ? (savePending ? 'Saving...' : 'Save')
      : (savePending ? 'Creating...' : 'Create');

    return (
      <BuilderFooter className="cadmin-veb-bottom">
        {showValidation && (
          <div className="cadmin-builder-bottom-validations">
            <p>Oops! There are some required fields above that need your attention.</p>
          </div>
        )}
        <div className="cadmin-builder-bottom-actions">
          <button className="btn green" disabled={disabled} onClick={this.onClickSave}>{btnText}</button>
        </div>
      </BuilderFooter>
    );
  }

  render() {
    const {shiftDeleteIndex} = this.state;

    return (
      <Builder footer={this.renderFooter()} className="cadmin-veb">
        {this.renderSectionTitle()}
        {this.renderSectionImg()}
        {this.renderSectionDescription()}
        {this.renderSectionLocation()}
        {this.renderSectionTime()}
        {this.renderSectionNonprofit()}
        {this.renderSectionCategory()}
        {this.renderSectionTags()}
        {this.renderSectionGroups()}
        {this.renderSectionDetails()}
        {this.renderSectionSharing()}

        {(shiftDeleteIndex != null) && (
          <Confirm onResponse={this.onResponseDeleteShift} confirmBtnLabel="Delete Shift" confirmBtnColor="danger">
            <p>Deleting this shift <strong>will also delete all volunteer registrations and logged hours</strong> associated with this shift.</p>
            <p><strong>This change can not be undone.</strong> It will take effect when you save the event you are editing.</p>
          </Confirm>
        )}
      </Builder>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  isSystemEvent: CadminSlx.company(state).id === config.systemEventsCompanyId,

  volEventId: BuilderSlx.volEventId(state),
  hasTouched: BuilderSlx.hasTouched(state),
  hasSubmitted: BuilderSlx.hasSubmitted(state),
  isValid: BuilderSlx.isValid(state),
  validations: BuilderSlx.visibleValidations(state),
  showShiftRequiredValidation: BuilderSlx.showShiftRequiredValidation(state),
  savePending: BuilderSlx.savePending(state),
  // attrs
  type: BuilderSlx.type(state),
  imgPath: BuilderSlx.imgPath(state),
  title: BuilderSlx.title(state),
  description: BuilderSlx.description(state),
  nteeCode: BuilderSlx.nteeCode(state),
  links: BuilderSlx.links(state),
  externalUrl: BuilderSlx.externalUrl(state),
  contact: BuilderSlx.contact(state),
  details: BuilderSlx.details(state),
  addressLine1: BuilderSlx.addressLine1(state),
  city: BuilderSlx.city(state),
  state: BuilderSlx.state(state),
  postalCode: BuilderSlx.postalCode(state),
  isRemote: BuilderSlx.isRemote(state),
  isOngoing: BuilderSlx.isOngoing(state),
  timezone: BuilderSlx.timezone(state),
  leadEmployee: BuilderSlx.leadEmployee(state),
  nonprofit: BuilderSlx.nonprofit(state),
  shifts: BuilderSlx.shifts(state),
  groups: BuilderSlx.groups(state),
  tags: BuilderSlx.tags(state),
});

const dispatchToProps = (dispatch) => ({
  setKeyVal: (key, val) => dispatch(BuilderAx.setKeyVal(key, val)),
  setKeyVals: (keyVals) => dispatch(BuilderAx.setKeyVals(keyVals)),
  setTag: (tag, checked) => dispatch(BuilderAx.setTag(tag, checked)),
  setNonprofit: (nonprofit) => dispatch(BuilderAx.setNonprofit(nonprofit)),
  setLeadEmployee: (employee) => dispatch(BuilderAx.setLeadEmployee(employee)),
  addShift: () => dispatch(BuilderAx.addShift()),
  removeShift: (index) => dispatch(BuilderAx.removeShift(index)),
  setGroup: (group, index) => dispatch(BuilderAx.setGroup(group, index)),
  removeGroup: (index) => dispatch(BuilderAx.removeGroup(index)),
  addLink: () => dispatch(BuilderAx.addLink()),
  updateLink: (index, link) => dispatch(BuilderAx.updateLink(index, link)),
  removeLink: (index) => dispatch(BuilderAx.removeLink(index)),
  save: () => dispatch(BuilderAx.save()),
});

export default connect(stateToProps, dispatchToProps)(VolEventBuilder);
