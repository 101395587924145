import axios from 'axios';
import _ from 'lodash';

import config from 'app/config';

const client = axios.create({
  baseURL: '/api/company-admin',
});

const Endpoints =  {

  /*
   *  Companies
   */

  companiesGet: (id) => {
    return client.get(`/${id}`).then(res => res.data);
  },

  companiesGetSso: (id) => {
    return client.get(`/${id}/sso`).then(res => res.data);
  },

  companiesUpdateMatchAmounts: (id, year, {matchPercent}) => {
    const body = {year, matchPercent};
    return client.patch(`/${id}/match-amounts`, body).then(res => res.data);
  },

  companiesUpdate: (id, params = {}) => {
    const body = new FormData();
    _.forEach(params, (val, key) => {
      body.append(key, val);
    });
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return client.patch(`/${id}`, body, {headers}).then(res => res.data);
  },

  companiesGetSetupStatus: (id) => {
    return client.get(`/${id}/setup-status`).then(res => res.data);
  },

  companiesGetAttentionNumbers: (id) => {
    return client.get(`/${id}/attention-numbers`).then(res => res.data);
  },

  companiesLaunch: (id, {sendInvites=true} = {}) => {
    const body = {sendInvites};
    return client.patch(`/${id}/launch`, body).then(res => res.data);
  },

  /*
   *  Domains
   */

  domainsSearch: (id) => {
    return client.get(`/${id}/company-domains`).then(res => res.data);
  },

  /*
   *  Dashboards
   */

  dashboardsGet: (companyId, dashboardId) => {
    return client.get(`/${companyId}/company-dashboards/${dashboardId}`).then(res => res.data);
  },

  dashboardsGetData: (companyId, dashboardId, {startDate, endDate} = {}) => {
    const params = {startDate, endDate};
    return client.get(`/${companyId}/company-dashboards/${dashboardId}/data`, {params}).then(res => res.data);
  },

  dashboardsUpdate: (companyId, dashboardId, attrs) => {
    const body = attrs;
    return client.patch(`/${companyId}/company-dashboards/${dashboardId}`, body).then(res => res.data);
  },

  /*
   *  Employees
   */

  employeesCreate: (companyId, {firstName, lastName, email}) => {
    const body = {firstName, lastName, email};
    return client.post(`/${companyId}/employees`, body).then(res => res.data);
  },

  employeesUpdate: (companyId, employeeId, {firstName, lastName, email, role, cattrs}) => {
    const body = {firstName, lastName, email, role, cattrs};
    return client.patch(`/${companyId}/employees/${employeeId}`, body).then(res => res.data);
  },

  employeesActivate: (companyId, employeeId) => {
    return client.patch(`/${companyId}/employees/${employeeId}/activate`).then(res => res.data);
  },

  employeesGet: (companyId, employeeId) => {
    return client.get(`/${companyId}/employees/${employeeId}`).then(res => res.data);
  },

  employeesDeactivate: (companyId, employeeId) => {
    return client.patch(`/${companyId}/employees/${employeeId}/deactivate`).then(res => res.data);
  },

  employeesSearch: (companyId, {search, page, status, role}) => {
    const params = {search, page, status, role};
    return client.get(`/${companyId}/employees`, {params}).then(res => res.data);
  },

  employeesGetAll: (companyId) => {
    return client.get(`/${companyId}/employees/all`).then(res => res.data);
  },

  employeesBulkCreate: (companyId, employeeRecords) => {
    const body = {employeeRecords};
    return client.post(`/${companyId}/employees/bulk`, body).then(res => res.data);
  },

  /*
   *  Bank Accounts
   */

  bankAccountsFetch: (companyId) => {
    return client.get(`/${companyId}/bank-accounts`).then(res => res.data);
  },

  bankAccountsCreatePlaid: (companyId, publicToken, accountId, plaidDetails) => {
    const body = {publicToken, accountId, plaidDetails};
    return client.post(`/${companyId}/bank-accounts/plaid`, body).then(res => res.data);
  },

  bankAccountsCreateManual: (companyId, bankAccountDataToken) => {
    const body = {bankAccountDataToken};
    return client.post(`/${companyId}/bank-accounts/create-manual`, body)
      .then(res => res.data);
  },

  bankAccountsVerifyManual: (companyId, bankAccountId, {deposit1, deposit2}) => {
    const body = {deposit1, deposit2};
    return client.post(`/${companyId}/bank-accounts/${bankAccountId}/verify-manual`, body)
      .then(res => res.data);
  },

  bankAccountsDelete: (companyId, bankAccountId) => {
    return client.delete(`/${companyId}/bank-accounts/${bankAccountId}`).then(res => res.data);
  },

  /*
   *  Credit Cards
   */

  creditCardsFetch: (companyId) => {
    return client.get(`/${companyId}/credit-cards`).then(res => res.data);
  },

  creditCardsCreate: (companyId, stripeToken) => {
    const body = { stripeToken };
    return client.post(`/${companyId}/credit-cards`, body).then(res => res.data);
  },

  creditCardsDelete: (companyId, creditCardId) => {
    return client.delete(`/${companyId}/credit-cards/${creditCardId}`).then(res => res.data);
  },

  /*
   *  Nonprofits
   */

  nonprofitsGet: (companyId, nonprofitId) => {
    return client.get(`/${companyId}/nonprofits/${nonprofitId}`).then(res => res.data);
  },

  nonprofitsApprove: (companyId, nonprofitId) => {
    return client.post(`/${companyId}/nonprofits/${nonprofitId}/approve`).then(res => res.data);
  },

  nonprofitsRestrict: (companyId, nonprofitId) => {
    return client.post(`/${companyId}/nonprofits/${nonprofitId}/restrict`).then(res => res.data);
  },

  nonprofitsUnsetApprovalStatus: (companyId, nonprofitId) => {
    return client.post(`/${companyId}/nonprofits/${nonprofitId}/unset-approval-status`).then(res => res.data);
  },

  nonprofitsSearch: (companyId, params) => {
    return client.get(`/${companyId}/nonprofits`, {params}).then(res => res.data);
  },

  nonprofitsUpdate: (companyId, nonprofitId, params={}) => {
    const body = params;
    return client.patch(`/${companyId}/nonprofits/${nonprofitId}`, body).then(res => res.data);
  },

  /*
   *  Employee Donations
   */

  employeeDonationsSearch: (companyId, {matchStatus, page, startDate, endDate, employeeId, campaignId, nonprofitId} = {}) => {
    const params = {matchStatus, page, startDate, endDate, employeeId, campaignId, nonprofitId};
    return client.get(`/${companyId}/employee-donations`, {params}).then(res => res.data);
  },

  /*
   *  Match Inquiries
   */

  matchInquiriesSearch: (companyId, {page, startDate, endDate, employeeId, nonprofitId} = {}) => {
    const params = {page, startDate, endDate, employeeId, nonprofitId};
    return client.get(`/${companyId}/match-inquiries`, {params}).then(res => res.data);
  },

  /*
   *  Offline Donations
   */

  offlineDonationsSearch: (companyId, {page, startDate, endDate, employeeId, nonprofitId, donatedDate} = {}) => {
    const params = {page, startDate, endDate, employeeId, nonprofitId, donatedDate};
    return client.get(`/${companyId}/offline-donations`, {params}).then(res => res.data);
  },

  offlineDonationsApprove: (companyId, offlineDonationId) => {
    return client.patch(`/${companyId}/offline-donations/${offlineDonationId}/approve`).then(res => res.data);
  },

  offlineDonationsReject: (companyId, offlineDonationId) => {
    return client.patch(`/${companyId}/offline-donations/${offlineDonationId}/reject`).then(res => res.data);
  },

  offlineDonationsCreate: (companyId, attrs) => {
    const body = attrs;
    return client.post(`/${companyId}/offline-donations`, body).then(res => res.data);
  },

  /*
   *  Company Charges
   */

  companyChargesFetch: (companyId) => {
    return client.get(`/${companyId}/company-charges`).then(res => res.data);
  },

  /*
   *  Company Purchases
   */

  companyPurchasesFetchPayroll: (companyId) => {
    return client.get(`/${companyId}/company-purchases/payroll`).then(res => res.data);
  },

  companyPurchasesGet: (companyId, purchaseId) => {
    return client.get(`/${companyId}/company-purchases/${purchaseId}`).then(res => res.data);
  },

  /*
   *  Slack Tokens
   */
  slackTokensClaim: (companyId, slackTokenId) => {
    return client.post(`/${companyId}/slack-tokens/${slackTokenId}/claim`).then(res => res.data);
  },

  slackTokensGet: (companyId, slackTokenId) => {
    return client.get(`/${companyId}/slack-tokens/${slackTokenId}`).then(res => res.data);
  },

  /*
   *  Slack Channels
   */

  slackChannelsGet: (companyId, slackChannelId) => {
    return client.get(`/${companyId}/slack-channels/${slackChannelId}`).then(res => res.data);
  },

  slackChannelsCreate: (companyId, name, isPrivate, op) => {
    const body = {name, isPrivate, op};
    return client.post(`/${companyId}/slack-channels`, body).then(res => res.data);
  },

  slackChannelsInviteMyself: (companyId, slackChannelId) => {
    return client.post(`/${companyId}/slack-channels/${slackChannelId}/invite-myself`).then(res => res.data);
  },

  slackChannelsFetchCompany: (companyId) => {
    return client.get(`/${companyId}/slack-channels/company`).then(res => res.data);
  },

  slackChannelsSetTopic: (companyId, slackChannelId, topic) => {
    const body = {topic};
    return client.patch(`/${companyId}/slack-channels/${slackChannelId}/topic`, body).then(res => res.data);
  },

  /*
   *  Wallet
   */

  walletLoad: (companyId, {amountInCents, paymentMethodId}) => {
    const body = {amountInCents, paymentMethodId};
    return client.post(`/${companyId}/wallet/load`, body).then(res => res.data);
  },

  /*
   *  Vol Events
   */

  volEventsSearch: (companyId, {limit=50, includeSystemEvents=false, ...query} = {}) => {
    const params = {limit, includeSystemEvents, ...query};
    return client.get(`/${companyId}/vol-events`, {params}).then(res => res.data);
  },

  volEventsGet: (companyId, volEventId) => {
    return client.get(`/${companyId}/vol-events/${volEventId}`).then(res => res.data);
  },

  volEventsDelete: (companyId, volEventId) => {
    return client.delete(`/${companyId}/vol-events/${volEventId}`).then(res => res.data);
  },

  volEventsArchive: (companyId, volEventId) => {
    return client.patch(`/${companyId}/vol-events/${volEventId}/archive`).then(res => res.data);
  },

  volEventsAnnounceInSlack: (companyId, volEventId) => {
    return client.post(`/${companyId}/vol-events/${volEventId}/announce-in-slack`).then(res => res.data);
  },

  volEventsRemindInSlack: (companyId, volEventId) => {
    return client.post(`/${companyId}/vol-events/${volEventId}/remind-in-slack`).then(res => res.data);
  },

  // client only
  // TODO: use FileSaver.js
  volEventsDownloadIcs: (companyId, volEventId) => {
    const path = `/${companyId}/vol-events/${volEventId}/ics`;
    return client.get(path).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'event.ics');
      // document.body.appendChild(link);
      link.click();
    });
  },

  volEventsCreate: (companyId, attrs) => {
    const body = attrs;
    return client.post(`/${companyId}/vol-events`, body).then(res => res.data);
  },

  // volEventsCreateHidden: (companyId, attrs) => {
  //   const body = attrs;
  //   return client.post(`/${companyId}/vol-events/hidden`, body).then(res => res.data);
  // },

  volEventsUpdate: (companyId, volEventId, attrs) => {
    const body = attrs;
    return client.patch(`/${companyId}/vol-events/${volEventId}`, body).then(res => res.data);
  },

  volEventsFetchShifts: (companyId, volEventId) => {
    return client.get(`/${companyId}/vol-events/${volEventId}/vol-event-shifts`).then(res => res.data);
  },

  /*
   *  Vol Time Entries
   */

  volTimeEntriesFetchGrouped: (companyId, {page, startDate, endDate, by}) => {
    const params = {page, startDate, endDate, by};
    return client.get(`/${companyId}/vol-time-entries/grouped`, {params}).then(res => res.data);
  },

  volTimeEntriesSearch: (companyId, {page, startDate, endDate, reviewStatus, volEventId, volEventShiftId, employeeId, pageSize, eventType, entryType}) => {
    const params = {page, startDate, endDate, reviewStatus, volEventId, volEventShiftId, employeeId, pageSize, eventType, entryType};
    return client.get(`/${companyId}/vol-time-entries/`, {params}).then(res => res.data);
  },

  volTimeEntriesApprove: (companyId, volTimeEntryId) => {
    return client.post(`/${companyId}/vol-time-entries/${volTimeEntryId}/approve`).then(res => res.data);
  },

  volTimeEntriesReject: (companyId, volTimeEntryId) => {
    return client.post(`/${companyId}/vol-time-entries/${volTimeEntryId}/reject`).then(res => res.data);
  },

  volTimeEntriesBulkReview: (companyId, ids, decision) => {
    const body = {ids, decision};
    return client.post(`/${companyId}/vol-time-entries/bulk-review`, body).then(res => res.data);
  },
  volTimeEntriesBulkApprove: (companyId, ids) => {
    return Endpoints.volTimeEntriesBulkReview(companyId, ids, 'approve');
  },
  volTimeEntriesBulkReject: (companyId, ids) => {
    return Endpoints.volTimeEntriesBulkReview(companyId, ids, 'reject');
  },

  volTimeEntriesUpdate: (companyId, vteId, params) => {
    const body = params;
    return client.patch(`/${companyId}/vol-time-entries/${vteId}`, body).then(res => res.data);
  },

  volTimeEntriesDelete: (companyId, vteId) => {
    return client.delete(`/${companyId}/vol-time-entries/${vteId}`).then(res => res.data);
  },

  volTimeEntriesCreateBatch: (companyId, params) => {
    const body = params;
    return client.post(`/${companyId}/vol-time-entries/batch`, body).then(res => res.data);
  },

  volTimeEntriesCreateBulk: (companyId, params) => {
    const body = params;
    return client.post(`/${companyId}/vol-time-entries/bulk`, body).then(res => res.data);
  },

  /*
   *  Campaigns
   */

  campaignsFetch: (companyId, {search, limit=200, isActive=false, hasDrive, activity, status, page} = {}) => {
    const params = {search, limit, isActive, hasDrive, activity, status, page};
    return client.get(`/${companyId}/campaigns`, {params}).then(res => res.data);
  },

  campaignsGet: (companyId, campaignId) => {
    return client.get(`/${companyId}/campaigns/${campaignId}`).then(res => res.data);
  },

  campaignsCreate: (companyId, attrs) => {
    const body = attrs;
    return client.post(`/${companyId}/campaigns`, body).then(res => res.data);
  },

  campaignsUpdate: (companyId, campaignId, attrs) => {
    const body = attrs;
    return client.patch(`/${companyId}/campaigns/${campaignId}`, body).then(res => res.data);
  },

  campaignsDelete: (companyId, campaignId) => {
    return client.delete(`/${companyId}/campaigns/${campaignId}`).then(res => res.data);
  },

  campaignsArchive: (companyId, campaignId) => {
    return client.patch(`/${companyId}/campaigns/${campaignId}/archive`).then(res => res.data);
  },

  campaignsAnnounceInSlack: (companyId, campaignId) => {
    return client.post(`/${companyId}/campaigns/${campaignId}/announce-in-slack`).then(res => res.data);
  },

  /*
   *  Groups
   */

  groupsFetch: (companyId, {limit=200, search} = {}) => {
    const params = {limit, search};
    return client.get(`/${companyId}/groups`, {params}).then(res => res.data);
  },

  groupsGet: (companyId, groupId) => {
    return client.get(`/${companyId}/groups/${groupId}`).then(res => res.data);
  },

  groupsDelete: (companyId, groupId) => {
    return client.delete(`/${companyId}/groups/${groupId}`).then(res => res.data);
  },

  groupsCreate: (companyId, attrs) => {
    const body = attrs;
    return client.post(`/${companyId}/groups`, body).then(res => res.data);
  },

  groupsUpdate: (companyId, groupId, attrs) => {
    const body = attrs;
    return client.patch(`/${companyId}/groups/${groupId}`, body).then(res => res.data);
  },

  groupsMembersAdd: (companyId, groupId, employeeId) => {
    const body = {employeeId};
    return client.post(`/${companyId}/groups/${groupId}/add-member`, body).then(res => res.data);
  },

  groupsMembersRemove: (companyId, groupId, employeeId) => {
    const body = {employeeId};
    return client.post(`/${companyId}/groups/${groupId}/remove-member`, body).then(res => res.data);
  },

  groupsMakeLead: (companyId, groupId, employeeId) => {
    const body = {employeeId};
    return client.post(`/${companyId}/groups/${groupId}/make-lead`, body).then(res => res.data);
  },

  groupsRemoveLead: (companyId, groupId, employeeId) => {
    const body = {employeeId};
    return client.post(`/${companyId}/groups/${groupId}/remove-lead`, body).then(res => res.data);
  },

  groupsTypesFetch: (companyId) => {
    return client.get(`/${companyId}/groups/types`).then(res => res.data);
  },

  groupsTypesUpdate: (companyId, data) => {
    return client.patch(`/${companyId}/groups/types`, data).then(res => res.data);
  },

  groupsGroupEmployeesSearch: (companyId, params={}) => {
    return client.get(`/${companyId}/groups/group-employees`, {params}).then(res => res.data);
  },

  groupsGroupEmployeesCreate: (companyId, groupId, employeeIds) => {
    const body = {employeeIds};
    return client.post(`/${companyId}/groups/${groupId}/group-employees`, body).then(res => res.data);
  },

  /*
   *  Gifts
   */

  giftsCreate: (companyId, params) => {
    const body = params;
    return client.post(`/${companyId}/gifts`, body).then(res => res.data);
  },

  giftsCreateBulk: (companyId, params) => {
    const body = params;
    return client.post(`/${companyId}/gifts/bulk`, body).then(res => res.data);
  },

  giftsSearch: (companyId, {page, startDate, endDate, status} = {}) => {
    const params = {page, startDate, endDate, status};
    return client.get(`/${companyId}/gifts`, {params}).then(res => res.data);
  },

  /*
   *  Grants
   */

  grantsCreate: (companyId, params) => {
    const body = params;
    return client.post(`/${companyId}/grants`, body).then(res => res.data);
  },

  grantsCreateOffline: (companyId, attrs) => {
    const body = attrs;
    return client.post(`/${companyId}/grants/offline`, body).then(res => res.data);
  },

  grantsUpdate: (companyId, grantId, attrs) => {
    const body = attrs;
    return client.patch(`/${companyId}/grants/${grantId}`, body).then(res => res.data);
  },

  grantsDelete: (companyId, grantId) => {
    return client.delete(`/${companyId}/grants/${grantId}`).then(res => res.data);
  },

  grantsSearch: (companyId, params={}) => {
    return client.get(`/${companyId}/grants`, {params}).then(res => res.data);
  },

  /*
   *  Brackets
   */

  bracketsFetch: (companyId, {limit=200, search} = {}) => {
    const params = {limit, search};
    return client.get(`/${companyId}/brackets`, {params}).then(res => res.data);
  },

  bracketsGet: (companyId, bracketId) => {
    return client.get(`/${companyId}/brackets/${bracketId}`).then(res => res.data);
  },

  bracketsGetDashboard: (companyId, bracketId) => {
    return client.get(`/${companyId}/brackets/${bracketId}/dashboard`).then(res => res.data);
  },

  bracketsGetLaunchDetails: (companyId, bracketId) => {
    return client.get(`/${companyId}/brackets/${bracketId}/launch-details`).then(res => res.data);
  },

  bracketsLaunch: (companyId, bracketId) => {
    return client.patch(`/${companyId}/brackets/${bracketId}/launch`).then(res => res.data);
  },

  bracketsDelete: (companyId, bracketId) => {
    return client.delete(`/${companyId}/brackets/${bracketId}`).then(res => res.data);
  },

  bracketsCreate: (companyId, attrs) => {
    const body = attrs;
    return client.post(`/${companyId}/brackets`, body).then(res => res.data);
  },

  bracketsCreateRandom: (companyId) => {
    return client.post(`/${companyId}/brackets/random`).then(res => res.data);
  },

  bracketsSetCrazy: (companyId, bracketId) => {
    return client.patch(`/${companyId}/brackets/${bracketId}/crazy`).then(res => res.data);
  },

  bracketsUpdate: (companyId, bracketId, attrs) => {
    const body = attrs;
    return client.patch(`/${companyId}/brackets/${bracketId}`, body).then(res => res.data);
  },

  bracketsFetchVoters: (companyId, bracketId, {limit=50, page=1} = {}) => {
    const params = {limit, page};
    return client.get(`/${companyId}/brackets/${bracketId}/voters`, {params}).then(res => res.data);
  },

  /*
   *  Transactions
   */

  balanceTransactionsSearch: (companyId, {page, startDate, endDate}) => {
    const params = {page, startDate, endDate};
    return client.get(`/${companyId}/balance-transactions`, {params}).then(res => res.data);
  },

  /*
   *  Budgets
   */

  budgetsFetch: (companyId) => {
    return client.get(`/${companyId}/budgets`).then(res => res.data);
  },

  budgetsGet: (companyId, idOrKey) => {
    return client.get(`/${companyId}/budgets/${idOrKey}`).then(res => res.data);
  },

  budgetsUpdateMatch: (companyId, {allocatedAmount, employeeAmount}={}) => {
    const body = {allocatedAmount, employeeAmount};
    return client.patch(`/${companyId}/budgets/match`, body).then(res => res.data);
  },

  budgetsUpdateDfd: (companyId, {allocatedAmount, employeeAmount, hourlyAmount}={}) => {
    const body = {allocatedAmount, employeeAmount, hourlyAmount};
    return client.patch(`/${companyId}/budgets/dfd`, body).then(res => res.data);
  },

  /*
   *  MS Teams
   */

  msTeamsChannelsGet: (companyId, channelId) => {
    return client.get(`/${companyId}/ms-teams/channels/${channelId}`).then(res => res.data);
  },

  msTeamsChannelsSearch: (companyId, name) => {
    const params = {name};
    return client.get(`/${companyId}/ms-teams/channels`, {params}).then(res => res.data);
  },

  msTeamsChannelsCreate: (companyId, name) => {
    const body = {name};
    return client.post(`/${companyId}/ms-teams/channels`, body).then(res => res.data);
  },

  /*
   *  Analytics / Stats
   */

  statsFetch: (companyId, {interval, startDate, endDate}={}) => {
    const params = {interval, startDate, endDate};
    return client.get(`/${companyId}/stats`, {params}).then(res => res.data);
  },

  analyticsFetch: (companyId, {groupBy='month', ...otherParams}={}) => {
    const params = {groupBy, ...otherParams};
    return client.get(`/${companyId}/analytics`, {params}).then(res => res.data);
  },

  /*
   *  Drive Donations
   */

  driveDonationsSearch: (companyId, {startDate, endDate, page, campaignId, employeeId, driveGoodId, entryType}={}) => {
    const params = {startDate, endDate, page, campaignId, employeeId, driveGoodId, entryType};
    return client.get(`/${companyId}/drive-donations`, {params}).then(res => res.data);
  },

  driveDonationsEntriesGet: (companyId, entryId) => {
    return client.get(`/${companyId}/drive-donations/entries/${entryId}`).then(res => res.data);
  },

  driveDonationsEntriesCreate: (companyId, attrs) => {
    const body = attrs;
    return client.post(`/${companyId}/drive-donations/entries`, body).then(res => res.data);
  },

  driveDonationsEntriesUpdate: (companyId, entryId, attrs) => {
    const body = attrs;
    return client.patch(`/${companyId}/drive-donations/entries/${entryId}`, body).then(res => res.data);
  },

  driveDonationsEntriesDelete: (companyId, entryId) => {
    return client.delete(`/${companyId}/drive-donations/entries/${entryId}`).then(res => res.data);
  },

  /*
   *  Cards
   */

  cardsFetch: (companyId) => {
    return client.get(`/${companyId}/cards`).then(res => res.data);
  },

  cardsCreate: (companyId, {imgPath}) => {
    const body = { imgPath };
    return client.post(`/${companyId}/cards`, body).then(res => res.data);
  },

  cardsDelete: (companyId, cardId) => {
    return client.delete(`/${companyId}/cards/${cardId}`).then(res => res.data);
  },

  cardsUpdate: (companyId, cardId, attrs) => {
    const body = attrs;
    return client.patch(`/${companyId}/cards/${cardId}`, body).then(res => res.data);
  },

  /*
   *  Company Ntee Rules
   */

  nteeRulesSearch: (companyId) => {
    return client.get(`/${companyId}/company-ntee-rules`).then(res => res.data);
  },

  nteeRulesSet: (companyId, {code, type, approvalStatus}) => {
    const body = {approvalStatus};
    return client.put(`/${companyId}/company-ntee-rules/${type}/${code}`, body).then(res => res.data);
  },

  /*
   *  Custom Attributes
   */

  cattrsFetch: (companyId) => {
    return client.get(`/${companyId}/custom-attributes`).then(res => res.data);
  },

  cattrsCreate: (companyId, attrs) => {
    const body = attrs;
    return client.post(`/${companyId}/custom-attributes`, body).then(res => res.data);
  },

  cattrsDelete: (companyId, cattrId) => {
    return client.delete(`/${companyId}/custom-attributes/${cattrId}`).then(res => res.data);
  },

  cattrsUpdate: (companyId, cattrId, attrs) => {
    const body = attrs;
    return client.patch(`/${companyId}/custom-attributes/${cattrId}`, body).then(res => res.data);
  },

  cattrOptionsCreate: (companyId, cattrId, attrs) => {
    const body = attrs;
    return client.post(`/${companyId}/custom-attributes/${cattrId}/custom-attribute-options`, body).then(res => res.data);
  },

  cattrOptionsUpdate: (companyId, cattrId, optionId, attrs) => {
    const body = attrs;
    return client.patch(`/${companyId}/custom-attributes/${cattrId}/custom-attribute-options/${optionId}`, body).then(res => res.data);
  },

  cattrOptionsDelete: (companyId, cattrId, optionId) => {
    return client.delete(`/${companyId}/custom-attributes/${cattrId}/custom-attribute-options/${optionId}`).then(res => res.data);
  },

};

export {
  client,
};

export default Endpoints;
