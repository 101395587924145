"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nativeTypesConfig = void 0;

var NativeTypes = _interopRequireWildcard(require("../NativeTypes"));

var _getDataFromDataTransfer = require("./getDataFromDataTransfer");

var _nativeTypesConfig;

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var nativeTypesConfig = (_nativeTypesConfig = {}, _defineProperty(_nativeTypesConfig, NativeTypes.FILE, {
  exposeProperties: {
    files: function files(dataTransfer) {
      return Array.prototype.slice.call(dataTransfer.files);
    },
    items: function items(dataTransfer) {
      return dataTransfer.items;
    },
    dataTransfer: function dataTransfer(_dataTransfer) {
      return _dataTransfer;
    }
  },
  matchesTypes: ['Files']
}), _defineProperty(_nativeTypesConfig, NativeTypes.HTML, {
  exposeProperties: {
    html: function html(dataTransfer, matchesTypes) {
      return (0, _getDataFromDataTransfer.getDataFromDataTransfer)(dataTransfer, matchesTypes, '');
    },
    dataTransfer: function dataTransfer(_dataTransfer2) {
      return _dataTransfer2;
    }
  },
  matchesTypes: ['Html', 'text/html']
}), _defineProperty(_nativeTypesConfig, NativeTypes.URL, {
  exposeProperties: {
    urls: function urls(dataTransfer, matchesTypes) {
      return (0, _getDataFromDataTransfer.getDataFromDataTransfer)(dataTransfer, matchesTypes, '').split('\n');
    },
    dataTransfer: function dataTransfer(_dataTransfer3) {
      return _dataTransfer3;
    }
  },
  matchesTypes: ['Url', 'text/uri-list']
}), _defineProperty(_nativeTypesConfig, NativeTypes.TEXT, {
  exposeProperties: {
    text: function text(dataTransfer, matchesTypes) {
      return (0, _getDataFromDataTransfer.getDataFromDataTransfer)(dataTransfer, matchesTypes, '');
    },
    dataTransfer: function dataTransfer(_dataTransfer4) {
      return _dataTransfer4;
    }
  },
  matchesTypes: ['Text', 'text/plain']
}), _nativeTypesConfig);
exports.nativeTypesConfig = nativeTypesConfig;