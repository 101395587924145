import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';

import AddressInput           from 'app/components/common/address-input';
import Checkbox               from 'app/components/common/checkbox';
import CityInput              from 'app/components/common/city-input';
import ConnectorLine          from 'app/components/common/connector-line';
import CurrencyInput          from 'app/components/common/currency-input';
import DatePicker             from 'app/components/common/date-picker';
import DateRangePicker        from 'app/components/common/date-range-picker';
import DebugClicker           from 'app/components/common/debug-clicker';
import EllipsisMenu           from 'app/components/common/ellipsis-menu';
import Icon                   from 'app/components/common/icon';
import IntegerInput           from 'app/components/common/integer-input';
import Link                   from 'app/components/common/link';
import LocationInput          from 'app/components/common/location-input';
import NonprofitInput         from 'app/components/common/nonprofit-input';
import NteePicker             from 'app/components/common/ntee-picker';
import Pagination             from 'app/components/common/pagination';
import Popper                 from 'app/components/common/popper';
import RadioButton            from 'app/components/common/radio-button';
import RecentDonors           from 'app/components/common/recent-donors';
import SparkleTriangle        from 'app/components/common/sparkle-triangle';
import StandardInput          from 'app/components/common/standard-input';
import StandardSelect         from 'app/components/common/standard-select';
import TimePicker             from 'app/components/common/time-picker';
import TimezoneInput          from 'app/components/common/timezone-input';
import MainLayout             from 'app/components/layout/main-layout';
import Bracket                from 'app/components/madness/bracket';
import confetti               from 'app/confetti';
import pricing2               from 'app/pricing2';

const foo = () => {
  bar();
};

class PageStyleGuide extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      startDateStr: null,
      endDateStr: null,
      address: null,
      timezone: null,
      timeStr: null,
      dateStr: null,
      balanceType: null,
      p2EmpCount: 1000,
      p2Match: true,
      p2Intl: false,
      p2Vol: false,
      p2Groups: false,
      p2Sso: false,
      p2Analytics: false,
      p2Payroll: false,
      p2Madness: false,
    };

    this.onSelectDateRange = this.onSelectDateRange.bind(this);
    this.onClickThrow = this.onClickThrow.bind(this);
    this.onClickConfetti = this.onClickConfetti.bind(this);
    this.onClickConfetti2 = this.onClickConfetti2.bind(this);
    this.onClickConfetti3 = this.onClickConfetti3.bind(this);

    this.onChangeP2EmpCount = this.onChangeP2EmpCount.bind(this);
    this.onChangeP2Match = this.onChangeP2Match.bind(this);
    this.onChangeP2Intl = this.onChangeP2Intl.bind(this);
    this.onChangeP2Vol = this.onChangeP2Vol.bind(this);
    this.onChangeP2Groups = this.onChangeP2Groups.bind(this);
    this.onChangeP2Analytics = this.onChangeP2Analytics.bind(this);
    this.onChangeP2Sso = this.onChangeP2Sso.bind(this);
    this.onChangeP2Payroll = this.onChangeP2Payroll.bind(this);
    this.onChangeP2Madness = this.onChangeP2Madness.bind(this);
  }

  onChangeP2EmpCount(p2EmpCount) {
    this.setState({p2EmpCount: (p2EmpCount || 0)});
  }
  onChangeP2Match(event) {
    const p2Match = event.target.checked;
    this.setState({p2Match});
  }
  onChangeP2Intl(event) {
    const p2Intl = event.target.checked;
    this.setState({p2Intl});
  }
  onChangeP2Vol(event) {
    const p2Vol = event.target.checked;
    this.setState({p2Vol});
  }
  onChangeP2Groups(event) {
    const p2Groups = event.target.checked;
    this.setState({p2Groups});
  }
  onChangeP2Analytics(event) {
    const p2Analytics = event.target.checked;
    this.setState({p2Analytics});
  }
  onChangeP2Sso(event) {
    const p2Sso = event.target.checked;
    this.setState({p2Sso});
  }
  onChangeP2Payroll(event) {
    const p2Payroll = event.target.checked;
    this.setState({p2Payroll});
  }
  onChangeP2Madness(event) {
    const p2Madness = event.target.checked;
    this.setState({p2Madness});
  }
  get p2Price() {
    const {p2EmpCount: empCount, p2Match: match, p2Intl: intl, p2Vol: vol, p2Groups: groups, p2Sso: sso, p2Analytics: analytics, p2Payroll: payroll, p2Madness: madness} = this.state;
    return pricing2.getPrice(empCount, {match, intl, vol, groups, sso, payroll, analytics, madness});
  }

  onClickConfetti() {
    confetti.fireworks();
  }
  onClickConfetti2() {
    confetti.fireworks2();
  }
  onClickConfetti3(event) {
    confetti.highlight(event.target);
  }

  onClickThrow() {
    foo();
  }

  onSelectDateRange({startDateStr, endDateStr}) {
    this.setState({startDateStr, endDateStr});
  }

  onChangeAddress(address) {
    console.log('onChangeAddress', address);
    this.setState({address});
  }

  onChangeTimezone(timezone) {
    this.setState({timezone});
  }

  onChangeDate(dateStr) {
    this.setState({dateStr});
  }

  onChangeBalanceType(balanceType) {
    this.setState({balanceType});
  }

  renderColors() {
    const colorsGroups = [
      ['Blue', 'Pink', 'Green', 'Purple', 'Yellow', 'Orange', 'Danger'],
      ['Midnight', 'Slate', 'Fog', 'Cloud', 'Ice', 'White'],
      ['Rainbow', 'Sunrise', 'Jungle'],
      ['Gradient Purple', 'Gradient Orange', 'Gradient Pink', 'Gradient Blue', 'Gradient Green'],
    ];
    return (<>
      <h2>Colors</h2>
      {colorsGroups.map((colorGroup, i) => (
        <div key={i} className="color-row">
          {colorGroup.map((color, j) => (
            <div key={j} className="color">
              <div className={`color-circle ${color.toLowerCase().replace(' ', '-')}`} />
              <div className="color-name">{color}</div>
            </div>
          ))}
        </div>
      ))}
    </>);
  }

  onChangeTime(timeStr) {
    this.setState({timeStr});
  }

  renderButtons() {
    return (<>
      <h2>Buttons</h2>
      <div className="button-cols">
        <div className="button-row">
          <button className="btn midnight">Button</button>
          <button className="btn pink">Button</button>
          <button className="btn blue">Button</button>
          <button className="btn green">Button</button>
          <button className="btn danger">Button</button>
          <button className="btn slate">Button</button>
          <button className="btn icon"><Icon.EnvelopePigeon />Button</button>
        </div>
        <div className="button-row">
          <button className="btn secondary midnight">Button</button>
          <button className="btn secondary pink">Button</button>
          <button className="btn secondary blue">Button</button>
          <button className="btn secondary green">Button</button>
          <button className="btn secondary danger">Button</button>
          <button className="btn secondary slate">Button</button>
          <button className="btn secondary icon"><Icon.EnvelopePigeon />Button</button>
        </div>
        <div className="button-row">
          <button className="btn special sunrise">Button</button>
          <button className="btn special jungle">Button</button>
          <button className="btn special slack">Slack Button</button>
          <button className="btn special jungle icon"><Icon.EnvelopePigeon />Button</button>
        </div>
        <div className="button-row">
          <button className="btn primary pink small">Button</button>
          <button className="btn secondary blue small">Button</button>
          <button className="btn special jungle small">Button</button>
          <button className="btn primary small icon"><Icon.EnvelopePigeon />Button</button>
        </div>
        <div className="button-row">
          <button className="btn special pill orange icon secondary"><Icon.EnvelopePigeon /> Button</button>
          <button className="btn special pill orange icon"><Icon.EnvelopePigeon /> Button</button>
          <button className="btn special pill purple icon secondary"><Icon.EnvelopePigeon /> Button</button>
          <button className="btn special pill purple icon"><Icon.EnvelopePigeon /> Button</button>
        </div>
        <div className="button-row">
          <button disabled className="btn secondary blue">Button</button>
          <button disabled className="btn green">Button</button>
          <button disabled className="btn special jungle small">Button</button>
          <button disabled className="btn special pill orange icon secondary"><Icon.EnvelopePigeon /> Button</button>
        </div>
      </div>
    </>);
  }

  renderIcons() {
    return (<>
      <h2>Icons</h2>
      <div className="icons">
        {_.map(Icon, (IconComp, name) => (
          <div className="icon-group" key={name}>
            <IconComp />
            <span>{name}</span>
          </div>
        ))}
      </div>
    </>);
  }

  render() {
    return (
      <MainLayout dnd>
        <div className="style-guide widther">
          <h1>Style Guide</h1>
          {this.renderColors()}

          {this.renderButtons()}
          {this.renderIcons()}

          <h2>Confetti</h2>
          <button className="btn" onClick={this.onClickConfetti}>Fireworks 1</button>
          &nbsp;
          <button className="btn" onClick={this.onClickConfetti2}>Fireworks 2</button>
          &nbsp;
          <button className="btn" onClick={this.onClickConfetti3}>Highlight</button>

          <h2>Standard Input</h2>
          <StandardInput type="text" name="firstName" label="First name" icon={<Icon.EnvelopePigeon />} />
          <StandardInput type="text" name="firstName" label="First name" value="Bad input" icon={<Icon.EnvelopePigeon />} validations={{firstName: ['invalid input']}} />

          <h2>Standard Select</h2>
          <StandardSelect
            options={[{label: 'Space Cats', value: 1}, {label: 'Shaved Alpacas', value: 2}]}
            value={this.state.standardSelectValue}
            onSelect={(v) => this.setState({standardSelectValue: v})}
          />

          <h2>Date Range Picker</h2>
          <DateRangePicker className="no-trailing nodir" startDateStr={this.state.startDateStr} endDateStr={this.state.endDateStr} onSelect={this.onSelectDateRange} leftAlign />
          <br /><br />
          <DateRangePicker className="no-trailing nodir" startDateStr={this.state.startDateStr} endDateStr={this.state.endDateStr} onSelect={this.onSelectDateRange} leftAlign name="foo" validations={{foo: ['invalid date range']}} />

          <h2>Pagination</h2>
          <Pagination
            pagination={{pageCount: 42, page: 3, resultCount: 411, pageSize: 10}}
            onSelectPage={(page) => { console.log('selected page', page) }}
          />

          <h2>Address Input</h2>
          <AddressInput onChange={this.onChangeAddress.bind(this)} address={this.state.address} />
          <AddressInput onChange={this.onChangeAddress.bind(this)} address={this.state.address} name="foo" validations={{foo: ['invalid address']}} />

          <h2>Timezone Input</h2>
          <TimezoneInput onChange={this.onChangeTimezone.bind(this)} timezone={this.state.timezone} />
          <br /><br />
          <TimezoneInput onChange={this.onChangeTimezone.bind(this)} timezone={this.state.timezone} name="foo" validations={{foo: ['invalid timezone']}} />

          <h2>Time Picker</h2>
          <TimePicker ddAlign="left" timeStr={this.state.timeStr} onChange={this.onChangeTime.bind(this)} />
          <br /><br />
          <TimePicker ddAlign="left" timeStr={this.state.timeStr} onChange={this.onChangeTime.bind(this)} name="foo" validations={{foo: ['invalid time']}} />

          <h2>Date Picker</h2>
          <DatePicker ddAlign="left" onChange={this.onChangeDate.bind(this)} dateStr={this.state.dateStr} />
          <br /><br />
          <DatePicker ddAlign="left" onChange={this.onChangeDate.bind(this)} dateStr={this.state.dateStr} name="foo" validations={{foo: ['invalid date']}} />

          <h2>Radio Buttons</h2>
          <div className="style-guide-radios">
            <RadioButton name="rb-thing" id="rb-gadget" />
            <label htmlFor="rb-gadget">Gadget</label>
            <RadioButton name="rb-thing" id="rb-gizmo" />
            <label htmlFor="rb-gizmo">Gizmo</label>
            <RadioButton name="rb-thing" id="rb-whoozit" />
            <label htmlFor="rb-whoozit">Whoozit</label>
          </div>

          <h2>Checkboxes</h2>
          <div className="style-guide-radios">
            <Checkbox id="cb-demo-plain" />
            <label htmlFor="cb-demo-plain">Default</label>
            <Checkbox id="cb-demo-toggle" isToggle />
            <label htmlFor="cb-demo-toggle">Toggle</label>
          </div>

          <h2>Throw an Error</h2>
          <button className="btn danger" onClick={this.onClickThrow}>Throw an Error</button>

          <h2>Recent Donors</h2>
          <RecentDonors donorCount={0} recentDonors={[]} />
          <RecentDonors donorCount={1} recentDonors={[]} />
          <RecentDonors donorCount={5} recentDonors={[]} />
          <RecentDonors donorCount={3} recentDonors={[{firstName: 'Marvin', id: 'a'}, {firstName: 'Tyler', id: 'b'}, {firstName: 'Rachel', id: 'c'}]} />
          <RecentDonors donorCount={3} recentDonors={[{firstName: 'Rachel', id: 'c'}]} />

          <h2>Sparkle Triangle</h2>
          <div className="triangles-con">
            <SparkleTriangle orientation={1} scale={2} color="blue" />
            <SparkleTriangle orientation={2} scale={2} color="purple" />
            <SparkleTriangle orientation={3} scale={1} color="cyan" />
            <SparkleTriangle orientation={4} scale={2} color="green" />
          </div>
          <div className="triangles-con">
            <SparkleTriangle orientation={2} scale={3} color="orange" />
            <SparkleTriangle orientation={3} scale={1} color="pink" />
            <SparkleTriangle orientation={4} scale={1} color="orange" />
            <SparkleTriangle orientation={1} scale={2} color="slack" />
          </div>

          <h2>Connector Line</h2>
          <div style={{width: 300}}>
            <ConnectorLine />
            <br />
            <ConnectorLine error />
            <br />
            <ConnectorLine success />
          </div>

          <h2>Bracket</h2>
          <select onChange={(e) => {this.setState({bracketSize: parseInt(e.target.value)})}} defaultValue={16}>
            <option value={2}>2</option>
            <option value={4}>4</option>
            <option value={8}>8</option>
            <option value={16}>16</option>
            <option value={32}>32</option>
            <option value={64}>64</option>
          </select>
          <Bracket bracket={{name: 'Bracket Name', size: (this.state.bracketSize || 16)}} />

          <h2>City Input</h2>
          <CityInput onChange={(selectedCity) => {this.setState({selectedCity})}} selectedCity={this.state.selectedCity} />
          {this.state.selectedCity && (<>
            <a href={`https://www.google.com/maps/@${this.state.selectedCity.latitude},${this.state.selectedCity.longitude},12z`} target="blank">Open in gmaps: {this.state.selectedCity.label}</a>
          </>)}
          <LocationInput onChange={(selectedLocation) => this.setState({selectedLocation})} location={this.state.selectedLocation} />

          <h2>Popper</h2>
          <Popper tagType="button" className="btn" hoverTrigger hoverDelay={500} popContent="asdf" popType="tooltip">
            Delayed Hover Tooltip
          </Popper>
          &nbsp;
          <Popper tagType="button" className="btn" focusTrigger popContent="asdf" popType="popover" placement="left">
            Focus Popover Left
          </Popper>

          <h2>Ellipsis Menu</h2>
          <EllipsisMenu>
            <Link href="/wallet"><Icon.AccountingBill />Link to Wallet</Link>
            <Link onClick={(event) => {event.preventDefault(); console.log('------ item onClick')}}><Icon.Megaphone1 />Log to console</Link>
          </EllipsisMenu>
          <EllipsisMenu>
            <Link href="/wallet"><Icon.AccountingBill />Link to Wallet</Link>
            <Link onClick={(event) => {event.preventDefault(); console.log('------ item onClick')}}><Icon.Megaphone1 />Log to console</Link>
          </EllipsisMenu>

          <h2>DebugClicker</h2>
          <DebugClicker id="A" />
          <DebugClicker id="B" />
          <DebugClicker id="C" />

          <h2>NTEE Picker</h2>
          <NteePicker nteeCode={this.state.ntee} onChange={(ntee) => this.setState({ntee})} />

          <h2>Currency Input</h2>
          <CurrencyInput amount={this.state.ciVal?.amount} currencyCode="JPY" onChange={(ciVal) => this.setState({ciVal})} />
          <pre>{JSON.stringify(this.state.ciVal || {}, null, 4)}</pre>

          <h2>Pricing V2</h2>
          <h3>1. Enter Employee Count</h3>
          <IntegerInput label="Employee Count" value={this.state.p2EmpCount} onChange={this.onChangeP2EmpCount} />
          <h3>2. Select Modules</h3>
          <div className="style-guide-radios">
            <Checkbox id="cb-p2-match" checked={this.state.p2Match} onChange={this.onChangeP2Match} />
            <label htmlFor="cb-p2-match">Match</label>

            <Checkbox id="cb-p2-intl" checked={this.state.p2Intl} onChange={this.onChangeP2Intl} />
            <label htmlFor="cb-p2-intl">International</label>

            <Checkbox id="cb-p2-vol" checked={this.state.p2Vol} onChange={this.onChangeP2Vol} />
            <label htmlFor="cb-p2-vol">Volunteer</label>

            <Checkbox id="cb-p2-groups" checked={this.state.p2Groups} onChange={this.onChangeP2Groups} />
            <label htmlFor="cb-p2-groups">Groups</label>

            <Checkbox id="cb-p2-analytics" checked={this.state.p2Analytics} onChange={this.onChangeP2Analytics} />
            <label htmlFor="cb-p2-analytics">Analytics</label>

            <Checkbox id="cb-p2-sso" checked={this.state.p2Sso} onChange={this.onChangeP2Sso} />
            <label htmlFor="cb-p2-sso">SSO</label>

            <Checkbox id="cb-p2-payroll" checked={this.state.p2Payroll} onChange={this.onChangeP2Payroll} />
            <label htmlFor="cb-p2-payroll">Payroll</label>

            <Checkbox id="cb-p2-madness" checked={this.state.p2Madness} onChange={this.onChangeP2Madness} />
            <label htmlFor="cb-p2-madness">Giving Madness</label>
          </div>
          <h3>3. $$$</h3>
          {this.p2Price ? (
            <table className="default" style={{width: 'unset'}}>
              <tbody>
                <tr>
                  <th className="right">Match</th>
                  <td className="right">{pricing2.format(this.p2Price.match)}</td>
                </tr>
                <tr>
                  <th className="right">International</th>
                  <td className="right">{pricing2.format(this.p2Price.intl)}</td>
                </tr>
                <tr>
                  <th className="right">Volunteering</th>
                  <td className="right">{pricing2.format(this.p2Price.vol)}</td>
                </tr>
                <tr>
                  <th className="right">Groups</th>
                  <td className="right">{pricing2.format(this.p2Price.groups)}</td>
                </tr>
                <tr>
                  <th className="right">Analytics</th>
                  <td className="right">{pricing2.format(this.p2Price.analytics)}</td>
                </tr>
                <tr>
                  <th className="right">SSO</th>
                  <td className="right">{pricing2.format(this.p2Price.sso)}</td>
                </tr>
                <tr>
                  <th className="right">Payroll</th>
                  <td className="right">{pricing2.format(this.p2Price.payroll)}</td>
                </tr>
                <tr>
                  <th className="right">Giving Madness</th>
                  <td className="right">{pricing2.format(this.p2Price.madness)}</td>
                </tr>
                <tr>
                  <th className="right">Total</th>
                  <td className="right"><strong>{pricing2.format(this.p2Price.total)}</strong></td>
                </tr>
              </tbody>
            </table>
          ) : (
            'Contact Us'
          )}

          <h2>Nonprofit Selector</h2>
          <div style={{width: 300}}>
            <NonprofitInput selectedNonprofit={this.state.selectedNp} onChange={(selectedNp) => this.setState({selectedNp})} intl advanced />
          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </MainLayout>
    );
  }

}

const stateToProps = (state) => {

};

export default connect()(PageStyleGuide);
