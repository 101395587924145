// import { createSelector } from 'reselect';
// import _ from 'lodash';

// import EntitiesSlx from 'app/selectors/entities';

const selCreatePending = state => state.companyAdmin.modalCreditCard.createPending;
const selCreateValidations = state => state.companyAdmin.modalCreditCard.createValidations;

export default {
  createPending: selCreatePending,
  createValidations: selCreateValidations,
};
