import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ModalAx from 'app/actions/company-admin/modal-credit-card';
import Icon from 'app/components/common/icon';
import Modal from 'app/components/common/modal';
import CreditCardInput from 'app/components/company-admin/wallet/credit-card-input';
import ModalSlx from 'app/selectors/company-admin/modal-credit-card';

class ModalCreditCard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      ccComplete: false,
    };

    this.refCcInput = React.createRef();

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onChangeCompleteCc = this.onChangeCompleteCc.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }


  onCloseModal() {
    this.props.onClose();
  }

  onChangeCompleteCc(ccComplete) {
    this.setState({ccComplete});
  }

  onClickSave() {
    this.props.create(this.refCcInput.current.params.card).then(() => {
      this.props.onClose({didCreate: true});
    });
  }

  render() {
    const { pending } = this.props;
    const { ccComplete } = this.state;
    const disabled = !ccComplete || pending;
    const btnText = pending ? 'Saving...' : 'Save';

    return (
      <Modal className="ca-modal-cc" onClose={this.onCloseModal}>
        <div className="ca-modal-cc-header">
          <h1>New Credit Card</h1>
        </div>
        <div className="ca-modal-cc-body">
          <CreditCardInput ref={this.refCcInput} onChangeComplete={this.onChangeCompleteCc} />
        </div>
        <div className="ca-modal-cc-footer">
          <button className="btn blue" disabled={disabled} onClick={this.onClickSave}>{btnText}</button>
        </div>
      </Modal>
    );
  }

}

ModalCreditCard.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  pending: ModalSlx.createPending(state),
});

const dispatchToProps = (dispatch) => ({
  create: (cardElement) => dispatch(ModalAx.createCc(cardElement)),
});

export default connect(stateToProps, dispatchToProps)(ModalCreditCard);
