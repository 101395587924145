import _ from 'lodash';
import { createSelector } from 'reselect';

import ToastAx           from 'app/actions/toast';
import CadminApi         from 'app/apis/company-admin';
import history           from 'app/history';
import reducerUtils      from 'app/reducers/utils';
import CadminSlx         from 'app/selectors/company-admin/';
import EntitiesSlx       from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  FETCH_ALL: 'CADMIN_CATTRS_FETCH_ALL',
  CREATE: 'CADMIN_CATTRS_CREATE',
  DELETE: 'CADMIN_CATTRS_DELETE',
  UPDATE: 'CADMIN_CATTRS_UPDATE',
  OPTIONS_CREATE: 'CADMIN_CATTRS_OPTIONS_CREATE',
  OPTIONS_UPDATE: 'CADMIN_CATTRS_OPTIONS_UPDATE',
  OPTIONS_DELETE: 'CADMIN_CATTRS_OPTIONS_DELETE',
};

const Ax = {

  fetchAll: (companyId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.cattrsFetch(companyId);
    return dispatch({type: Types.FETCH_ALL, promise, _entities: ['customAttributes', 'customAttributeOptions']});
  },

  create: (companyId, attrs) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.cattrsCreate(companyId, attrs);
    promise.then(() => {
      dispatch(ToastAx.success('Custom attribute created.'));
    });
    return dispatch({type: Types.CREATE, promise, _entities: ['customAttribute', 'customAttributeOptions', 'cattrSet']});
  },

  delete: (companyId, cattrId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.cattrsDelete(companyId, cattrId);
    promise.then(() => {
      dispatch(ToastAx.success('Custom attribute deleted.'));
    });
    return dispatch({type: Types.DELETE, promise, companyId, cattrId});
  },

  update: (companyId, cattrId, attrs) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.cattrsUpdate(companyId, cattrId, attrs);
    promise.then(() => {
      dispatch(ToastAx.success('Custom attribute updated.'));
    });
    return dispatch({type: Types.UPDATE, promise, _entities: ['customAttribute', 'customAttributeOptions', 'cattrSet']});
  },

  optionsCreate: (companyId, cattrId, attrs) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState())
    const promise = CadminApi.cattrOptionsCreate(companyId, cattrId, {...attrs, customAttributeId: cattrId});
    promise.then(() => {
      dispatch(ToastAx.success('Option created.'));
    });
    return dispatch({type: Types.OPTIONS_CREATE, promise, _entities: ['customAttribute', 'customAttributeOptions', 'cattrSet']});
  },

  optionsUpdate: (companyId, cattrId, optionId, attrs) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState())
    const promise = CadminApi.cattrOptionsUpdate(companyId, cattrId, optionId, attrs);
    promise.then(() => {
      dispatch(ToastAx.success('Option updated.'));
    });
    return dispatch({type: Types.OPTIONS_UPDATE, promise, _entities: ['customAttribute', 'customAttributeOptions', 'cattrSet']});
  },

  optionsDelete: (companyId, cattrId, optionId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState())
    const promise = CadminApi.cattrOptionsDelete(companyId, cattrId, optionId);
    promise.then(() => {
      dispatch(ToastAx.success('Option deleted.'));
    });
    return dispatch({type: Types.OPTIONS_DELETE, promise, _entities: ['customAttribute', 'customAttributeOptions', 'cattrSet']});
  },

};



/*
 *  Reducer
 */

const initialState = {
  fetchAllPending: false,
  companyIdIdsMap: {},
  createPending: false,
  updatePending: false,
  deletePending: false,
  optionsCreatePending: false,
  optionsUpdatePending: false,
  optionsDeletePending: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.FETCH_ALL}_PENDING`]: (state, action) => {
    return {...state,
      fetchAllPending: true,
    };
  },
  [`${Types.FETCH_ALL}_RESOLVED`]: (state, action) => {
    const companyId = action.result.companyId;
    const cattrIds = action.result.customAttributes.map(ca => ca.id);
    return {...state,
      fetchAllPending: false,
      companyIdIdsMap: {...state.companyIdIdsMap,
        [companyId]: cattrIds,
      },
    };
  },
  [`${Types.FETCH_ALL}_REJECTED`]: (state, action) => {
    return {...state,
      fetchAllPending: false,
    };
  },

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, action) => {
    const companyId = action.result.companyId;
    const cattr = action.result.customAttribute;
    return {...state,
      createPending: false,
      companyIdIdsMap: {...state.companyIdIdsMap,
        [companyId]: [cattr.id, ...state.companyIdIdsMap[companyId]],
      },
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, action) => {
    return {...state,
      createPending: false,
    };
  },

  [`${Types.UPDATE}_PENDING`]: (state, action) => {
    return {...state,
      updatePending: true,
    };
  },
  [`${Types.UPDATE}_RESOLVED`]: (state, action) => {
    return {...state,
      updatePending: false,
    };
  },
  [`${Types.UPDATE}_REJECTED`]: (state, action) => {
    return {...state,
      updatePending: false,
    };
  },

  [`${Types.DELETE}_PENDING`]: (state, action) => {
    return {...state,
      deletePending: true,
    };
  },
  [`${Types.DELETE}_RESOLVED`]: (state, action) => {
    const {companyId, cattrId} = action;
    const ids = [...state.companyIdIdsMap[companyId]];
    const index = ids.indexOf(cattrId);
    delete ids[index];
    return {...state,
      deletePending: false,
      companyIdIdsMap: {...state.companyIdIdsMap,
        [companyId]: ids,
      },
    };
  },
  [`${Types.DELETE}_REJECTED`]: (state, action) => {
    return {...state,
      deletePending: false,
    };
  },

  [`${Types.OPTIONS_CREATE}_PENDING`]: (state, action) => {
    return {...state,
      optionsCreatePending: true,
    };
  },
  [`${Types.OPTIONS_CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      optionsCreatePending: false,
    };
  },
  [`${Types.OPTIONS_CREATE}_REJECTED`]: (state, action) => {
    return {...state,
      optionsCreatePending: false,
    };
  },

  [`${Types.OPTIONS_UPDATE}_PENDING`]: (state, action) => {
    return {...state,
      optionsUpdatePending: true,
    };
  },
  [`${Types.OPTIONS_UPDATE}_RESOLVED`]: (state, action) => {
    return {...state,
      optionsUpdatePending: false,
    };
  },
  [`${Types.OPTIONS_UPDATE}_REJECTED`]: (state, action) => {
    return {...state,
      optionsUpdatePending: false,
    };
  },

  [`${Types.OPTIONS_DELETE}_PENDING`]: (state, action) => {
    return {...state,
      optionsDeletePending: true,
    };
  },
  [`${Types.OPTIONS_DELETE}_RESOLVED`]: (state, action) => {
    return {...state,
      optionsDeletePending: false,
    };
  },
  [`${Types.OPTIONS_DELETE}_REJECTED`]: (state, action) => {
    return {...state,
      optionsDeletePending: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selFetchAllPending      = state => state.companyAdmin.cattrs.fetchAllPending;
  const selCompanyIdIdsMap      = state => state.companyAdmin.cattrs.companyIdIdsMap;
  const selCreatePending        = state => state.companyAdmin.cattrs.createPending;
  const selUpdatePending        = state => state.companyAdmin.cattrs.updatePending;
  const selDeletePending        = state => state.companyAdmin.cattrs.deletePending;
  const selOptionsCreatePending = state => state.companyAdmin.cattrs.optionsCreatePending;
  const selOptionsUpdatePending = state => state.companyAdmin.cattrs.optionsUpdatePending;
  const selOptionsDeletePending = state => state.companyAdmin.cattrs.optionsDeletePending;

  const selCattrs = createSelector(
    [selCompanyIdIdsMap, EntitiesSlx.customAttributes, EntitiesSlx.customAttributeOptions, CadminSlx.companyId],
    (cidIdsMap, cattrsIdMap, cattrOptionsIdMap, cid) => {
      const cattrIds = cidIdsMap[cid];
      if (!cattrIds) return null;
      return cattrIds.map((cattrId) => {
        if (!cattrsIdMap[cattrId]) return null;
        const cattr = {...cattrsIdMap[cattrId]};
        cattr.options = cattr.customAttributeOptionIds.map((optionId) => {
          return cattrOptionsIdMap[optionId];
        }).filter(o => o);
        return cattr;
      }).filter(ca => ca);
    }
  );

  return {
    fetchAllPending: selFetchAllPending,
    cattrs: selCattrs,
    createPending: selCreatePending,
    updatePending: selUpdatePending,
    deletePending: selDeletePending,
    optionsCreatePending: selOptionsCreatePending,
    optionsUpdatePending: selOptionsUpdatePending,
    optionsDeletePending: selOptionsDeletePending,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
