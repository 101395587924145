import _ from 'lodash';
import timm from 'timm';

import { Types as AccountTypes }                  from 'app/actions/account';
import { Types as ActionsTypes }                  from 'app/actions/actions';
import { Types as AuthTypes }                     from 'app/actions/auth';
import { Types as ClaimTypes }                    from 'app/actions/claim';
import { Types as DonationTypes }                 from 'app/actions/donations';
import { Types as GiftTypes }                     from 'app/actions/gifts';
import { Types as NonprofitsTypes }               from 'app/actions/nonprofits';
import { Types as PageNonprofitDashboardTypes }   from 'app/actions/page-nonprofit-dashboard';
// import { Types as PageNonprofitProfileEditTypes } from 'app/actions/page-nonprofit-profile-edit';
import { Types as RecDonationTypes }              from 'app/actions/recurring-donations';
import { Types as UsersTypes }                    from 'app/actions/users';
import { Types as WalletTypes }                   from 'app/actions/wallet';
import { Types as CreditCardsTypes }              from 'app/ducks/credit-cards';
import utils                                      from 'app/reducers/utils';

const initialState = {};

const currentUserUpdate = (state, {result: {currentUser}}) => {
  if (!currentUser) return state;
  return timm.mergeIn(state, [currentUser.id], currentUser);
};

const reducer = utils.createReducer(initialState, {

  ...utils.entityHandlers(['user', 'users']),

  [AuthTypes.SET_CURRENT_USER]: (state, {currentUser}) => {
    if (!currentUser) return state;
    return timm.mergeIn(state, [currentUser.id], currentUser);
  },

  [`${ClaimTypes.SUBMIT}_RESOLVED`]: currentUserUpdate,
  [`${WalletTypes.LOAD}_RESOLVED`]: currentUserUpdate,
  [`${RecDonationTypes.CREATE}_RESOLVED`]: currentUserUpdate,
  [`${RecDonationTypes.RESUME}_RESOLVED`]: currentUserUpdate,
  [`${DonationTypes.CREATE}_RESOLVED`]: currentUserUpdate,
  [`${GiftTypes.CREATE}_RESOLVED`]: currentUserUpdate,
  [`${GiftTypes.REDEEM}_RESOLVED`]: currentUserUpdate,
  [`${AccountTypes.UPDATE_AVATAR}_RESOLVED`]: currentUserUpdate,
  [`${AccountTypes.UPDATE}_RESOLVED`]: currentUserUpdate,
  [`${AccountTypes.PARTIAL_UPDATE}_RESOLVED`]: currentUserUpdate,
  [`${AuthTypes.GET_CURRENT_USER}_RESOLVED`]: currentUserUpdate,
  [`${AuthTypes.PW_LOGIN}_RESOLVED`]: currentUserUpdate,
  [`${AuthTypes.SIGN_UP}_RESOLVED`]: currentUserUpdate,
  [`${CreditCardsTypes.MAKE_DEFAULT}_RESOLVED`]: currentUserUpdate,
  [`${CreditCardsTypes.CREATE}_RESOLVED`]: currentUserUpdate,
  // [`${PageNonprofitProfileEditTypes.PUBLISH}_RESOLVED`]: currentUserUpdate,

  [`${UsersTypes.GET}_RESOLVED`]: (state, {result: {user}}) => {
    return timm.mergeIn(state, [user.id], user);
  },

  [`${NonprofitsTypes.GET}_RESOLVED`]: (state, {result: {users}}) => {
    return timm.mergeDeep(state, utils.idMap(users));
  },

  [`${ActionsTypes.FETCH}_RESOLVED`]: (state, {result: {users}}) => {
    return timm.mergeDeep(state, utils.idMap(users));
  },
  [`${ActionsTypes.FETCH_MINE}_RESOLVED`]: (state, {result: {users}}) => {
    return timm.mergeDeep(state, utils.idMap(users));
  },

  [`${PageNonprofitDashboardTypes.FETCH_DISBURSEMENTS}_RESOLVED`]: (state, {result: {users}}) => {
    return timm.mergeDeep(state, utils.idMap(users));
  },

});

export default reducer;
