import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import MillieApi from 'app/apis/millie';
import Icon from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';

const getImgNameFromPath = (path) => {
  if (!path) return null;
  const s3Name = path.split('/').pop();
  return s3Name.split('-').slice(1).join('-');
};

class ImageInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      imagePath: null,
      failed: false,
    };

    this.refStandardInput = React.createRef();

    this.onChange = this.onChange.bind(this);
  }

  upload(file) {
    const {nonprofitProfileId, onChange} = this.props;
    const didChange = !!this.state.imagePath;
    this.setState({uploading: true, imagePath: null, failed: false});
    if (didChange) onChange(null);
    MillieApi.nonprofitProfilesUploadImage(nonprofitProfileId, file)
      .then(({imagePath}) => {
        this.setState({uploading: false, imagePath});
        onChange(imagePath);
      })
      .catch((error) => {
        this.setState({uploading: false, failed: true})
      });
  }

  clear() {
    this.setState({imagePath: null});
    this.props.onChange(null);
  }

  onChange(event, file) {
    // const file = _.get(this.refStandardInput, 'current.element.files[0]');
    file ? this.upload(file) : this.clear();
  }

  render() {
    const {uploading} = this.state;
    const {name, label, validations, pathValue} = this.props;
    const imgName = getImgNameFromPath(pathValue);

    return (
      <StandardInput
        type="file"
        name={name}
        label={label}
        value={imgName || ''}
        validations={validations}
        icon={<Icon.ImageFileAdd />}
        rightIcon={uploading ? <Icon.Loading /> : null}
        onChange={this.onChange}
        ref={this.refStandardInput}
      />
    );
  }

}

ImageInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validations: PropTypes.object,
  nonprofitProfileId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  pathValue: PropTypes.string,
};

ImageInput.defaultProps = {
  validations: {},
  className: '',
};

export default ImageInput;
