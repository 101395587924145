import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import GrantsAx       from 'app/actions/company-admin/grants';
import PageAx         from 'app/actions/company-admin/page-grants-new';
import Link           from 'app/components/common/link';
import Icon           from 'app/components/common/icon';
import Meta           from 'app/components/common/meta';
import NonprofitInput from 'app/components/common/nonprofit-input';
import StandardInput  from 'app/components/common/standard-input';
import CadminLayout   from 'app/components/company-admin/layout/';
import PageLoading    from 'app/components/layout/page-loading';
import NonprofitCard  from 'app/components/nonprofits/nonprofit-card';
import {
  DonatableTypes,
}                     from 'app/constants';
import paths          from 'app/paths';
import prompts        from 'app/prompts';
import CadminSlx      from 'app/selectors/company-admin/';
import PageSlx        from 'app/selectors/company-admin/page-grants-new';
import GrantsSlx      from 'app/selectors/company-admin/grants';
import RoutingSlx     from 'app/selectors/routing';

class PageCadminGrantsCreate extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      amountInDollars: null,
    };

    this.refNote = React.createRef();

    this.onClickCreate = this.onClickCreate.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onChangeAmountInDollars = this.onChangeAmountInDollars.bind(this);
  }

  get params() {
    return {
      amountInDollars: parseInt(this.state.amountInDollars) || 0,
      donatableType: DonatableTypes.NONPROFIT,
      donatableId: this.props.nonprofit?.id,
      note: (this.refNote.current?.value || '').trim() || null,
    };
  }

  get canSubmit() {
    const { donatableId, amountInDollars } = this.params;
    return !!(
      (amountInDollars > 0)
      && donatableId
    );
  }

  get hasIntl() {
    return !!this.props.company?.features?.international;
  }

  onChangeNonprofit(nonprofit) {
    this.props.setNonprofit(nonprofit);
    console.log(nonprofit);
  }

  onChangeAmountInDollars(event) {
    const amountInDollars = Math.max(Math.floor(parseFloat(event.target.value) || 0), 0);
    this.setState({amountInDollars});
  }

  async onClickCreate() {
    const { nonprofit } = this.props;
    const amount = numeral(this.params.amountInDollars).format('$0,0.00');
    const didConfirm = await prompts.confirm(`You're about to donate ${amount} to ${nonprofit.name}.`);
    if (didConfirm) {
      this.props.create(this.props.company.id, this.params);
    }
  }

  render() {
    const { company, pending, success, validations, errorMessage, nonprofit, paymentMethodLabel } = this.props;
    const { amountInDollars } = this.state;
    const frmtAmount = this.params.amountInDollars ? numeral(this.params.amountInDollars).format('$0,0.00') : null;
    if (!company) return <PageLoading />;
    const disabled = !!(!this.canSubmit || pending || success);
    const btnLabel = pending
      ? 'Donating...'
      : success
        ? 'Donated!'
        : frmtAmount
          ? `Donate ${frmtAmount}`
          : 'Donate';

    return (
      <CadminLayout className="cadmin-cgrant" company={company} activeItem="grants">
        <Meta title="Send Grant | Millie" />
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Send Grant</h1>
            <div className="ca-box-header-controls">
            </div>
          </div>
          <div className="ca-box-body">
            <div className="cadmin-cgrant-body">
              <div className="cadmin-cgrant-form">
                <label className="cadmin-cgrant-form-label">Nonprofit recipient</label>
                <NonprofitInput onChange={this.onChangeNonprofit} selectedNonprofit={nonprofit} intl={this.hasIntl} />
                <label className="cadmin-cgrant-form-label">Grant amount</label>
                <StandardInput name="amountInDollars" label="Amount" type="number" min="0" step="1" icon={<Icon.CurrencyDollar />} onChange={this.onChangeAmountInDollars} value={amountInDollars || ''} />
                <label className="cadmin-cgrant-form-label">Note</label>
                <StandardInput type="textarea" name="note" label="Note to nonprofit" ref={this.refNote} />
                <label className="cadmin-cgrant-form-label">Payment method</label>
                <div>
                  <strong>{paymentMethodLabel}</strong>
                  {paymentMethodLabel && <>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                  </>}
                  <Link href={paths.cadminWallet(company.slug)}>{paymentMethodLabel ? 'Change' : 'Add Payment Method'}</Link>
                </div>
              </div>
              <div className="cadmin-cgrant-preview">
                <NonprofitCard id={nonprofit?.id} target="_blank" />
              </div>
            </div>
          </div>
          <div className="ca-box-footer">
            <button onClick={this.onClickCreate} className="btn special pink" disabled={disabled}>{btnLabel}</button>
            {errorMessage && <div className="cadmin-cgrant-error-msg">{errorMessage}</div>}
          </div>
        </div>
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  query: RoutingSlx.query(state),

  nonprofit: PageSlx.nonprofit(state),
  paymentMethodLabel: PageSlx.paymentMethodLabel(state),

  pending: GrantsSlx.createPending(state),
  success: GrantsSlx.createSuccess(state),
  validations: GrantsSlx.createValidations(state),
  errorMessage: GrantsSlx.createErrorMessage(state),
});

const dispatchToProps = (dispatch) => ({
  setNonprofit: (nonprofit) => dispatch(PageAx.setNonprofit(nonprofit)),

  create: (companyId, params) => dispatch(GrantsAx.create(companyId, params)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminGrantsCreate);
