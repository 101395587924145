import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import CampaignsAx        from 'app/actions/company-admin/campaigns';
import PageAx             from 'app/actions/company-admin/page-campaigns';
import CampaignCard       from 'app/components/common/campaign-card';
import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Pagination         from 'app/components/common/pagination';
import StandardSelect     from 'app/components/common/standard-select';
import ModalCreateSuccess from 'app/components/company-admin/campaigns/modal-create-success';
import ModalNewCampaign   from 'app/components/company-admin/campaigns/modal-new-campaign';
import CadminLayout       from 'app/components/company-admin/layout/';
import ModalSlackCampaign from 'app/components/company-admin/slack/modal-channel-campaign';
import RequireRole        from 'app/components/gating/require-role';
import PageLoading        from 'app/components/layout/page-loading';
import {
  CampaignStatuses,
}                         from 'app/constants';
import { ReviewPromptAx } from 'app/ducks/review-prompt';
import campaignsHelper    from 'app/helpers/campaigns';
import Metrics            from 'app/metrics';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-campaigns';
import FfSlx              from 'app/selectors/feature-flags';

const StatusLabels = {
  [CampaignStatuses.ACTIVE]: 'Live',
  [CampaignStatuses.ENDED]: 'Ended',
  [CampaignStatuses.UPCOMING]: 'Upcoming',
};
const activityOptions = [
  {label: 'Donations',    value: 'give'},
  {label: 'Volunteering', value: 'vol'},
];
const activityOptionsWithDrive = [
  ...activityOptions,
  {label: 'Goods Drive',  value: 'drive'},
];
const statusOptions = [
  {label: 'Live',         value: 'active'},
  {label: 'Upcoming',     value: 'upcoming'},
  {label: 'Ended',        value: 'ended'},
];

const getFormattedDate = (campaign) => {
  if (!campaign.endDate) return 'Ongoing';
  return moment(campaign.endDate).format('MM/DD/YYYY');
};

class PageCadminCampaigns extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showNewModal: false,
    };

    this.onSelectPage              = this.onSelectPage.bind(this);
    this.onChangeActivity          = this.onChangeActivity.bind(this);
    this.onChangeStatus            = this.onChangeStatus.bind(this);
    this.onCloseSlackModal         = this.onCloseSlackModal.bind(this);
    this.onCloseCreateSuccessModal = this.onCloseCreateSuccessModal.bind(this);
    this.onCloseNewModal           = this.onCloseNewModal.bind(this);
    this.onClickNewCampaign        = this.onClickNewCampaign.bind(this);
  }

  get showSlackAnnounce() {
    const {company} = this.props;
    return !!(company && company.isSlackSetup && company.mainSlackChannelId);
  }

  get newModalDefaults() {
    const {queryParams} = this.props;
    if (queryParams.activity === 'give')  return {hasGive: true,  hasDrive: false, hasVol: false};
    if (queryParams.activity === 'drive') return {hasGive: false, hasDrive: true,  hasVol: false};
    if (queryParams.activity === 'vol')   return {hasGive: false, hasDrive: false, hasVol: true};
    return {hasGive: false, hasDrive: false, hasVol: false};
  }

  get drivesEnabled() {
    return this.props.company?.features?.drive || false;
  }

  onClickNewCampaign() {
    this.setState({showNewModal: true});
  }

  onCloseNewModal() {
    this.setState({showNewModal: false});
  }

  onSelectPage(page) {
    this.props.setQueryParams({page});
  }

  onChangeActivity(activity) {
    this.props.setQueryParams({activity, page: 1});
  }

  onChangeStatus(status) {
    this.props.setQueryParams({status, page: 1});
  }

  onCloseSlackModal() {
    this.props.viewSlack(null);
  }

  onCloseCreateSuccessModal() {
    this.props.closeCreateSuccessModal();
    this.props.checkReviewPrompt();
  }

  async onClickDelete(campaign, event) {
    event.preventDefault();
    const msg = `Are you sure you want to delete this campaign?\n${campaign.name}\nThis can not be undone.`;
    const didConfirm = await prompts.confirm({msg, danger: 'Delete'});
    if (!didConfirm) return;
    const trEl = event.target && event.target.closest('tr');
    const emEl = event.target && event.target.closest('.ellipsis-menu');
    if (trEl) trEl.style.opacity = 0.2;
    if (emEl) emEl.remove();
    const { company, deleteCampaign } = this.props;
    deleteCampaign(company.id, campaign.id);
  }

  async onClickArchive(campaign, event) {
    event.preventDefault();
    const msg = `Are you sure you want to mark this campaign as Ended?\n${campaign.name}\nThis can not be undone.`;
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel: 'Mark as Ended'});
    if (!didConfirm) return;
    const { company, archiveCampaign } = this.props;
    archiveCampaign(company.id, campaign.id);
  }

  onClickSlack(campaign, event) {
    event.preventDefault();
    this.props.viewSlack(campaign.id);
  }

  onClickSlackAnnounce(campaign, event) {
    event && event.preventDefault();
    this.props.announceInSlack(campaign.id);
  }

  renderCollabAnnounceMenuItem(company, campaign) {
    if (!this.showSlackAnnounce && !company.isMsTeamsSetup) return null;
    const icon = this.showSlackAnnounce ? <Icon.LogoSlack /> : <Icon.LogoMsTeams />;
    const label = this.showSlackAnnounce
      ? company.isMsTeamsSetup
        ? 'Announce in Slack & Teams'
        : 'Announce in Slack'
      : 'Announce in Teams';
    return (
      <Link onClick={this.onClickSlackAnnounce.bind(this, campaign)}>{icon}{label}</Link>
    );
  }

  renderFilters() {
    const {queryParams, company} = this.props;
    const useActivityOptions = this.drivesEnabled ? activityOptionsWithDrive : activityOptions;
    return (
      <div className="ca-main-filters">
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Activity</label>
          <StandardSelect options={useActivityOptions} onSelect={this.onChangeActivity} value={queryParams.activity} label="All" allowClear />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Status</label>
          <StandardSelect options={statusOptions} onSelect={this.onChangeStatus} value={queryParams.status} label="All" allowClear />
        </div>
      </div>
    );
  }

  renderCampaigns() {
    const { company, campaigns } = this.props;
    // if (!campaigns) return null;

    return (
      <table className="ca-box-table campaigns">
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
            <th>Activity</th>
            <th>Nonprofits</th>
            <th className="right">$ Raised</th>
            {this.drivesEnabled && <th className="right"># Goods</th>}
            <th>Status</th>
            <th className="right">End Date</th>
          </tr>
        </thead>
        <tbody>
          {(campaigns || []).map((c) => {
            const status = campaignsHelper.getStatus(c);
            const companyAmount = c.grantedAmountInCents + c.matchedAmountInCents;
            const showArchive = c.isOngoing && !c.endDate;
            const giveNum  = c.hasGive  ? numeral(c.totalRaisedAmount / 100).format('$0,0') : '';
            const driveNum = c.hasDrive ? numeral(c.driveTotalQuantity || 0).format('0,0')  : '';
            return (
              <tr key={c.id}>
                <td>
                  <Link className="pink-hover" href={paths.campaign(c.id)}>{c.name}</Link>
                </td>
                <td>
                  <EllipsisMenu>
                    <Link href={paths.campaign(c.id)}><Icon.BrowserPageText />View</Link>
                    <Link href={paths.cadminEditCampaign(company.slug, c.id)}><Icon.Pencil />Edit</Link>
                    {c.hasGive && (
                      <Link href={paths.cadminMatches(company.slug, {campaignId: c.id})}><Icon.AccountingBills />View Donations</Link>
                    )}
                    {c.hasDrive && (
                      <Link href={paths.cadminDriveDonations(company.slug, {campaignId: c.id})}><Icon.ProductsGiftGive />View Drive Donations</Link>
                    )}
                    {this.renderCollabAnnounceMenuItem(company, c)}
                    <RequireRole>
                      <Link href={paths.cadminAnalytics(company.slug, {campaignId: c.id, groupBy: 'employeeId'}, Metrics.presets.campaigns.key)}><Icon.AnalyticsBars />Analytics</Link>
                    </RequireRole>
                    {showArchive && (
                      <Link onClick={this.onClickArchive.bind(this, c)}><Icon.CalendarCheck1 />Mark as Ended</Link>
                    )}
                    <Link onClick={this.onClickDelete.bind(this, c)}><Icon.Bin />Delete</Link>
                  </EllipsisMenu>
                </td>
                <td className="ca-campaigns-col-activities">
                  {c.hasGive && <Icon.AccountingBills className="purple" />}
                  {c.hasDrive && <Icon.ProductsGiftGive className="green" />}
                  {c.hasVol && <Icon.HandExpand className="orange" />}
                </td>
                <td>
                  {c.aNonprofit && (
                    <Link className="pink-hover" href={paths.nonprofit(c.aNonprofit)}>{c.aNonprofit.name}</Link>
                  )}
                  {(c.nonprofitCount >= 2) && (` + ${c.nonprofitCount - 1}`)}
                </td>
                <td className="right">{giveNum}</td>
                {this.drivesEnabled && <td className="right">{driveNum}</td>}
                <td className={`col-status ${status}`}>{StatusLabels[status]}</td>
                <td className="right">{getFormattedDate(c)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    );
  }

  renderCampaignCards(campaigns, title, cardProps = {}) {
    if (!campaigns || !campaigns.length) return null;
    return (
      <div className="ca-campaigns-section-live-campaigns">
        {title}
        <div className="ca-campaigns-section-live-campaigns-cards">
          {campaigns.map(lc => (
            <div key={lc.id} className="campaigns-cards-card">
              <CampaignCard campaign={lc} {...cardProps} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const {showNewModal} = this.state;
    const {company, campaigns, liveCampaigns, slackCampaign, createdCampaign, pagination, queryParams} = this.props;
    if (!company) return <PageLoading />;
    const liveTitle = <h4 className="ca-campaigns-section-title">Live Campaigns</h4>;

    return (
      <CadminLayout className="ca-campaigns" company={company} activeItem="campaigns">
        <Meta title="Campaigns | Millie" />
        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">Campaigns</h1>
          <div className="ca-main-head-actions">
            <button onClick={this.onClickNewCampaign} className="btn special purple">Create New</button>
          </div>
        </div>
        {this.renderFilters()}

        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Campaigns</h1>
            <div className="ca-box-header-controls"></div>
          </div>
          <div className="ca-box-body">
            {this.renderCampaigns()}
            <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
          </div>
        </div>

        {this.renderCampaignCards(liveCampaigns, liveTitle)}
        {slackCampaign && <ModalSlackCampaign campaign={slackCampaign} onClose={this.onCloseSlackModal} />}
        {createdCampaign && <ModalCreateSuccess campaign={createdCampaign} onClose={this.onCloseCreateSuccessModal} onClickSlackAnnounce={this.showSlackAnnounce ? this.onClickSlackAnnounce.bind(this) : null} />}
        {showNewModal && <ModalNewCampaign company={company} onClose={this.onCloseNewModal} defaults={this.newModalDefaults} allowedGive={company.features.match} allowedVol={company.features.vol} allowedDrive={this.drivesEnabled} />}
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  campaigns: PageSlx.campaigns(state),
  liveCampaigns: PageSlx.liveCampaigns(state),
  slackCampaign: PageSlx.slackCampaign(state),
  createdCampaign: PageSlx.createdCampaign(state),

  searchPending: PageSlx.searchPending(state),
  queryParams: PageSlx.queryParams(state),
  pagination: PageSlx.pagination(state),
});

const dispatchToProps = (dispatch) => ({
  deleteCampaign: (companyId, campaignId) => dispatch(CampaignsAx.delete(companyId, campaignId)),
  archiveCampaign: (companyId, campaignId) => dispatch(CampaignsAx.archive(companyId, campaignId)),
  announceInSlack: (campaignId) => dispatch(CampaignsAx.announceInSlack(undefined, campaignId)),
  closeCreateSuccessModal: () => dispatch(CampaignsAx.setCreatedId(null)),
  viewSlack: (campaignId) => dispatch(PageAx.viewSlack(campaignId)),
  setQueryParams: (params) => dispatch(PageAx.setQueryParams(params)),
  checkReviewPrompt: () => dispatch(ReviewPromptAx.checkShow()),
});

export default connect(stateToProps, dispatchToProps)(PageCadminCampaigns);
