import { createSelector } from 'reselect';
import _ from 'lodash';

import {
  VolEventTypes,
}                       from 'app/constants';
import builderHelpers   from 'app/helpers/builders';
import volEventsHelpers from 'app/helpers/vol-events';
import EntitiesSlx      from 'app/selectors/entities';

const selVolEventId     = state => state.companyAdmin.builderVolEvent.volEventId;
const selType           = state => state.companyAdmin.builderVolEvent.type;
const selHasTouched     = state => state.companyAdmin.builderVolEvent.hasTouched;
const selSavePending    = state => state.companyAdmin.builderVolEvent.savePending;
const selImgPath        = state => state.companyAdmin.builderVolEvent.imgPath;
const selTitle          = state => state.companyAdmin.builderVolEvent.title;
const selDescription    = state => state.companyAdmin.builderVolEvent.description;
const selLinks          = state => state.companyAdmin.builderVolEvent.links;
const selExternalUrl    = state => state.companyAdmin.builderVolEvent.externalUrl;
const selContact        = state => state.companyAdmin.builderVolEvent.contact;
const selDetails        = state => state.companyAdmin.builderVolEvent.details;
const selAddressLine1   = state => state.companyAdmin.builderVolEvent.addressLine1;
const selCity           = state => state.companyAdmin.builderVolEvent.city;
const selState          = state => state.companyAdmin.builderVolEvent.state;
const selPostalCode     = state => state.companyAdmin.builderVolEvent.postalCode;
const selNteeCode       = state => state.companyAdmin.builderVolEvent.nteeCode;
const selIsRemote       = state => state.companyAdmin.builderVolEvent.isRemote;
const selIsOngoing      = state => state.companyAdmin.builderVolEvent.isOngoing;
const selTimezone       = state => state.companyAdmin.builderVolEvent.timezone;
const selLeadEmployeeId = state => state.companyAdmin.builderVolEvent.leadEmployeeId;
const selNonprofitId    = state => state.companyAdmin.builderVolEvent.nonprofitId;
const selShifts         = state => state.companyAdmin.builderVolEvent.shifts;
const selHasSubmitted   = state => state.companyAdmin.builderVolEvent.hasSubmitted;
const selGroupIds       = state => state.companyAdmin.builderVolEvent.groupIds;
const selTags           = state => state.companyAdmin.builderVolEvent.tags;

const selNonprofit = createSelector(
  [selNonprofitId, EntitiesSlx.nonprofits],
  (id, nonprofits) => nonprofits[id]
);

const selLeadEmployee = createSelector(
  [selLeadEmployeeId, EntitiesSlx.employees],
  (id, employees) => employees[id]
);

const selSaveAttrs = (state) => {
  const attrs = _.pick(state.companyAdmin.builderVolEvent, volEventsHelpers.editAttrs);
  const groups = EntitiesSlx.groups(state);
  // trim() the strings
  volEventsHelpers.textAttrs.forEach((attr) => {
    attrs[attr] = (attrs[attr] || '').trim();
  });
  attrs.groupIds = _.uniq(attrs.groupIds.filter(id => id && groups[id]));
  attrs.isHidden = false;
  return attrs;
};

const selValidations = createSelector(
  [selSaveAttrs, selType],
  (saveAttrs, selType) => {
    const isGroupEvent = selType === VolEventTypes.EVENT;
    const v = {};
    if (!saveAttrs.title) v.title = ['required'];
    if (!saveAttrs.imgPath) v.imgPath = ['required'];
    if (!saveAttrs.description) v.description = ['required'];
    if (!saveAttrs.timezone) v.timezone = ['required'];
    if (!isGroupEvent) {
      if (!saveAttrs.nteeCode) v.nteeCode = ['required'];
    }
    if (!saveAttrs.city && !saveAttrs.isRemote) v.address = ['required'];
    return Object.keys(v).length ? v : null;
  }
);

const selVisibleValidations = createSelector(
  [selValidations, selHasSubmitted],
  (validations, hasSubmitted) => hasSubmitted ? validations : null
);

const selIsValid = createSelector(
  [selValidations, selSaveAttrs, selShifts, selLinks],
  (validations, saveAttrs, shifts, links) => {
    if (validations) return false;
    const undeletedShifts = shifts.filter(s => !s._delete);
    const validShifts = undeletedShifts.filter(s => !volEventsHelpers.getShiftValidations(s));
    if (!saveAttrs.isOngoing) {
      if (!validShifts.length) return false;
      if (validShifts.length < undeletedShifts.length) return false;
    }
    const hasInvalidLink = links.some(builderHelpers.getLinkValidations);
    if (hasInvalidLink) return false;
    return true;
  }
);

const selShowShiftRequiredValidation = createSelector(
  [selHasSubmitted, selShifts, selSaveAttrs],
  (hasSubmitted, shifts, saveAttrs) => {
    if (!hasSubmitted) return false;
    if (saveAttrs.isOngoing) return false;
    const undeletedShifts = shifts.filter(s => !s._delete);
    return !undeletedShifts.length;
  }
);

const selGroups = createSelector(
  [selGroupIds, EntitiesSlx.groups],
  (ids, groups) => {
    return ids.map(id => groups[id]);
  }
);

export default {
  type: selType,
  volEventId: selVolEventId,
  hasTouched: selHasTouched,
  isValid: selIsValid,
  savePending: selSavePending,
  saveAttrs: selSaveAttrs,
  validations: selValidations,
  visibleValidations: selVisibleValidations,
  hasSubmitted: selHasSubmitted,
  showShiftRequiredValidation: selShowShiftRequiredValidation,
  // attrs
  imgPath: selImgPath,
  title: selTitle,
  description: selDescription,
  links: selLinks,
  externalUrl: selExternalUrl,
  contact: selContact,
  details: selDetails,
  addressLine1: selAddressLine1,
  city: selCity,
  state: selState,
  postalCode: selPostalCode,
  nteeCode: selNteeCode,
  isRemote: selIsRemote,
  isOngoing: selIsOngoing,
  timezone: selTimezone,
  leadEmployee: selLeadEmployee,
  nonprofit: selNonprofit,
  shifts: selShifts,
  groups: selGroups,
  tags: selTags,
};
