import _ from 'lodash';
import { createSelector } from 'reselect';

import cadminApi    from 'app/apis/company-admin';
import reducerUtils from 'app/reducers/utils';
import CadminSlx    from 'app/selectors/company-admin/';


/*
 *  Actions
 */

const Types = {
  CHANNELS_GET: 'CADMIN_MS_TEAMS_CHANNELS_GET',
  CHANNELS_SEARCH: 'CADMIN_MS_TEAMS_CHANNELS_SEARCH',
  CHANNELS_CREATE: 'CADMIN_MS_TEAMS_CHANNELS_CREATE',
};

const Ax = {

  channelsGet: (channelId) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = cadminApi.msTeamsChannelsGet(companyId, channelId);
    return dispatch({type: Types.CHANNELS_GET, promise, _entities: ['teamsChannel']});
  },

  channelsSearch: (name) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = cadminApi.msTeamsChannelsSearch(companyId, name);
    return dispatch({type: Types.CHANNELS_SEARCH, promise});
  },

  channelsCreate: (name) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = cadminApi.msTeamsChannelsCreate(companyId, name);
    return dispatch({type: Types.CHANNELS_CREATE, promise, _entities: ['teamsChannel']});
  },

};



/*
 *  Reducer
 */

const initialState = {
};

const reducer = reducerUtils.createReducer(initialState, {
});



/*
 *  Selectors
 */

const Slx = (() => {

  // const selFoo = (state) => state.companyAdmin.msTeams.foo;

  return {
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
