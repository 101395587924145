import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import CompaniesAx        from 'app/actions/company-admin/companies';
import Checkbox           from 'app/components/common/checkbox';
import Icon               from 'app/components/common/icon';
import PaymentMethodInput from 'app/components/company-admin/wallet/payment-method-input';
import {
  PaymentMethodTypes,
}                         from 'app/constants';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-wallet';

const titleMap = {
  match: 'Matches',
  grant: 'Grants',
  gift: 'Gifts',
  payroll: 'Payroll Giving',
  dfd: 'Dollars for Doers',
};

const iconMap = {
  match: Icon.PaginateFilterHeart,
  grant: Icon.LoveHeartHold,
  gift: Icon.GiftBox,
  payroll: Icon.AccountingBill,
  dfd: Icon.CatEnvironment,
};

const idFieldMap = {
  match: 'paymentMethodMatchId',
  grant: 'paymentMethodGrantId',
  gift: 'paymentMethodGiftId',
  payroll: 'paymentMethodPayrollId',
  dfd: 'paymentMethodDfdId',
};

const balanceFieldMap = {
  match: 'useBalanceFirstMatch',
  grant: 'useBalanceFirstGrant',
  gift: 'useBalanceFirstGift',
  payroll: 'useBalanceFirstPayroll',
  dfd: 'useBalanceFirstDfd',
};

const subtextMap = {
  match: 'Matches made will be charged on the first day of the following month as one charge.',
};

const gmDisclaimerMap = {
  match: 'The Match payment method and budgets are used across annual match as well as all Giving Madness matches combined.',
  grant: 'The Grants payment method is used across general company grants as well as company donations made to kickoff Giving Madness bracket donation pools.',
};

class ProgramSettings extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectedPm: null,
      selectedUseBalance: null,
      isSaving: false,
      saveSuccess: false,
    };

    this.onSelectPaymentMethod = this.onSelectPaymentMethod.bind(this);
    this.onChangeUseBalance = this.onChangeUseBalance.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.renderPaymentMethodOption = this.renderPaymentMethodOption.bind(this);
  }

  get title() {
    return titleMap[this.props.type];
  }

  get subtext() {
    return subtextMap[this.props.type];
  }

  get gmDisclaimer() {
    return gmDisclaimerMap[this.props.type];
  }

  get idField() {
    return idFieldMap[this.props.type];
  }

  get balanceField() {
    return balanceFieldMap[this.props.type];
  }

  get savedPm() {
    const { creditCards, verifiedBankAccounts, company } = this.props;
    return [...creditCards, ...verifiedBankAccounts].find(pm => company[this.idField] === pm.id);
  }

  get displayPm() {
    return this.state.selectedPm || this.savedPm;
  }

  get displayUseBalance() {
    return _.isBoolean(this.state.selectedUseBalance)
      ? this.state.selectedUseBalance
      : this.props.company[this.balanceField];
  }

  get needsSaving() {
    const { savedPm } = this;
    const { selectedPm, selectedUseBalance } = this.state;
    if (!this.displayPm) return false;
    if (selectedPm && (!savedPm || (selectedPm.id !== savedPm.id))) return true;
    if (_.isBoolean(selectedUseBalance) && selectedUseBalance !== this.props.company[this.balanceField]) return true;
    return false;
  }

  onClickSave() {
    const attrs = {
      [this.balanceField]: this.displayUseBalance,
      [this.idField]: this.displayPm.id,
    };
    this.setState({isSaving: true});
    this.props.updateCompany(attrs).then(() => {
      this.setState({isSaving: false, saveSuccess: true});
    });
  }

  onSelectPaymentMethod(pm) {
    this.setState({selectedPm: pm, saveSuccess: false});
  }

  onChangeUseBalance(event) {
    const checked = event.target.checked;
    this.setState({selectedUseBalance: checked, saveSuccess: false});
  }

  renderPaymentMethodOption(pm, isSelected) {
    if (!pm) return (
      <span className="faint">Payment Method...</span>
    );
    const isCc = pm.type === PaymentMethodTypes.CREDIT_CARD;
    const PmIcon = isCc ? Icon.CreditCard : Icon.SavingBank;
    return (
      <div className={`payment-select-option ${isSelected ? 'selected' : ''}`}>
        <PmIcon brand={pm.brand} />
        <span>{pm.name}</span>
      </div>
    );
  }

  render() {
    const { creditCards, verifiedBankAccounts, type, hideBalanceFirst, children } = this.props;
    const { isSaving, saveSuccess } = this.state;
    const TypeIcon = iconMap[type];
    const btnText = isSaving
      ? 'Saving...'
      : saveSuccess ? 'Saved!' : 'Save';

    return (
      <div className={`ca-box vertical-tight ca-program-settings ${type}`}>
        <div className="ca-box-header">
          <h1 className="ca-box-header-title"><TypeIcon /> {this.title}</h1>
        </div>
        <div className="ca-box-body">
          <div className="ca-program-settings-fields">
            <PaymentMethodInput
              paymentMethods={[...creditCards, ...verifiedBankAccounts]}
              onSelect={this.onSelectPaymentMethod}
              selectedPaymentMethod={this.displayPm}
            />
            {!hideBalanceFirst && (<>
              <Checkbox
                id={`${this.balanceField}-checkbox`}
                onChange={this.onChangeUseBalance}
                checked={this.displayUseBalance}
              />
              <label htmlFor={`${this.balanceField}-checkbox`}>
                Charge the wallet balance first if there are funds available.
              </label>
            </>)}
          </div>
          {!!this.subtext && (
            <p className="ca-program-settings-subtext">{this.subtext}</p>
          )}
          {!!this.gmDisclaimer && (
            <p className="ca-program-settings-subtext">{this.gmDisclaimer}</p>
          )}
        </div>
        {(this.needsSaving || saveSuccess) && (
          <div className="ca-box-footer">
            <button className="btn blue" onClick={this.onClickSave} disabled={isSaving || saveSuccess}>{btnText}</button>
          </div>
        )}
        {children && (
          <div className="ca-box-footer">
            {children}
          </div>
        )}
      </div>
    );
  }

}

ProgramSettings.propTypes = {
  type: PropTypes.oneOf(['match', 'grant', 'gift', 'payroll', 'dfd']).isRequired,
  hideBalanceFirst: PropTypes.bool,
  children: PropTypes.node,
};

ProgramSettings.defaultProps = {
  hideBalanceFirst: false,
  children: null,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  creditCards: PageSlx.creditCards(state),
  verifiedBankAccounts: PageSlx.verifiedBankAccounts(state),
});

const dispatchToProps = (dispatch) => ({
  updateCompany: (attrs) => dispatch(CompaniesAx.update(undefined, attrs)),
});

export default connect(stateToProps, dispatchToProps)(ProgramSettings);
