import { createSelector } from 'reselect';

import {
  PaymentMethodTypes,
}                  from 'app/constants';
import CadminSlx   from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';
import RoutingSlx  from 'app/selectors/routing';

const selNonprofit = createSelector(
  [RoutingSlx.query, EntitiesSlx.nonprofits],
  (query, nonprofits) => nonprofits[query.nonprofitId]
);

const selPaymentMethodLabel = createSelector(
  [EntitiesSlx.creditCards, EntitiesSlx.bankAccounts, CadminSlx.company],
  (creditCards, bankAccounts, company) => {
    const { paymentMethodGrantType: type, paymentMethodGrantId: id } = (company || {});
    if (!id) return null;
    const isCc = type === PaymentMethodTypes.CREDIT_CARD;
    const pm = isCc ? creditCards[id] : bankAccounts[id];
    return pm && pm.name;
  }
);

export default {
  nonprofit: selNonprofit,
  paymentMethodLabel: selPaymentMethodLabel,
};
