import moment from 'moment';
import numeral from 'numeral';

import {
  CampaignStatuses,
  DriveGoalTypes as GoalTypes,
} from 'app/constants';

const editAttrs = [
  'name',
  'description',
  'startDate',
  'endDate',
  'startsAt',
  'endsAt',
  'goalAmountInCents',
  'imgPath',
  'isOnDashboard',
  'nonprofitIds',
  'volEventIds',
  'groupIds',
  'isOngoing',
  'isFund',
  'matchPercent',
  'budgetAllocatedAmount',
  'links',
  'addNonprofitsToMatch',
  'timezone',
  'hasGive',
  'hasDrive',
  'hasVol',
  'driveGoalType',
  'driveGoalValue',
  'driveAllowEmpTracking',
];

const textAttrs = [
  'name',
  'description',
];

const getStatus = (campaign) => {
  const { startsAt, startDate, endsAt, endDate } = campaign;
  if (endsAt && moment().isAfter(endsAt)) return CampaignStatuses.ENDED;
  if (startsAt && moment().isBefore(startsAt)) return CampaignStatuses.UPCOMING;
  if (endsAt && startsAt) return CampaignStatuses.ACTIVE;
  if (endDate && moment().startOf('day').isAfter(moment(endDate))) return CampaignStatuses.ENDED;
  if (startDate && moment().isBefore(moment(startDate))) return CampaignStatuses.UPCOMING;
  return CampaignStatuses.ACTIVE;
};

const getDateLabel = (campaign) => {
  const format = 'MMMM Do';
  const status = getStatus(campaign);
  const startStr = moment(campaign.startDate).format(format);
  const endStr = moment(campaign.endDate).format(format);
  if (status === CampaignStatuses.UPCOMING) return `Starts ${startStr}`;
  if (status === CampaignStatuses.ENDED) return `${endStr}`;
  return campaign.endDate ? `Ends ${endStr}` : 'Ongoing';
};

const getGoalLabel = (campaign) => {
  const {donatedAmountInCents, matchedAmountInCents, goalAmountInCents} = campaign;
  const raisedAmountInCents = (donatedAmountInCents + matchedAmountInCents) || 0;
  const frmtGoal = numeral((goalAmountInCents || 0) / 100).format('$0,0');
  const frmtRaised = numeral((raisedAmountInCents|| 0) / 100).format('$0,0');
  const status = getStatus(campaign);
  if (status === CampaignStatuses.UPCOMING) return goalAmountInCents ? `${frmtGoal} goal` : null;
  if (status === CampaignStatuses.ENDED || !goalAmountInCents) return `${frmtRaised} raised`;
  return `${frmtRaised} / ${frmtGoal}`;
};

// {name: "Coats", unitValue: 2150, goalType: "monetary", goalValue: 350000}
const getGoodValidations = (good) => {
  const validations = {};
  if (!good.name) validations['name'] = ['required'];
  if (good.goalType) {
    if (!Object.values(GoalTypes).includes(good.goalType)) validations['goalType'] = ['invalid'];
    if (good.goalType === GoalTypes.MONETARY) {
      if (!good.unitValue) validations['unitValue'] = ['required for monetary goal'];
    }
    if (!good.goalValue) validations['goalValue'] = ['required'];
  }
  return Object.keys(validations).length
    ? validations
    : null;
};

export default {
  getStatus,
  getDateLabel,
  getGoalLabel,
  editAttrs,
  textAttrs,
  getGoodValidations,
};
