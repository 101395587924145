import _ from 'lodash';
import { createSelector } from 'reselect';
import timm from 'timm';

import ToastAx       from 'app/actions/toast';
import CadminApi     from 'app/apis/company-admin';
import reducerUtils  from 'app/reducers/utils';
import CadminSlx     from 'app/selectors/company-admin/';
import EntitiesSlx   from 'app/selectors/entities';



/*
 *  Actions
 */

const Types = {
  SEARCH:         'CADMIN_VOL_EVENTS_SEARCH',
  GET:            'CADMIN_VOL_EVENTS_GET',
  DELETE:         'CADMIN_VOL_EVENTS_DELETE',
  ARCHIVE:        'CADMIN_VOL_EVENTS_ARCHIVE',
  CREATE:         'CADMIN_VOL_EVENTS_CREATE',
  // CREATE_HIDDEN:  'CADMIN_VOL_EVENTS_CREATE_HIDDEN',
  UPDATE:         'CADMIN_VOL_EVENTS_UPDATE',
  SET_CREATED_ID: 'CADMIN_VOL_EVENTS_SET_CREATED_ID',
  ANNOUNCE_SLACK: 'CADMIN_VOL_EVENTS_ANNOUNCE_SLACK',
  REMIND_SLACK:   'CADMIN_VOL_EVENTS_REMIND_SLACK',
};

const Ax = {

  search: (companyId, params={}) => {
    const promise = CadminApi.volEventsSearch(companyId, params);
    return { type: Types.SEARCH, promise, _entities: ['volEvents', 'nonprofits'] };
  },

  get: (companyId, volEventId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.volEventsGet(companyId, volEventId);
    return dispatch({type: Types.GET, promise, _entities: ['volEvent', 'volEventShifts', 'groups', 'employee', 'nonprofit']});
  },

  delete: (companyId, volEventId) => {
    const promise = CadminApi.volEventsDelete(companyId, volEventId);
    return { type: Types.DELETE, promise, volEventId };
  },

  archive: (companyId, volEventId) => {
    const promise = CadminApi.volEventsArchive(companyId, volEventId);
    return { type: Types.ARCHIVE, promise, volEventId };
  },

  create: (companyId, attrs, {showToast=false}={}) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.volEventsCreate(companyId, attrs);
    promise.then(() => {
      if (showToast) {
        dispatch(ToastAx.success('Event created.'));
      }
    });
    return dispatch({type: Types.CREATE, promise, _entities: ['volEvent', 'volEventShifts']});
  },

  update: (companyId, volEventId, attrs, {showToast=false}={}) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.volEventsUpdate(companyId, volEventId, attrs);
    promise.then(() => {
      if (showToast) {
        dispatch(ToastAx.success('Event updated.'));
      }
    });
    return dispatch({type: Types.UPDATE, promise, _entities: ['volEvent']});
  },

  setCreatedId: (volEventId) => {
    return { type: Types.SET_CREATED_ID, volEventId };
  },

  announceInSlack: (companyId, volEventId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.volEventsAnnounceInSlack(companyId, volEventId);
    promise.then(() => {
      dispatch(ToastAx.success('Announced.'));
    });
    return dispatch({ type: Types.ANNOUNCE_SLACK, promise, volEventId });
  },

  remindInSlack: (companyId, volEventId) => (dispatch, getState) => {
    if (!companyId) companyId = CadminSlx.companyId(getState());
    const promise = CadminApi.volEventsRemindInSlack(companyId, volEventId);
    promise.then(() => {
      dispatch(ToastAx.success('Reminder sent.'));
    });
    return dispatch({ type: Types.REMIND_SLACK, promise, volEventId });
  },

};

/*
 *  Reducer
 */

const initialState = {
  createPending: false,
  updatePending: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      createPending: false,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, action) => {
    return {...state,
      createPending: false,
    };
  },

  [`${Types.UPDATE}_PENDING`]: (state, action) => {
    return {...state,
      updatePending: true,
    };
  },
  [`${Types.UPDATE}_RESOLVED`]: (state, action) => {
    return {...state,
      updatePending: false,
    };
  },
  [`${Types.UPDATE}_REJECTED`]: (state, action) => {
    return {...state,
      updatePending: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selCreatePending = state => state.companyAdmin.volEvents.createPending;
  const selUpdatePending = state => state.companyAdmin.volEvents.updatePending;

  return {
    createPending: selCreatePending,
    updatePending: selUpdatePending,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
