import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import BuilderAx           from 'app/actions/company-admin/builder-campaign';
import Checkbox            from 'app/components/common/checkbox';
import Confirm             from 'app/components/common/confirm';
import DatePicker          from 'app/components/common/date-picker';
import GroupInput          from 'app/components/common/group-input';
import Icon                from 'app/components/common/icon';
import IntegerInput        from 'app/components/common/integer-input';
import Link                from 'app/components/common/link';
import MatchRatioSelector  from 'app/components/common/match-ratio-selector';
import MonetaryInput       from 'app/components/common/monetary-input';
import NonprofitInput      from 'app/components/common/nonprofit-input';
import SegmentedInput      from 'app/components/common/segmented-selector-input';
import StandardInput       from 'app/components/common/standard-input';
import StandardSelect      from 'app/components/common/standard-select';
import TimezoneInput       from 'app/components/common/timezone-input';
import Tip                 from 'app/components/common/tip';
import UploadedImageInput  from 'app/components/common/uploaded-image-input';
import VolEventInput       from 'app/components/common/vol-event-input';
import BuilderDriveGood    from 'app/components/company-admin/campaigns/builder-drive-good';
import BuilderLink         from 'app/components/company-admin/common/builder-link';
import Builder             from 'app/components/company-admin/layout/builder';
import BuilderFooter       from 'app/components/company-admin/layout/builder-footer';
import BuilderIterable     from 'app/components/company-admin/layout/builder-iterable';
import BuilderSection      from 'app/components/company-admin/layout/builder-section';
import BuilderSectionGroup from 'app/components/company-admin/layout/builder-section-group';
import config              from 'app/config';
import {
  EmployeeRoles,
  DriveGoalTypes,
}                          from 'app/constants';
import format              from 'app/helpers/format';
import AuthSlx             from 'app/selectors/auth';
import CadminSlx           from 'app/selectors/company-admin/';
import BuilderSlx          from 'app/selectors/company-admin/builder-campaign';
import FfSlx               from 'app/selectors/feature-flags';

const isFundOptions = [
  {label: 'Employee Choice', value: 'false'},
  {label: 'Pooled Fund', value: 'true'},
];
const driveGoalTypeOptions = [
  {value: DriveGoalTypes.QUANTITY, label: 'Quantity'},
  {value: DriveGoalTypes.MONETARY, label: 'Monetary'},
];

class CampaignBuilder extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refGoalAmount = React.createRef();
    this.refBudgetAmount = React.createRef();

    this.state = {
      separateMatchRatio: null,
      separateMatchBudget: null,
      showBudgetSettings: false,
      driveGoodDeleteIndex: null,
    };

    this.onClickSave = this.onClickSave.bind(this);
    this.onChangeImg = this.onChangeImg.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeIsOngoing = this.onChangeIsOngoing.bind(this);
    this.onChangeAllowEmpTracking = this.onChangeAllowEmpTracking.bind(this);
    this.onChangeTimeonze = this.onChangeTimeonze.bind(this);
    this.onChangeIsFund = this.onChangeIsFund.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeIsOnDashboard = this.onChangeIsOnDashboard.bind(this);
    this.onChangeIncludeGoal = this.onChangeIncludeGoal.bind(this);
    this.onChangeGiveGoalAmount = this.onChangeGiveGoalAmount.bind(this);
    this.onChangeMatchPercent = this.onChangeMatchPercent.bind(this);
    this.onChangeBudget = this.onChangeBudget.bind(this);
    this.onSelectSepMatchRatio = this.onSelectSepMatchRatio.bind(this);
    this.onSelectSepMatchBudget = this.onSelectSepMatchBudget.bind(this);
    this.onClickAddNonprofit = this.onClickAddNonprofit.bind(this);
    this.onClickShowBudgetSettings = this.onClickShowBudgetSettings.bind(this);
    this.onClickHideBudgetSettings = this.onClickHideBudgetSettings.bind(this);
    this.onRemoveNonprofit = this.onRemoveNonprofit.bind(this);
    this.onClickAddEvent = this.onClickAddEvent.bind(this);
    this.onRemoveEvent = this.onRemoveEvent.bind(this);
    this.onRemoveGroup = this.onRemoveGroup.bind(this);
    this.onClickAddGroup = this.onClickAddGroup.bind(this);
    this.onRemoveLink = this.onRemoveLink.bind(this);
    this.onChangeLink = this.onChangeLink.bind(this);
    this.onClickAddLink = this.onClickAddLink.bind(this);
    this.onChangeAddNonprofitsToMatch = this.onChangeAddNonprofitsToMatch.bind(this);
    this.onClickAddDriveGood = this.onClickAddDriveGood.bind(this);
    this.onRemoveDriveGood = this.onRemoveDriveGood.bind(this);
    this.onResponseDeleteDriveGood = this.onResponseDeleteDriveGood.bind(this);
    this.onToggleGive = this.onToggleGive.bind(this);
    this.onToggleDrive = this.onToggleDrive.bind(this);
    this.onToggleVol = this.onToggleVol.bind(this);
    this.onChangeDriveGoalOn = this.onChangeDriveGoalOn.bind(this);
    this.onChangeDriveGoalType = this.onChangeDriveGoalType.bind(this);
    this.onChangeDriveGoalValue = this.onChangeDriveGoalValue.bind(this);
  }

  get separateMatchRatio() {
    if (this.state.separateMatchRatio != null) return this.state.separateMatchRatio;
    return this.props.matchPercent != null;
  }

  get separateMatchBudget() {
    if (this.state.separateMatchBudget != null) return this.state.separateMatchBudget;
    return this.props.budgetAllocatedAmount != null;
  }

  get hasBudgetVals() {
    const { budgetAllocatedAmount, matchPercent } = this.props;
    return (budgetAllocatedAmount != null) || (matchPercent != null);
  }

  get canHideBudgetSettings() {
    if (this.hasBudgetVals) return false;
    if (this.state.separateMatchRatio) return false;
    if (this.state.separateMatchBudget) return false;
    return true;
  }

  get showBudgetSettings() {
    if (this.hasBudgetVals) return true;
    return this.state.showBudgetSettings;
  }

  get hasIntl() {
    return !!this.props.company?.features?.international;
  }

  onToggleGive(isOn) {
    this.props.setKeyVal('hasGive', isOn);
  }
  onToggleDrive(isOn) {
    this.props.setKeyVal('hasDrive', isOn);
  }
  onToggleVol(isOn) {
    this.props.setKeyVal('hasVol', isOn);
  }

  onChangeDriveGoalOn(event) {
    const goalOn = event.target.checked;
    const driveGoalType = goalOn ? DriveGoalTypes.QUANTITY : null;
    this.props.setKeyVal('driveGoalType', driveGoalType);
  }
  onChangeDriveGoalType(option) {
    this.props.setKeyVals({driveGoalType: option.value, driveGoalValue: null});
  }
  onChangeDriveGoalValue(driveGoalValue) {
    this.props.setKeyVal('driveGoalValue', driveGoalValue);
  }

  onChangeName(event) {
    const name = event.target.value;
    this.props.setKeyVal('name', name);
  }

  onChangeImg(imgPath) {
    this.props.setKeyVal('imgPath', imgPath);
  }

  onChangeDescription(event) {
    const description = event.target.value;
    this.props.setKeyVal('description', description);
  }

  onChangeGiveGoalAmount(goalAmountInCents) {
    this.props.setKeyVal('goalAmountInCents', goalAmountInCents);
  }

  onChangeMatchPercent(matchPercent) {
    this.setState({separateMatchRatio: true, showBudgetSettings: true});
    this.props.setKeyVal('matchPercent', matchPercent);
  }

  onChangeBudget(budgetAllocatedAmount) {
    this.setState({separateMatchBudget: true, showBudgetSettings: true});
    this.props.setKeyVal('budgetAllocatedAmount', budgetAllocatedAmount);
  }

  onSelectSepMatchRatio(separateMatchRatio) {
    const matchPercent = separateMatchRatio
      ? (_.isFinite(this.props.storedCampaign?.matchPercent) ? this.props.storedCampaign.matchPercent : 100)
      : null;
    this.props.setKeyVal('matchPercent', matchPercent);
    this.setState({separateMatchRatio});
  }

  onSelectSepMatchBudget(separateMatchBudget) {
    const budgetAllocatedAmount = separateMatchBudget
      ? (_.isFinite(this.props.storedCampaign?.budgetAllocatedAmount) ? this.props.storedCampaign.budgetAllocatedAmount : 10000)
      : null;
    this.props.setKeyVal('budgetAllocatedAmount', budgetAllocatedAmount);
    this.setState({separateMatchBudget});
    // focus and highlight text upon enabling separate budget
    if (separateMatchBudget) {
      setTimeout(() => {
        const el = _.get(this.refBudgetAmount, 'current.element');
        if (el) {
          el.focus();
          el.select();
        }
      }, 0);
    }
  }

  onChangeIncludeGoal(event) {
    const includeGoal = event.target.checked;
    const goalAmountInCents = includeGoal ? 250000 : null;
    this.props.setKeyVal('goalAmountInCents', goalAmountInCents);
    // focus and highlight text upon enabling the goal
    if (includeGoal) {
      setTimeout(() => {
        const el = _.get(this.refGoalAmount, 'current.element');
        if (el) {
          el.focus();
          el.select();
        }
      }, 0);
    }
  }

  onChangeAddNonprofitsToMatch(event) {
    const addNonprofitsToMatch = event.target.checked;
    this.props.setKeyVal('addNonprofitsToMatch', addNonprofitsToMatch);
  }

  onChangeIsOngoing(event) {
    const isOngoing = event.target.checked;
    this.props.setKeyVals({
      startDate: null,
      endDate: null,
      isOngoing,
    });
  }

  onChangeAllowEmpTracking(event) {
    const driveAllowEmpTracking = event.target.checked;
    this.props.setKeyVals({driveAllowEmpTracking});
  }

  onChangeTimeonze(timezone) {
    this.props.setKeyVals({timezone, startsAt: null, endsAt: null});
    this.props.getTimestamps();
  }

  onChangeIsFund(option) {
    const isFund = option.value === 'true';
    this.props.setKeyVal('isFund', isFund);
  }
  
  onClickAddNonprofit() {
    const index = this.props.nonprofits.length;
    this.props.setNonprofit(null, index);
  }
  onRemoveNonprofit(index) {
    this.props.removeNonprofit(index);
  }
  onChangeNonprofit(index, nonprofit) {
    this.props.setNonprofit(nonprofit, index);
  }

  onClickAddEvent() {
    const index = this.props.volEvents.length;
    this.props.setVolEvent(null, index);
  }
  onRemoveEvent(index) {
    this.props.removeVolEvent(index);
  }
  onChangeEvent(index, volEvent) {
    this.props.setVolEvent(volEvent, index);
  }

  onClickAddGroup() {
    const index = this.props.groups.length;
    this.props.setGroup(null, index);
  }
  onRemoveGroup(index) {
    this.props.removeGroup(index);
  }
  onChangeGroup(index, group) {
    this.props.setGroup(group, index);
  }

  onClickAddLink() {
    this.props.addLink();
  }
  onChangeLink(index, link) {
    this.props.updateLink(index, link);
  }
  onRemoveLink(index) {
    this.props.removeLink(index);
  }

  onChangeStartDate(startDateStr) {
    this.props.setKeyVals({startDate: startDateStr, startsAt: null});
    this.props.getTimestamps();
  }

  onChangeEndDate(endDateStr) {
    this.props.setKeyVals({endDate: endDateStr, endsAt: null});
    this.props.getTimestamps();
  }

  onChangeIsOnDashboard(event) {
    const isOnDashboard = event.target.checked;
    this.props.setKeyVal('isOnDashboard', isOnDashboard);
  }

  onClickSave() {
    this.props.save();
  }

  onClickShowBudgetSettings(event) {
    event.preventDefault();
    this.setState({showBudgetSettings: true});
  }

  onClickHideBudgetSettings(event) {
    event.preventDefault();
    this.setState({showBudgetSettings: false});
  }

  onClickAddDriveGood() {
    this.props.addDriveGood();
  }

  onRemoveDriveGood(index) {
    const good = this.props.driveGoods[index];
    if (good?.id) {
      this.setState({driveGoodDeleteIndex: index});
    } else {
      this.props.removeDriveGood(index);
    }
  }
  onResponseDeleteDriveGood(didConfirm) {
    const {driveGoodDeleteIndex} = this.state;
    this.setState({driveGoodDeleteIndex: null});
    if (!didConfirm) return;
    this.props.removeDriveGood(driveGoodDeleteIndex);
  }

  /*
   *  Render Sections
   */

  renderSectionTitle() {
    const { name, validations } = this.props;
    return (
      <BuilderSection title="Campaign Name" isRequired>
        <StandardInput
          name="name"
          label="Name"
          value={name || ''}
          validations={validations}
          onChange={this.onChangeName}
        />
      </BuilderSection>
    );
  }

  renderSectionImg() {
    const { imgPath, validations } = this.props;
    return (
      <BuilderSection title="Image" isRequired>
        <UploadedImageInput
          name="imgPath"
          label="Image"
          pathValue={imgPath}
          maxImageWidth={2048}
          validations={validations}
          s3PathParts={['campaigns']}
          onChange={this.onChangeImg}
        />
      </BuilderSection>
    );
  }

  renderSectionDescription() {
    const { description, links, hasSubmitted, validations } = this.props;
    return (
      <BuilderSection title="Short Description" isRequired>
        <StandardInput
          type="textarea"
          name="description"
          label="Description"
          value={description || ''}
          validations={validations}
          onChange={this.onChangeDescription}
        />
        {links.map((link, index) => {
          return (
            <BuilderIterable index={index} key={index} onDelete={this.onRemoveLink}>
              <BuilderLink link={link} index={index} onChange={this.onChangeLink} hasSubmitted={hasSubmitted} />
            </BuilderIterable>
          );
        })}
        <button className="btn small blue cadmin-builder-iterable-add" onClick={this.onClickAddLink}>+ Add Link</button>
      </BuilderSection>
    );
  }

  renderSectionDates() {
    const { startDate, endDate, timezone, isOngoing, validations } = this.props;
    const topRight = (
      <div className="cadmin-builder-checkbox">
        <Checkbox id="checkbox-is-ongoing" onChange={this.onChangeIsOngoing} checked={isOngoing} />
        <label htmlFor="checkbox-is-ongoing">Ongoing</label>
      </div>
    );
    return (
      <BuilderSection title="Campaign Dates" topRight={topRight} className="cadmin-camb-dates" isRequired>
        {!isOngoing && (<>
          <DatePicker name="startDate" placeholder="Start Date" ddAlign="left" dateStr={startDate} onChange={this.onChangeStartDate} validations={validations} />
          <DatePicker name="endDate" placeholder="End Date" ddAlign="left" dateStr={endDate} onChange={this.onChangeEndDate} validations={validations} />
        </>)}
        <TimezoneInput timezone={timezone} onChange={this.onChangeTimeonze} />
      </BuilderSection>
    );
  }

  renderSectionGoal() {
    const { goalAmountInCents, validations } = this.props;
    const goalAmountInDollars = Number.isFinite(goalAmountInCents) && (goalAmountInCents / 100);
    return (
      <BuilderSection title="Goal Tracking" className="cadmin-camb-match">
        <div className="cadmin-camb-match-row">
          <Checkbox id="checkbox-include-goal" className="cadmin-camb-match-cb" onChange={this.onChangeIncludeGoal} checked={goalAmountInDollars} />
          <label className="cadmin-camb-match-label" htmlFor="checkbox-include-goal">Include donation goal tracker (company and employee donations combined)</label>
          <MonetaryInput className="cadmin-camb-match-amount" ref={this.refGoalAmount} label="2,500.00" name="goalAmountInCents" value={goalAmountInCents} onChange={this.onChangeGiveGoalAmount} validations={validations} />
        </div>
      </BuilderSection>
    );
  }

  renderSectionBudget() {
    const { validations, matchPercent, budgetAllocatedAmount, currentUser, company } = this.props;
    const isAdmin = _.get(currentUser, 'employment.employee.role') === EmployeeRoles.ADMIN;

    const topRight = this.showBudgetSettings
      ? this.canHideBudgetSettings
        ? <Link className="btn-link" onClick={this.onClickHideBudgetSettings}>Hide advanced settings</Link>
        : null
      : <Link className="btn-link" onClick={this.onClickShowBudgetSettings}>Show advanced settings</Link>;

    const effectiveBudgetAmount = [budgetAllocatedAmount, company.currentMatchBudget?.allocatedAmount, 0].find(_.isFinite);
    const effectiveMatchPercent = [matchPercent, company.currentMatchPercent, 0].find(_.isFinite);
    const matchBudgetAmount = company.currentMatchBudget?.allocatedAmount || 0;
    const budgetHelp = budgetAllocatedAmount == null
      ? `This campaign will use the annual match budget of ${numeral(matchBudgetAmount / 100).format('$0,0')}.`
      : `This campaign will have a distinct budget of ${numeral(budgetAllocatedAmount / 100).format('$0,0')} that goes beyond the annual budget of ${numeral(matchBudgetAmount / 100).format('$0,0')}. There is no per employee cap; this falls outside of that.`;
    const ratioHelp = matchPercent == null
      ? `This campaign will use the annual match ratio of ${format.matchRatio(company.currentMatchPercent)}`
      : `This campaign will have a distinct match ratio of ${format.matchRatio(matchPercent)}.`;
    const hasMatch = (effectiveBudgetAmount > 0) && (effectiveMatchPercent > 0);
    const help = hasMatch
      ? `${budgetHelp} ${ratioHelp}`
      : 'Donations to this campaign will not be matched.';
    const helpKey = `${budgetAllocatedAmount}|${matchPercent}`;

    return (
      <BuilderSection title="Match" className="cadmin-camb-budg" topRight={isAdmin ? topRight : null}>
        {this.showBudgetSettings && isAdmin && (
          <div className="cadmin-camb-budg-abox">
            <div className="cadmin-camb-budg-abox-label-con">
              <div className="cadmin-camb-budg-abox-label">All-Access Admin Only</div>
            </div>

            <div className="cadmin-camb-budg-abox-heading">Ratio</div>
            <StandardSelect
              options={[{label: 'Use annual match ratio', value: false}, {label: 'Use separate ratio', value: true}]}
              onSelect={this.onSelectSepMatchRatio}
              value={this.separateMatchRatio}
            />
            {this.separateMatchRatio && (
              <MatchRatioSelector
                value={matchPercent || 0}
                onChange={this.onChangeMatchPercent}
                validations={validations}
              />
            )}

            <div className="cadmin-camb-budg-abox-heading">Budget</div>
            <StandardSelect
              options={[{label: 'Use annual match budget', value: false}, {label: 'Use separate budget', value: true}]}
              onSelect={this.onSelectSepMatchBudget}
              value={this.separateMatchBudget}
            />
            {this.separateMatchBudget && (
              <MonetaryInput validations={validations} name="budgetAllocatedAmount" value={budgetAllocatedAmount} label="Match Allocated Budget (1,250.00)" onChange={this.onChangeBudget} ref={this.refBudgetAmount} />
            )}
          </div>
        )}
        <div key={helpKey} className="cadmin-camb-budg-help">{help}</div>
      </BuilderSection>
    );
  }

  renderSectionTargetType() {
    const { ffFundsOn, isFund } = this.props;
    if (!ffFundsOn) return null;
    return (
      <BuilderSection title="Campaign Type" className="cadmin-camb-tt">
        <SegmentedInput options={isFundOptions} value={`${isFund}`} onChange={this.onChangeIsFund} />
      </BuilderSection>
    );
  }

  renderSectionNonprofit() {
    const { nonprofits, addNonprofitsToMatch, budgetAllocatedAmount } = this.props;
    const antmExpl = addNonprofitsToMatch ? 'These nonprofits will be added to the approved list for Match.' : 'These nonprofits will not be added to the approved list for Match.';
    return (
      <BuilderSection title="Nonprofits supported">
        {nonprofits.map((nonprofit, index) => {
          return (
            <BuilderIterable index={index} key={index} onDelete={this.onRemoveNonprofit}>
              <NonprofitInput onChange={this.onChangeNonprofit.bind(this, index)} selectedNonprofit={nonprofit} intl={this.hasIntl} />
            </BuilderIterable>
          );
        })}
        <button className="btn small blue cadmin-builder-iterable-add" onClick={this.onClickAddNonprofit}>+ Add Nonprofit</button>
        <div className="cadmin-camb-antm">
          <div className="cadmin-builder-checkbox2">
            <Checkbox className="cadmin-camb-antm-cb" id="checkbox-add-nps-to-match" onChange={this.onChangeAddNonprofitsToMatch} checked={addNonprofitsToMatch} disabled={!!budgetAllocatedAmount} />
            <label htmlFor="checkbox-add-nps-to-match">Add to Match List</label>
          </div>
          <p className="cadmin-camb-antm-expl">{antmExpl}</p>
        </div>
      </BuilderSection>
    );
  }

  renderSectionGroups() {
    const { groups, company } = this.props;
    if (!company.features.groups) return null;

    return (
      <BuilderSection title="Group Support">
        {groups.map((group, index) => {
          return (
            <BuilderIterable index={index} key={index} onDelete={this.onRemoveGroup}>
              <GroupInput onChange={this.onChangeGroup.bind(this, index)} selectedGroup={group} companyId={company.id} />
            </BuilderIterable>
          );
        })}
        <button className="btn small blue cadmin-builder-iterable-add" onClick={this.onClickAddGroup}>+ Add Group</button>
      </BuilderSection>
    );
  }

  renderSectionEvents() {
    const { volEvents, company } = this.props;
    return (
      <BuilderSection title="Volunteer Opportunities">
        {volEvents.map((volEvent, index) => {
          return (
            <BuilderIterable index={index} key={index} onDelete={this.onRemoveEvent}>
              <VolEventInput onChange={this.onChangeEvent.bind(this, index)} selectedVolEvent={volEvent} companyId={company.id} />
            </BuilderIterable>
          );
        })}
        <button className="btn small blue cadmin-builder-iterable-add" onClick={this.onClickAddEvent}>+ Add Opportunity</button>
      </BuilderSection>
    );
  }

  renderSectionSharing() {
    const { isOnDashboard } = this.props;
    return (
      <BuilderSection title="Sharing Settings" className="cadmin-camb-sharing">
        <div className="cadmin-camb-sharing-row">
          <label htmlFor="checkbox-is-on-dashboard" className="cadmin-camb-sharing-label">
            <span>Recommended</span>
            <span>Add new campaign to dashboard</span>
          </label>
          <Checkbox className="cadmin-camb-sharing-cb" id="checkbox-is-on-dashboard" onChange={this.onChangeIsOnDashboard} checked={isOnDashboard} isToggle />
        </div>
      </BuilderSection>
    );
  }

  renderSectionDriveGoods() {
    const {driveGoods, hasSubmitted, showGoodRequiredValidation} = this.props;
    return (
      <BuilderSection title="Goods" isRequired>
        {showGoodRequiredValidation && (
          <p className="cadmin-builder-section-vmsg">At least one good is required.</p>
        )}
        {driveGoods.map((good, i) => {
          if (good._delete) return null;
          return (
            <BuilderIterable index={i} key={i} onDelete={this.onRemoveDriveGood} className="cadmin-camb-good-iterable">
              <BuilderDriveGood driveGood={good} index={i} hasSubmitted={hasSubmitted} />
            </BuilderIterable>
          );
        })}
        <button className="btn small blue cadmin-builder-iterable-add" onClick={this.onClickAddDriveGood}>+ Add Good</button>
      </BuilderSection>
    );
  }

  renderSectionDriveGoal() {
    const {driveGoalType, driveGoalValue, validations} = this.props;
    const goalOn = !!driveGoalType;
    const isMonetary = driveGoalType === DriveGoalTypes.MONETARY;
    const goalOnId = `cb-drive-combined-goal-on`;

    return (
      <BuilderSection title="Combined Goal">
        <div className={`cadmin-camb-good-goal ${goalOn ? 'on' : 'off'}`}>
          <div className="cadmin-camb-good-goal-on">
            <Checkbox id={goalOnId} name="goalOn" checked={goalOn} onChange={this.onChangeDriveGoalOn} />
            <label htmlFor={goalOnId}>Set a Combined Goal</label>
          </div>
          {goalOn && (<>
            <div className="cadmin-camb-good-goal-hr" />
            <SegmentedInput options={driveGoalTypeOptions} value={driveGoalType} onChange={this.onChangeDriveGoalType} className="small stretch" />
            {isMonetary ? (
              <MonetaryInput name="driveGoalValue" value={driveGoalValue} label="Dollar amount (15,500.00)" onChange={this.onChangeDriveGoalValue} validations={validations} />
            ) : (
              <IntegerInput name="driveGoalValue" value={driveGoalValue} label="Quantity (3,500)" onChange={this.onChangeDriveGoalValue} validations={validations} />
            )}
          </>)}
        </div>
      </BuilderSection>
    );
  }

  renderSectionDriveTracking() {
    const {driveAllowEmpTracking} = this.props;
    const allowEmpTrackCbId = `cb-drive-aet`;
    const statement = driveAllowEmpTracking
      ? 'Employees can record their donations themselves.'
      : 'Only admins can record donations.';
    const tipMsg = `If you'd like to record donations in batches, you may want to prevent employees from also recording their donations to prevent duplicative counts. This setting can be changed later.`;

    return (
      <BuilderSection title="Tracking">
        <div className="cadmin-builder-checkbox2 tip-con">
          <Checkbox isToggle checked={driveAllowEmpTracking} id={allowEmpTrackCbId} onChange={this.onChangeAllowEmpTracking} />
          <label htmlFor={allowEmpTrackCbId}>Allow Employees To Track</label>
          <Tip message={tipMsg}></Tip>
        </div>
        <p className="cadmin-camb-drive-aet-statement">{statement}</p>
      </BuilderSection>
    );
  }

  renderFooter() {
    const { hasTouched, hasSubmitted, savePending, isValid, campaignId } = this.props;
    const showValidation = hasSubmitted && !isValid;
    const disabled = !hasTouched || savePending || showValidation;
    const btnText = campaignId
      ? (savePending ? 'Saving...' : 'Save')
      : (savePending ? 'Creating...' : 'Create');

    return (
      <BuilderFooter className="cadmin-camb-bottom">
        {showValidation && (
          <div className="cadmin-builder-bottom-validations">
            <p>Oops! There are some required fields above that need your attention.</p>
          </div>
        )}
        <div className="cadmin-builder-bottom-actions">
          <button className="btn green" disabled={disabled} onClick={this.onClickSave}>{btnText}</button>
        </div>
      </BuilderFooter>
    );
  }

  render() {
    const {driveGoodDeleteIndex} = this.state;
    const {hasGive, hasDrive, hasVol, company} = this.props;

    return (
      <Builder footer={this.renderFooter()} className="cadmin-camb">
        {this.renderSectionTitle()}
        {this.renderSectionImg()}
        {this.renderSectionDescription()}
        {this.renderSectionDates()}

        {company.features.drive && (
          <BuilderSectionGroup title="Goods Drive" color="green" icon={<Icon.ProductsGiftGive />} isOn={hasDrive} onToggle={this.onToggleDrive}>
            {this.renderSectionDriveGoods()}
            {this.renderSectionDriveGoal()}
            {this.renderSectionDriveTracking()}
          </BuilderSectionGroup>
        )}

        <BuilderSectionGroup title="Donations" color="purple" icon={<Icon.AccountingBills />} isOn={hasGive} onToggle={this.onToggleGive}>
          {this.renderSectionGoal()}
          {this.renderSectionBudget()}
          {this.renderSectionTargetType()}
          {this.renderSectionNonprofit()}
        </BuilderSectionGroup>

        {company.features.vol && (
          <BuilderSectionGroup title="Volunteering" color="orange" icon={<Icon.HandExpand />} isOn={hasVol} onToggle={this.onToggleVol}>
            {this.renderSectionEvents()}
          </BuilderSectionGroup>
        )}

        <BuilderSectionGroup title="Settings" isOn hideToggle icon={<Icon.Cog1 />}>
          {this.renderSectionGroups()}
          {this.renderSectionSharing()}
        </BuilderSectionGroup>

        {(driveGoodDeleteIndex != null) && (
          <Confirm onResponse={this.onResponseDeleteDriveGood} confirmBtnLabel="Delete Good" confirmBtnColor="danger">
            <p>Deleting this good <strong>will also delete all donations</strong> associated with this good in this campaign.</p>
            <p><strong>This change can not be undone.</strong> It will take effect when you save the campaign you are editing.</p>
          </Confirm>
        )}

      </Builder>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  company: CadminSlx.company(state),
  ffFundsOn: FfSlx.funds(state),
  // attrs
  goalAmountInCents: BuilderSlx.goalAmountInCents(state),
  isOnDashboard: BuilderSlx.isOnDashboard(state),
  description: BuilderSlx.description(state),
  links: BuilderSlx.links(state),
  startDate: BuilderSlx.startDate(state),
  endDate: BuilderSlx.endDate(state),
  isOngoing: BuilderSlx.isOngoing(state),
  addNonprofitsToMatch: BuilderSlx.addNonprofitsToMatch(state),
  isFund: BuilderSlx.isFund(state),
  imgPath: BuilderSlx.imgPath(state),
  name: BuilderSlx.name(state),
  timezone: BuilderSlx.timezone(state),
  matchPercent: BuilderSlx.matchPercent(state),
  budgetAllocatedAmount: BuilderSlx.budgetAllocatedAmount(state),
  hasGive: BuilderSlx.hasGive(state),
  hasDrive: BuilderSlx.hasDrive(state),
  hasVol: BuilderSlx.hasVol(state),
  driveGoalType: BuilderSlx.driveGoalType(state),
  driveGoalValue: BuilderSlx.driveGoalValue(state),
  driveAllowEmpTracking: BuilderSlx.driveAllowEmpTracking(state),
  // entities
  campaignId: BuilderSlx.campaignId(state),
  nonprofits: BuilderSlx.nonprofits(state),
  volEvents: BuilderSlx.volEvents(state),
  groups: BuilderSlx.groups(state),
  driveGoods: BuilderSlx.driveGoods(state),
  storedCampaign: BuilderSlx.storedCampaign(state),
  // builder state
  saveAttrs: BuilderSlx.saveAttrs(state),
  savePending: BuilderSlx.savePending(state),
  isValid: BuilderSlx.isValid(state),
  hasTouched: BuilderSlx.hasTouched(state),
  hasSubmitted: BuilderSlx.hasSubmitted(state),
  validations: BuilderSlx.visibleValidations(state),
  showGoodRequiredValidation: BuilderSlx.showGoodRequiredValidation(state),
});

const dispatchToProps = (dispatch) => ({
  setNonprofit: (nonprofit, index) => dispatch(BuilderAx.setNonprofit(nonprofit, index)),
  removeNonprofit: (index) => dispatch(BuilderAx.removeNonprofit(index)),
  setVolEvent: (volEvent, index) => dispatch(BuilderAx.setVolEvent(volEvent, index)),
  removeVolEvent: (index) => dispatch(BuilderAx.removeVolEvent(index)),
  setGroup: (group, index) => dispatch(BuilderAx.setGroup(group, index)),
  removeGroup: (index) => dispatch(BuilderAx.removeGroup(index)),
  addLink: () => dispatch(BuilderAx.addLink()),
  updateLink: (index, link) => dispatch(BuilderAx.updateLink(index, link)),
  removeLink: (index) => dispatch(BuilderAx.removeLink(index)),
  setKeyVal: (key, val) => dispatch(BuilderAx.setKeyVal(key, val)),
  setKeyVals: (keyVals) => dispatch(BuilderAx.setKeyVals(keyVals)),
  getTimestamps: () => dispatch(BuilderAx.getTimestamps()),
  addNonprofit: () => dispatch(BuilderAx.addNonprofit()),
  addDriveGood: () => dispatch(BuilderAx.addDriveGood()),
  removeDriveGood: (index) => dispatch(BuilderAx.removeDriveGood(index)),
  save: () => dispatch(BuilderAx.save()),
});

export default connect(stateToProps, dispatchToProps)(CampaignBuilder);
