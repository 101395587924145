"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSource = addSource;
exports.addTarget = addTarget;
exports.removeSource = removeSource;
exports.removeTarget = removeTarget;
exports.REMOVE_TARGET = exports.REMOVE_SOURCE = exports.ADD_TARGET = exports.ADD_SOURCE = void 0;
var ADD_SOURCE = 'dnd-core/ADD_SOURCE';
exports.ADD_SOURCE = ADD_SOURCE;
var ADD_TARGET = 'dnd-core/ADD_TARGET';
exports.ADD_TARGET = ADD_TARGET;
var REMOVE_SOURCE = 'dnd-core/REMOVE_SOURCE';
exports.REMOVE_SOURCE = REMOVE_SOURCE;
var REMOVE_TARGET = 'dnd-core/REMOVE_TARGET';
exports.REMOVE_TARGET = REMOVE_TARGET;

function addSource(sourceId) {
  return {
    type: ADD_SOURCE,
    payload: {
      sourceId: sourceId
    }
  };
}

function addTarget(targetId) {
  return {
    type: ADD_TARGET,
    payload: {
      targetId: targetId
    }
  };
}

function removeSource(sourceId) {
  return {
    type: REMOVE_SOURCE,
    payload: {
      sourceId: sourceId
    }
  };
}

function removeTarget(targetId) {
  return {
    type: REMOVE_TARGET,
    payload: {
      targetId: targetId
    }
  };
}