import _ from 'lodash';

import { Types } from 'app/actions/company-admin/modal-credit-card';
import utils from 'app/reducers/utils';

const initialState = {
  createPending: false,
  createValidations: false,
};

const reducer = utils.createReducer(initialState, {

  [`${Types.CREATE_CC}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
      createValidations: null,
    };
  },
  [`${Types.CREATE_CC}_RESOLVED`]: (state, action) => {
    return {...state,
      createPending: false,
      createValidations: null,
    };
  },
  [`${Types.CREATE_CC}_REJECTED`]: (state, {employeeId, error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      createPending: false,
      createValidations: validations,
    };
  },

});

export default reducer;
