import React from 'react';

import BackstageQuickMenu   from 'app/components/layout/backstage-quick-menu';
import CurrentPage          from 'app/components/layout/current-page';
import ModalConfirmNav      from 'app/components/layout/modal-confirm-nav';
import ModalMonitor         from 'app/components/layout/modal-monitor';
import PageTitle            from 'app/components/layout/page-title';
import Prompts              from 'app/components/layout/prompts';
import SizeMonitor          from 'app/components/layout/size-monitor';
import Toasts               from 'app/components/layout/toasts';
import ModalConfirmDonation from 'app/components/nonprofits/modal-confirm-donation';
import ModalReviewPrompt    from 'app/components/review-prompt/modal';
import ModalAutoRedeemNoti  from 'app/components/gifts/modal-auto-redeem-notification';

class App extends React.PureComponent {

  render() {
    return (
      <>
        <PageTitle />
        <CurrentPage />
        <ModalConfirmDonation />
        <ModalConfirmNav />
        <Toasts />
        <SizeMonitor />
        <ModalMonitor />
        <ModalReviewPrompt />
        <ModalAutoRedeemNoti />
        <Prompts />
        <BackstageQuickMenu />
      </>
    );
  }

}

export default App;
