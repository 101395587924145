import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import CardsAx             from 'app/actions/cards';
import CadminCompaniesAx   from 'app/actions/company-admin/companies';
import GiftsDuck           from 'app/ducks/company-admin/gifts';
import history             from 'app/history';
import paths               from 'app/paths';
import reducerUtils        from 'app/reducers/utils';
import CadminSlx           from 'app/selectors/company-admin/';
import EntitiesSlx         from 'app/selectors/entities';
import RoutingSlx          from 'app/selectors/routing';



/*
 *  Actions
 */

const Types = {
  LOAD: 'CADMIN_PAGE_SGIFT_LOAD',
};

const Ax = {

  load: ({params, query}) => (dispatch, getState) => {
    const promise = Promise.all([
      dispatch(CadminCompaniesAx.get(params.companySlug)),
      dispatch(CardsAx.fetchAll()),
      dispatch(GiftsDuck.Ax.clearSuccess()),
    ]);
    return dispatch({type: Types.LOAD, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  isLoading: false,
};

const reducer = reducerUtils.createReducer(initialState, {

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selIsLoading = (state) => state.companyAdmin.pageGiftsSend.isLoading;

  return {
    isLoading: selIsLoading,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
