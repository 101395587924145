import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import groupHelpers from 'app/helpers/groups';
import EntitiesSlx from 'app/selectors/entities';

const selGroupId = state => state.companyAdmin.builderGroup.groupId;

const selHasTouched = state => state.companyAdmin.builderGroup.hasTouched;
const selSavePending = state => state.companyAdmin.builderGroup.savePending;
const selHasSubmitted = state => state.companyAdmin.builderGroup.hasSubmitted;

const selName = state => state.companyAdmin.builderGroup.name;
const selGroupTypeId = state => state.companyAdmin.builderGroup.groupTypeId;
const selDescription = state => state.companyAdmin.builderGroup.description;
const selBannerImgPath = state => state.companyAdmin.builderGroup.bannerImgPath;
const selLogoImgPath = state => state.companyAdmin.builderGroup.logoImgPath;
const selHighlights = state => state.companyAdmin.builderGroup.highlights;
const selStats = state => state.companyAdmin.builderGroup.stats;
const selResources = state => state.companyAdmin.builderGroup.resources;
const selNonprofitIds = state => state.companyAdmin.builderGroup.nonprofitIds;
const selVolEventIds = state => state.companyAdmin.builderGroup.volEventIds;
const selCampaignIds = state => state.companyAdmin.builderGroup.campaignIds;

const selSaveAttrs = (state) => {
  const attrs = _.pick(state.companyAdmin.builderGroup, groupHelpers.editAttrs);
  const volEvents = EntitiesSlx.volEvents(state);
  groupHelpers.textAttrs.forEach((attr) => {
    attrs[attr] = (attrs[attr] || '').trim();
  });
  attrs.nonprofitIds = _.uniq(attrs.nonprofitIds.filter(id => id));
  attrs.volEventIds = _.uniq(attrs.volEventIds.filter(id => id && volEvents[id]));
  return attrs;
};

const selValidations = createSelector(
  [selSaveAttrs],
  (saveAttrs) => {
    const v = {};
    if (!saveAttrs.name) v.name = ['required'];
    if (!saveAttrs.description) v.description = ['required'];
    if (!saveAttrs.bannerImgPath) v.bannerImgPath = ['required'];
    if (!saveAttrs.logoImgPath) v.logoImgPath = ['required'];
    if (!saveAttrs.groupTypeId) v.groupTypeId = ['required'];
    return Object.keys(v).length ? v : null;
  }
);

const selVisibleValidations = createSelector(
  [selValidations, selHasSubmitted],
  (validations, hasSubmitted) => hasSubmitted ? validations : null
);

const selIsValid = createSelector(
  [selValidations, selHighlights, selStats, selResources],
  (validations, highlights, stats, resources) => {
    if (validations) return false;
    const hasInvalidHighlight = highlights.some(groupHelpers.getHighlightValidations);
    if (hasInvalidHighlight) return false;
    const hasInvalidStat = stats.some(groupHelpers.getStatValidations);
    if (hasInvalidStat) return false;
    const hasInvalidResource = resources.some(groupHelpers.getResourceValidations);
    if (hasInvalidResource) return false;
    return true;
  }
);

const selNonprofits = createSelector(
  [selNonprofitIds, EntitiesSlx.nonprofits],
  (npids, nonprofits) => {
    return npids.map(npid => nonprofits[npid]);
  }
);

const selSaveNonprofitIds = createSelector(
  [selNonprofits],
  (nonprofits) => {
    return nonprofits.filter(n => n).map(n => n.id);
  }
);

const selVolEvents = createSelector(
  [selVolEventIds, EntitiesSlx.volEvents],
  (ids, volEvents) => {
    return ids.map(id => volEvents[id]);
  }
);

const selCampaigns = createSelector(
  [selCampaignIds, EntitiesSlx.campaigns],
  (ids, campaigns) => {
    return ids.map(id => campaigns[id]);
  }
);

export default {
  name: selName,
  groupTypeId: selGroupTypeId,
  description: selDescription,
  bannerImgPath: selBannerImgPath,
  logoImgPath: selLogoImgPath,

  highlights: selHighlights,
  stats: selStats,
  resources: selResources,

  groupId: selGroupId,
  nonprofits: selNonprofits,
  volEvents: selVolEvents,
  campaigns: selCampaigns,
  saveNonprofitIds: selSaveNonprofitIds,
  
  saveAttrs: selSaveAttrs,
  hasSubmitted: selHasSubmitted,
  savePending: selSavePending,
  hasTouched: selHasTouched,
  visibleValidations: selVisibleValidations,
  validations: selValidations,
  isValid: selIsValid,
};
