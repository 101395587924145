import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Icon            from 'app/components/common/icon';
import Link            from 'app/components/common/link';
import Modal           from 'app/components/common/modal';
import StandardInput   from 'app/components/common/standard-input';
import config          from 'app/config';
import ModalDuck       from 'app/ducks/company-admin/modal-ms-teams-channel';
import TeamsDuck       from 'app/ducks/company-admin/ms-teams';
import paths           from 'app/paths';
import CadminSlx       from 'app/selectors/company-admin/';
import EntitiesSlx     from 'app/selectors/entities';

class ModalTeamsChannel extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      name: null,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onClickCreate = this.onClickCreate.bind(this);
    this.onClickUnlink = this.onClickUnlink.bind(this);
    this.onClickOpCreate = this.onClickOpCreate.bind(this);
    this.onClickOpFind = this.onClickOpFind.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
  }

  componentDidMount() {
    this.props.reset();
    if (this.props.defaultName) {
      this.setState({name: this.props.defaultName});
    }
    if (this.props.id) {
      this.props.get(this.props.id);
    }
  }

  onCloseModal() {
    this.props.onClose();
  }

  onClickCreate() {
    const { create, onCreate } = this.props;
    const { name } = this.state;
    create(name, onCreate);
  }

  onClickUnlink(event) {
    event.preventDefault();
    const { unlink, onUnlink, unlinkPending, defaultName } = this.props;
    const didConfirm = confirm(`Are you sure you want to unlink the Teams channels from ${defaultName}?`);
    if (!didConfirm) return;
    if (unlinkPending) return;
    unlink(onUnlink);
  }

  onClickOpCreate() {
    this.props.setOp('create');
  }
  onClickOpFind() {
    this.props.setOp('find');
  }

  onChangeName(event) {
    const name = event.target.value;
    this.setState({name});
  }

  renderChannel() {
    const { company, id, teamsChannel, createPending, createValidations, op } = this.props;
    if (id && !teamsChannel) return <Icon.Loading className="loading" />;
    const { name } = this.state;
    const isCreate = op === 'create';
    const btnLabelCreate = createPending
      ? (isCreate ? 'Creating...' : 'Connecting...')
      : (isCreate ? 'Create' : 'Connect');

    return teamsChannel ? (<>
      <div className="ca-modal-mstchannel-channel">
        <Icon.LogoMsTeams className="ca-modal-mstchannel-channel-icon" />
        <div className="ca-modal-mstchannel-channel-name">{teamsChannel.name}</div>
      </div>
    </>) : (
      <div className="ca-modal-mstchannel-input">
        <div className="ca-modal-mstchannel-input-tabs">
          <button onClick={this.onClickOpCreate} className={`ca-modal-mstchannel-input-tab ${isCreate ? 'active' : ''}`}>New Channel</button>
          {/* <button onClick={this.onClickOpFind} className={`ca-modal-mstchannel-input-tab ${!isCreate ? 'active' : ''}`}>Existing Channel</button> */}
        </div>
        <p className="ca-modal-mstchannel-input-op-desc">Create a new channel in Teams:</p>
        <div className="ca-modal-mstchannel-input-form">
          <StandardInput label="Channel Name" name="name" value={name || ''} onChange={this.onChangeName} icon={<Icon.LogoMsTeams />} validations={createValidations} />
          <button className="btn special slack" onClick={this.onClickCreate} disabled={createPending}>{btnLabelCreate}</button>
        </div>
      </div>
    );
  }

  renderActions() {
    const { actions } = this.props;
    if (!actions) return null;
    return (
      <div className="ca-modal-mstchannel-actions">
        {actions}
      </div>
    );
  }

  renderBottom() {
    const { teamsChannel, unlinkPending } = this.props;
    if (!teamsChannel) return null;
    const btnLabel = unlinkPending ? 'Unlinking...' : 'Unlink Channel';
    return (
      <div className="ca-modal-mstchannel-bottom">
        <p>If you’d like to rename the channel, you can do this directly within Teams.</p>
        <a className="ca-modal-mstchannel-bottom-unlink" onClick={this.onClickUnlink}>{btnLabel}</a>
      </div>
    );
  }

  render() {
    const { company, title, subHeader } = this.props;

    return (
      <Modal className="ca-modal-mstchannel" onClose={this.onCloseModal}>
        <div className="ca-modal-mstchannel-header">
          <img src="/images/company-admin/ms-teams-logo.png" alt="Teams" />
          <h1>{title}</h1>
        </div>
        {subHeader}
        {this.renderChannel()}
        {this.renderActions()}
        {this.renderBottom()}
      </Modal>
    );
  }

}

ModalTeamsChannel.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUnlink: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  teamsChannel: PropTypes.object,
  subHeader: PropTypes.node,
  defaultName: PropTypes.string,
  actions: PropTypes.node,
};

ModalTeamsChannel.defaultProps = {
  subHeader: null,
};

const stateToProps = (state, ownProps) => ({
  company: CadminSlx.company(state),
  teamsChannel: ownProps.teamsChannel || EntitiesSlx.teamsChannelById(state, ownProps.id),

  createPending: ModalDuck.Slx.createPending(state),
  createValidations: ModalDuck.Slx.createValidations(state),
  unlinkPending: ModalDuck.Slx.unlinkPending(state),
  op: ModalDuck.Slx.op(state),
});

const dispatchToProps = (dispatch) => ({
  get: (id) => dispatch(TeamsDuck.Ax.channelsGet(id)),
  reset: () => dispatch(ModalDuck.Ax.reset()),
  setOp: (op) => dispatch(ModalDuck.Ax.setOp(op)),
  create: (name, onCreate) => dispatch(ModalDuck.Ax.create(name, onCreate)),
  unlink: (onUnlink) => dispatch(ModalDuck.Ax.unlink(onUnlink)),
});

export default connect(stateToProps, dispatchToProps)(ModalTeamsChannel);
