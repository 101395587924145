import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import GroupsAx      from 'app/actions/company-admin/groups';
import Link          from 'app/components/common/link';
import Modal         from 'app/components/common/modal';
import StandardInput from 'app/components/common/standard-input';
import ModalChannel  from 'app/components/company-admin/ms-teams/modal-channel';
import config        from 'app/config';
import paths         from 'app/paths';
import CadminSlx     from 'app/selectors/company-admin/';

class ModalTeamsChannelGroup extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onUnlink = this.onUnlink.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  onCreate(teamsChannel) {
    const { company, group, update } = this.props;
    return update(company.id, group.id, {teamsChannelId: teamsChannel.id});
  }

  onUnlink() {
    const { company, group, update } = this.props;
    return update(company.id, group.id, {teamsChannelId: null});
  }

  renderSubHeader() {
    const { group } = this.props;
    return (
      <div className="ca-modal-schannel-subheader">
        <img src={`${config.s3BaseUrl}/${group.logoImgPath}`} />
        {group.name}
      </div>
    );
  }

  render() {
    const { group } = this.props;

    return (
      <ModalChannel
        title="Group Settings"
        onClose={this.onClose}
        onCreate={this.onCreate}
        onUnlink={this.onUnlink}
        id={group.teamsChannelId}
        subHeader={this.renderSubHeader()}
        defaultName={group.name}
      />
    );
  }

}

ModalTeamsChannelGroup.propTypes = {
  onClose: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
});

const dispatchToProps = (dispatch) => ({
  update: (companyId, groupId, attrs) => dispatch(GroupsAx.update(companyId, groupId, attrs)),
});

export default connect(stateToProps, dispatchToProps)(ModalTeamsChannelGroup);
