
import CadminCcAx from 'app/actions/company-admin/credit-cards';
import CadminSlx from 'app/selectors/company-admin/';

const Types = {
  CREATE_CC: 'CADMIN_MODAL_CC_CREATE_CC',
};

const Actions = {

  createCc: (cardElement) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(CadminCcAx.create(companyId, cardElement));
    return dispatch({type: Types.CREATE_CC, promise});
  },

};

export {
  Types,
};

export default Actions;
