import { createSelector } from 'reselect';
import _ from 'lodash';

import CadminSlx from 'app/selectors/company-admin/';
import EntitiesSlx from 'app/selectors/entities';
// import RoutingSlx from 'app/selectors/routing';

const selLoadPending = state => state.companyAdmin.pageWallet.loadPending;
const selCreatePlaidBaPending = state => state.companyAdmin.pageWallet.createPlaidBaPending;
const selManualBankAccountStatus = state => state.companyAdmin.pageWallet.manualBankAccountStatus;
const selBaIds = state => state.companyAdmin.pageWallet.baIds;
const selFetchBasPending = state => state.companyAdmin.pageWallet.fetchBasPending;
const selDeleteBaPending = state => state.companyAdmin.pageWallet.deleteBaPending;
const selCcIds = state => state.companyAdmin.pageWallet.ccIds;
const selFetchCcsPending = state => state.companyAdmin.pageWallet.fetchCcsPending;
const selDeleteCcPending = state => state.companyAdmin.pageWallet.deleteCcPending;
const selDeleteConflictId = state => state.companyAdmin.pageWallet.deleteConflictId;

const selBankAccounts = createSelector(
  [selBaIds, EntitiesSlx.bankAccounts],
  (ids, bankAccounts) => ids.map(id => bankAccounts[id])
);

const selVerifiedBankAccounts = createSelector(
  [selBaIds, EntitiesSlx.bankAccounts],
  (ids, bankAccounts) => ids.map(id => bankAccounts[id]).filter(account => account.verified)
);

const selCreditCards = createSelector(
  [selCcIds, EntitiesSlx.creditCards],
  (ids, creditCards) => ids.map(id => creditCards[id])
);

const selCcsLoading = createSelector(
  [selFetchCcsPending, selDeleteCcPending],
  (fetchPending, deletePending) => !!(fetchPending || deletePending)
);

const selBasLoading = createSelector(
  [selFetchBasPending, selDeleteBaPending, selCreatePlaidBaPending, selManualBankAccountStatus],
  (fetchPending, deletePending, createPlaidPending, manualBankAccountStatus) => !!(fetchPending || deletePending || createPlaidPending || (manualBankAccountStatus === 'createPending'))
);

export default {
  bankAccounts: selBankAccounts,
  verifiedBankAccounts: selVerifiedBankAccounts,
  creditCards: selCreditCards,
  loadPending: selLoadPending,
  createPlaidBaPending: selCreatePlaidBaPending,
  manualBankAccountStatus: selManualBankAccountStatus,
  basLoading: selBasLoading,
  ccsLoading: selCcsLoading,
  deleteConflictId: selDeleteConflictId,
};
