import _ from 'lodash';
import numeral from 'numeral';
import { createSelector } from 'reselect';

import config      from 'app/config';
import compHelpers from 'app/helpers/companies';
import paths       from 'app/paths';
import EntitiesSlx from 'app/selectors/entities';

const selIsMasquerading = state => state.auth.isMasquerading;
const selLoginPending = state => state.auth.loginPending;
const selLoginFailed = state => state.auth.loginFailed;
const selLoginValidations = state => state.auth.loginValidations;
const selLoginMustReset = state => state.auth.loginMustReset;
const selSsoLoginPending = state => state.auth.ssoLoginPending;
const selSsoLoginFailed = state => state.auth.ssoLoginFailed;
const selSsoLoginValidations = state => state.auth.ssoLoginValidations;
const selSignupPending = state => state.auth.signupPending;
const selSignupFailed = state => state.auth.signupFailed;
const selSignupValidations = state => state.auth.signupValidations;
const selResendEvPending = state => state.auth.resendEvPending;
const selResendEvFailed = state => state.auth.resendEvFailed;
const selResendEvSuccess = state => state.auth.resendEvSuccess;
const selCurrentUserId = state => state.auth.currentUserId;
const selSendResetPending = state => state.auth.sendResetPending;
const selSendResetSuccess = state => state.auth.sendResetSuccess;
const selSendResetFailed = state => state.auth.sendResetFailed;
const selSendResetValidations = state => state.auth.sendResetValidations;
const selResendInvitePending = state => state.auth.resendInvitePending;
const selResendInviteSuccess = state => state.auth.resendInviteSuccess;
const selResendInviteFailed = state => state.auth.resendInviteFailed;
const selResendInviteValidations = state => state.auth.resendInviteValidations;
const selEmpEmailSignupPending = state => state.auth.empEmailSignupPending;
const selEmpEmailSignupSuccess = state => state.auth.empEmailSignupSuccess;
const selEmpEmailSignupFailed = state => state.auth.empEmailSignupFailed;
const selEmpEmailSignupValidations = state => state.auth.empEmailSignupValidations;

const selCurrentUser = createSelector(
  [selCurrentUserId, EntitiesSlx.users],
  (id, users) => {
    const user = users[id];
    if (!user) return null;
    const balanceAmount = user.balance?.amount || 0;
    const giftBalanceAmount = user.giftBalance?.amount || 0;
    const totalBalanceAmount = balanceAmount + giftBalanceAmount;
    const adminableCompany = _.get(user, 'employment.employee.isAdmin') ? _.get(user, 'employment.company') : null;
    return {
      ...user,
      adminableCompany,
      balanceAmount,
      giftBalanceAmount,
      totalBalanceAmount,
    };
  }
);

const selNeedsEmailVerification = createSelector(
  [selCurrentUser],
  (currentUser) => {
    return !!(currentUser && !currentUser.emailVerified);
  }
);

const selNonprofitAdmin = createSelector(
  [selCurrentUser],
  (currentUser) => _.get(currentUser, 'nonprofitAdmins[0]')
);

const selNonprofitAdmins = createSelector(
  [selCurrentUser],
  (currentUser) => _.get(currentUser, 'nonprofitAdmins', [])
);

// TODO: this should be removed in favor of currentUser.adminableCompany
const selAdminableCompany = createSelector(
  [selCurrentUser],
  (currentUser) => currentUser && currentUser.adminableCompany
);

const selEmployment = createSelector(
  [selCurrentUser],
  (currentUser) => _.get(currentUser, 'employment')
);

const selEmployerCompany = createSelector(
  [selCurrentUser, EntitiesSlx.companies],
  (user, companies) => {
    if (!user) return null;
    const companyId = _.get(user, 'employment.company.id', null);
    return companies[companyId];
  }
);

const selDefaultCompanyPath = createSelector(
  [selCurrentUser],
  (currentUser) => {
    if (!currentUser) return null;
    const company = _.get(currentUser, 'employment.company');
    if (!company) return null;
    const isGmOnly = compHelpers.isGmOnly(company);
    const isAdmin = _.get(currentUser, 'employment.employee.isAdmin') || false;
    if (isAdmin) {
      return isGmOnly
        ? paths.cadminBrackets(company.slug)
        : paths.cadminDashboardViewWithoutId(company.slug);
    }
    if (isGmOnly) return paths.happenings();
    if (company.companyDashboardId) return paths.companyDashboard(company.companyDashboardId);
    // TODO: probably should only default here when company actually has match
    return paths.nonprofits({companyId: company.id});
  }
);

const selRequiresApproval = createSelector(
  [selCurrentUser],
  (currentUser) => !!_.get(currentUser, 'employment.company.requiresApproval')
);

export default {
  isMasquerading: selIsMasquerading,
  currentUserId: selCurrentUserId,
  currentUser: selCurrentUser,
  needsEmailVerification: selNeedsEmailVerification,
  employment: selEmployment,
  defaultCompanyPath: selDefaultCompanyPath,
  nonprofitAdmin: selNonprofitAdmin,
  nonprofitAdmins: selNonprofitAdmins,
  adminableCompany: selAdminableCompany,
  loginPending: selLoginPending,
  loginFailed: selLoginFailed,
  loginValidations: selLoginValidations,
  loginMustReset: selLoginMustReset,
  ssoLoginPending: selSsoLoginPending,
  ssoLoginFailed: selSsoLoginFailed,
  ssoLoginValidations: selSsoLoginValidations,
  signupPending: selSignupPending,
  signupFailed: selSignupFailed,
  signupValidations: selSignupValidations,
  resendEvPending: selResendEvPending,
  resendEvFailed: selResendEvFailed,
  resendEvSuccess: selResendEvSuccess,
  sendResetPending: selSendResetPending,
  sendResetSuccess: selSendResetSuccess,
  sendResetFailed: selSendResetFailed,
  sendResetValidations: selSendResetValidations,
  resendInvitePending: selResendInvitePending,
  resendInviteSuccess: selResendInviteSuccess,
  resendInviteFailed: selResendInviteFailed,
  resendInviteValidations: selResendInviteValidations,
  empEmailSignupPending: selEmpEmailSignupPending,
  empEmailSignupSuccess: selEmpEmailSignupSuccess,
  empEmailSignupFailed: selEmpEmailSignupFailed,
  empEmailSignupValidations: selEmpEmailSignupValidations,
  employerCompany: selEmployerCompany,
  requiresApproval: selRequiresApproval,
};
