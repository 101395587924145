import _ from 'lodash';
import { createSelector } from 'reselect';

const selMembershipByGroupId = state => state.groups.membershipByGroupId;
const selMembershipIsFetching = state => state.groups.membershipIsFetching;
const selMembershipIsChanging = state => state.groups.membershipIsChanging;

export default {
  membershipByGroupId: selMembershipByGroupId,
  membershipIsFetching: selMembershipIsFetching,
  membershipIsChanging: selMembershipIsChanging,
};
