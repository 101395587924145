"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HandlerRole = void 0;
var HandlerRole;
exports.HandlerRole = HandlerRole;

(function (HandlerRole) {
  HandlerRole["SOURCE"] = "SOURCE";
  HandlerRole["TARGET"] = "TARGET";
})(HandlerRole || (exports.HandlerRole = HandlerRole = {}));