"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HTML = exports.TEXT = exports.URL = exports.FILE = void 0;
var FILE = '__NATIVE_FILE__';
exports.FILE = FILE;
var URL = '__NATIVE_URL__';
exports.URL = URL;
var TEXT = '__NATIVE_TEXT__';
exports.TEXT = TEXT;
var HTML = '__NATIVE_HTML__';
exports.HTML = HTML;