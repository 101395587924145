"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reduce = reduce;

var _dragOffset = require("./dragOffset");

var _dragOperation = require("./dragOperation");

var _refCount = require("./refCount");

var _dirtyHandlerIds = require("./dirtyHandlerIds");

var _stateId = require("./stateId");

var _js_utils = require("../utils/js_utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function reduce() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  return {
    dirtyHandlerIds: (0, _dirtyHandlerIds.reduce)(state.dirtyHandlerIds, {
      type: action.type,
      payload: _objectSpread(_objectSpread({}, action.payload), {}, {
        prevTargetIds: (0, _js_utils.get)(state, 'dragOperation.targetIds', [])
      })
    }),
    dragOffset: (0, _dragOffset.reduce)(state.dragOffset, action),
    refCount: (0, _refCount.reduce)(state.refCount, action),
    dragOperation: (0, _dragOperation.reduce)(state.dragOperation, action),
    stateId: (0, _stateId.reduce)(state.stateId)
  };
}