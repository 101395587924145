import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';
import ImageInput from 'app/components/nonprofit-profile/image-input';
import StandardInput from 'app/components/common/standard-input';

const PH_IMAGE = 'Program Image';
const PH_TITLE_1 = 'Coding Bootcamp';
const PH_TEXT_1 = 'Our coding bootcamps service 5,000 girls across 23 states nationwide. This is a great way for us to introduce software engineering to girls in a high-touch way.';
const PH_TITLE_2 = 'Tech Camp';
const PH_TEXT_2 = 'One of our favorite programs! Tech camp runs in the summer and gives girls a deeper dive on a variety of programming languages. Camps span across 5 grades…';

class ProgramInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.onChangePhoto = this.onChangePhoto.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  onChangeTitle(event) {
    const title = event.target.value;
    const { onChange, program, index } = this.props;
    onChange(index, {...program, title});
  }

  onChangeText(event) {
    const text = event.target.value;
    const { onChange, program, index } = this.props;
    onChange(index, {...program, text});
  }

  onChangePhoto(imgPath) {
    const { onChange, program, index } = this.props;
    onChange(index, {...program, imgPath});
  }

  onClickDelete(event) {
    event.preventDefault();
    const { onDelete, index } = this.props;
    onDelete(index);
  }

  render() {
    const {program, index, validations, nonprofitProfileId} = this.props;

    let phTitle = PH_TITLE_1;
    let phText = PH_TEXT_1;
    if (index === 1) {
      phTitle = PH_TITLE_2;
      phText = PH_TEXT_2;
    }

    return (
      <div className="npp-edit-form-subsection npp-edit-form-program">
        <Icon.Bin className="npp-edit-form-subsection-delete" onClick={this.onClickDelete} />
        <ImageInput
          name={`program[${index}].imgPath`}
          label={PH_IMAGE}
          pathValue={program.imgPath}
          onChange={this.onChangePhoto}
          nonprofitProfileId={nonprofitProfileId}
        />
        <StandardInput
          name={`program[${index}].title`}
          label={phTitle}
          value={program.title || ''}
          validations={validations}
          onChange={this.onChangeTitle}
        />
        <StandardInput
          type="textarea"
          name={`program[${index}].text`}
          label={phText}
          value={program.text || ''}
          validations={validations}
          onChange={this.onChangeText}
        />
      </div>
    );
  }

}

ProgramInput.propTypes = {
  program: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  validations: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  nonprofitProfileId: PropTypes.string.isRequired,
};

ProgramInput.defaultProps = {
  validations: {},
  className: '',
};

export default ProgramInput;
