import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';

import EllipsisMenu     from 'app/components/common/ellipsis-menu';
import Icon             from 'app/components/common/icon';
import Link             from 'app/components/common/link';
import {
  CampaignStatuses,
}                       from 'app/constants';
import PageDuck         from 'app/ducks/company-admin/page-analytics';
import bracketHelpers   from 'app/helpers/brackets';
import campaignHelpers  from 'app/helpers/campaigns';
import employeeHelpers  from 'app/helpers/employees';
import format           from 'app/helpers/format';
import Metrics          from 'app/metrics';
import paths            from 'app/paths';
import EntitiesSlx      from 'app/selectors/entities';

const campaignStatusLabels = {
  [CampaignStatuses.ACTIVE]: 'Active',
  [CampaignStatuses.UPCOMING]: 'Upcoming',
  [CampaignStatuses.ENDED]: 'Ended',
};

const datesToSubtext = (startStr, endStr, {showYear=true} = {}) => {
  const format = showYear ? 'MMM D, YYYY' : 'MMM D';
  const start = startStr ? moment(startStr).format(format) : null;
  const end   = endStr   ? moment(endStr).format(format)   : null;
  if (!start && !end) return 'Ongoing';
  if (!start) return `Ended ${end}`;
  return `${start} - ${end}`;
};

class GroupByCell extends React.PureComponent {

  scrollToTop() {
    const titleEl = document.querySelector('.mtrx-title');
    if (!titleEl) return;
    titleEl.scrollIntoView({behavior: 'smooth'});
  }

  onClickFilterCampaign(campaign, event) {
    event.preventDefault();
    const {groupBy, setRouteParams} = this.props;
    const newParams = {page: 1, preset: 'campaigns', sortCol: null, sortDir: null};
    Metrics.setEntityParam(newParams, 'campaignId', campaign.id);
    if (groupBy === 'campaignId') newParams.groupBy = 'employeeId';
    setRouteParams(newParams);
    this.scrollToTop();
  }

  onClickFilterBracket(bracket, event) {
    event.preventDefault();
    const {groupBy, setRouteParams} = this.props;
    const newParams = {page: 1, preset: 'brackets', sortCol: null, sortDir: null};
    Metrics.setEntityParam(newParams, 'bracketId', bracket.id);
    if (groupBy === 'bracketId') newParams.groupBy = 'employeeId';
    setRouteParams(newParams);
    this.scrollToTop();
  }

  onClickFilterVolEvent(volEvent, event) {
    event.preventDefault();
    const {groupBy, setRouteParams} = this.props;
    const newParams = {page: 1, preset: 'volunteering', sortCol: null, sortDir: null};
    Metrics.setEntityParam(newParams, 'volEventId', volEvent.id);
    if (groupBy === 'volEventId') newParams.groupBy = 'employeeId';
    setRouteParams(newParams);
    this.scrollToTop();
  }

  onClickFilterGroupEvent(groupEvent, event) {
    event.preventDefault();
    const {groupBy, setRouteParams} = this.props;
    const newParams = {page: 1, preset: 'groupEvents', sortCol: null, sortDir: null};
    Metrics.setEntityParam(newParams, 'groupEventId', groupEvent.id);
    if (groupBy === 'groupEventId') newParams.groupBy = 'employeeId';
    setRouteParams(newParams);
    this.scrollToTop();
  }

  onClickFilterEmployee(employee, event) {
    event.preventDefault();
    const {groupBy, setRouteParams} = this.props;
    const newParams = {page: 1, preset: 'employees', sortCol: null, sortDir: null};
    Metrics.setEntityParam(newParams, 'employeeId', employee.id);
    if (groupBy === 'employeeId') newParams.groupBy = 'nonprofitId';
    setRouteParams(newParams);
    this.scrollToTop();
  }

  onClickFilterNonprofit(nonprofit, event) {
    event.preventDefault();
    const {groupBy, setRouteParams} = this.props;
    const newParams = {page: 1, preset: 'nonprofits', sortCol: null, sortDir: null};
    Metrics.setEntityParam(newParams, 'nonprofitId', nonprofit.id);
    if (groupBy === 'nonprofitId') newParams.groupBy = 'employeeId';
    setRouteParams(newParams);
    this.scrollToTop();
  }

  onClickFilterCompany(company, event) {
    event.preventDefault();
    const {groupBy, setRouteParams} = this.props;
    const newParams = {page: 1, preset: 'impact', sortCol: null, sortDir: null};
    Metrics.setEntityParam(newParams, 'companyId', company.id);
    if (groupBy === 'companyId') newParams.groupBy = 'campaignId';
    setRouteParams(newParams);
    this.scrollToTop();
  }

  renderCell(left, right) {
    return (
      <td className="mtrx-table-cell-gby">
        <div className="mtrx-table-cell-gby-row">
          <div className="mtrx-table-cell-gby-left">{left}</div>
          {right}
        </div>
      </td>
    );
  }

  renderInterval() {
    const {row, groupBy} = this.props;
    const format = ({
      month: ' MMMM YYYY',
      quarter: '[Q]Q YYYY',
      year: 'YYYY',
    })[groupBy];
    const isMonth = groupBy === 'month';
    const mom = moment(row[groupBy]);
    const startStr = mom.format('YYYY-MM-DD');
    const endStr = mom.clone().add(1, groupBy).subtract(1, 'day').format('YYYY-MM-DD');
    const showYear = groupBy === 'year';
    const subtext = isMonth ? null : datesToSubtext(startStr, endStr, {showYear});
    return this.renderCell(<>
      <strong>{mom.format(format)}</strong>
      <br />
      <span className="faint">{subtext}</span>
    </>);
  }

  renderCampaign() {
    const {row, campaigns} = this.props;
    const campaign = campaigns[row.campaignId];
    const name = campaign ? campaign.name : 'Unassociated';

    return this.renderCell(
      (<>
        <strong>{name}</strong>
        <br />
        <span className="faint">{campaign ? datesToSubtext(campaign.startDate, campaign.endDate) : ''}</span>
      </>),
      (campaign && (
        <EllipsisMenu usePortal>
          <a href="#" onClick={this.onClickFilterCampaign.bind(this, campaign)}><Icon.AnalyticsBars /> View Analytics</a>
          <Link href={paths.campaign(campaign.id)}><Icon.BrowserPageText /> View Campaign</Link>
        </EllipsisMenu>
      ))
    );
  }

  renderBracket() {
    const {row, brackets} = this.props;
    const bracket = brackets[row.bracketId];
    const name = bracket ? bracket.name : 'Unassociated';
    const startDateStr = bracket && bracketHelpers.startDateStr(bracket);
    const endDateStr   = bracket && bracketHelpers.endDateStr(bracket);
    const subtext = bracket && datesToSubtext(startDateStr, endDateStr);

    return this.renderCell(
      (<>
        <strong>{name}</strong>
        <br />
        <span className="faint">{subtext}</span>
      </>),
      (bracket && (
        <EllipsisMenu usePortal>
          <a href="#" onClick={this.onClickFilterBracket.bind(this, bracket)}><Icon.AnalyticsBars /> View Analytics</a>
          <Link href={paths.bracket(bracket.id)}><Icon.BrowserPageText /> View Bracket</Link>
        </EllipsisMenu>
      ))
    );
  }

  renderEmployee() {
    const {row, employees} = this.props;
    const employee = employees[row.employeeId];
    const name = employee ? `${employee.firstName} ${employee.lastName}` : 'Unassociated';
    const subtext = employee ? `${employeeHelpers.roleLabel(employee.role)} | ${employee.status}` : '';

    return this.renderCell(
      (<>
        <strong>{name}</strong>
        <br />
        <span className="faint">{subtext}</span>
      </>),
      (employee && (
        <EllipsisMenu usePortal>
          <a href="#" onClick={this.onClickFilterEmployee.bind(this, employee)}><Icon.AnalyticsBars /> View Analytics</a>
        </EllipsisMenu>
      ))
    );
  }

  renderNonprofit() {
    const {row, nonprofits} = this.props;
    const nonprofit = nonprofits[row.nonprofitId];
    const name = nonprofit ? nonprofit.name : 'Unassociated';
    const subtext = nonprofit?.ein || '';

    return this.renderCell(
      (<>
        <strong>{name}</strong>
        <br />
        <span className="faint">{subtext}</span>
      </>),
      (nonprofit && (
        <EllipsisMenu usePortal>
          <a href="#" onClick={this.onClickFilterNonprofit.bind(this, nonprofit)}><Icon.AnalyticsBars /> View Analytics</a>
          <Link href={paths.nonprofit(nonprofit)}><Icon.BrowserPageText /> View Profile</Link>
        </EllipsisMenu>
      ))
    );
  }

  renderEvent() {
    const {row, volEvents} = this.props;
    const event = volEvents[row.volEventId || row.groupEventId];
    const name = event ? event.title : 'Unassociated';
    const location = event ? (event.isRemote ? 'Remote' : event.city) : null;
    const date = event ? (event.startDate ? moment(event.startDate).format('MMM D, YYYY') : 'Ongoing') : null;
    const subtext = [date, location].filter(a => a).join(' | ');

    return this.renderCell(
      (<>
        <strong>{name}</strong>
        <br />
        <span className="faint">{subtext}</span>
      </>),
      (event && (
        <EllipsisMenu usePortal>
          <a href="#" onClick={row.volEventId ? this.onClickFilterVolEvent.bind(this, event) : this.onClickFilterGroupEvent.bind(this, event)}><Icon.AnalyticsBars /> View Analytics</a>
          {!event.isHidden && (
            <Link href={paths.volEvent(event.id)}><Icon.BrowserPageText /> View Event</Link>
          )}
        </EllipsisMenu>
      ))
    );
  }

  renderCompany() {
    const {row, companies} = this.props;
    const company = companies[row.companyId];
    const name = company ? company.name : 'Unassociated';
    const subtext = company ? `${numeral(company.employeeCount || 0).format('0,0')} ${format.pluralize('employee', company.employeeCount || 0)}` : '';

    return this.renderCell(
      (<>
        <strong>{name}</strong>
        <br />
        <span className="faint">{subtext}</span>
      </>),
      (company && (
        <EllipsisMenu usePortal>
          <a href="#" onClick={this.onClickFilterCompany.bind(this, company)}><Icon.AnalyticsBars /> View Analytics</a>
        </EllipsisMenu>
      ))
    );
  }

  render() {
    const {groupBy} = this.props;
    const isInterval = ['month', 'quarter', 'year'].includes(groupBy);
    if (isInterval) return this.renderInterval();
    if (groupBy === 'campaignId')   return this.renderCampaign();
    if (groupBy === 'employeeId')   return this.renderEmployee();
    if (groupBy === 'nonprofitId')  return this.renderNonprofit();
    if (groupBy === 'volEventId')   return this.renderEvent();
    if (groupBy === 'groupEventId') return this.renderEvent();
    if (groupBy === 'bracketId')    return this.renderBracket();
    if (groupBy === 'companyId')    return this.renderCompany();

    return (
      <td></td>
    );
  }

}

GroupByCell.propTypes = {
  row: PropTypes.object.isRequired,
  groupBy: PropTypes.string.isRequired,
};

const stateToProps = (state) => ({
  campaigns: EntitiesSlx.campaigns(state),
  employees: EntitiesSlx.employees(state),
  brackets: EntitiesSlx.brackets(state),
  volEvents: EntitiesSlx.volEvents(state),
  nonprofits: EntitiesSlx.nonprofits(state),
  companies: EntitiesSlx.companies(state),

  groupBy: PageDuck.Slx.groupBy(state),
});

const dispatchToProps = (dispatch) => ({
  setRouteParams: (newParams) => dispatch(PageDuck.Ax.setRouteParams(newParams)),
});

export default connect(stateToProps, dispatchToProps)(GroupByCell);
