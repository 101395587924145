"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reduce = reduce;

var _registry = require("../actions/registry");

function reduce() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _registry.ADD_SOURCE:
    case _registry.ADD_TARGET:
      return state + 1;

    case _registry.REMOVE_SOURCE:
    case _registry.REMOVE_TARGET:
      return state - 1;

    default:
      return state;
  }
}