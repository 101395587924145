
import MillieApi from 'app/apis/millie';

const Types = {
  UPDATE_AVATAR: 'ACCOUNT_UPDATE_AVATAR',
  UPDATE: 'ACCOUNT_UPDATE',
  PARTIAL_UPDATE: 'ACCOUNT_PARTIAL_UPDATE',
  LOAD_PAGE_NEW_PW: 'ACCOUNT_LOAD_PAGE_NEW_PW',
  SET_NEW_PW: 'ACCOUNT_SET_NEW_PW',
};

const Actions = {

  updateAvatar: (file) => {
    const promise = MillieApi.accountUpdateAvatar(file);
    return { type: Types.UPDATE_AVATAR, promise };
  },

  update: ({firstName, lastName, postalCode, email, private: isPrivate}) => {
    const promise = MillieApi.accountUpdate({firstName, lastName, postalCode, email, private: isPrivate});
    return { type: Types.UPDATE, promise, email };
  },

  partialUpdate: (attrs) => {
    const promise = MillieApi.accountPartialUpdate(attrs);
    return { type: Types.PARTIAL_UPDATE, promise };
  },

  loadPageNewPw: (location) => (dispatch, getState) => {
    const promise = Promise.resolve();
    return dispatch({type: Types.LOAD_PAGE_NEW_PW, promise});
  },

  setNewPassword: (pw, pw2) => {
    const promise = MillieApi.accountSetNewPassword(pw, pw2);
    return {type: Types.SET_NEW_PW, promise};
  },

};

export {
  Types,
};

export default Actions;
