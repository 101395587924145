import _ from 'lodash';
import { createSelector } from 'reselect';

const selActions                    = state => state.entities.actions;
const selBalanceTransactions        = state => state.entities.balanceTransactions;
const selBankAccounts               = state => state.entities.bankAccounts;
const selBrackets                   = state => state.entities.brackets;
const selBracketGames               = state => state.entities.bracketGames;
const selBracketLaunchDetails       = state => state.entities.bracketLaunchDetails;
const selBracketVoteRecords         = state => state.entities.bracketVoteRecords;
const selBudgets                    = state => state.entities.budgets;
const selCampaigns                  = state => state.entities.campaigns;
const selCards                      = state => state.entities.cards;
const selCattrSets                  = state => state.entities.cattrSets;
const selCities                     = state => state.entities.cities;
const selCollections                = state => state.entities.collections;
const selCompanies                  = state => state.entities.companies;
const selCompanyCharges             = state => state.entities.companyCharges;
const selCompanyDashboardDatas      = state => state.entities.companyDashboardDatas;
const selCompanyDashboards          = state => state.entities.companyDashboards;
const selCompanyNonprofits          = state => state.entities.companyNonprofits;
const selCompanyPurchases           = state => state.entities.companyPurchases;
const selCountries                  = state => state.entities.countries;
const selCreditCards                = state => state.entities.creditCards;
const selCustomAttributeOptions     = state => state.entities.customAttributeOptions;
const selCustomAttributes           = state => state.entities.customAttributes;
const selDisbursements              = state => state.entities.disbursements;
const selDonations                  = state => state.entities.donations;
const selDriveDonations             = state => state.entities.driveDonations;
const selDriveDonationEntries       = state => state.entities.driveDonationEntries;
const selDriveGoods                 = state => state.entities.driveGoods;
const selEmployeeDonations          = state => state.entities.employeeDonations;
const selEmployees                  = state => state.entities.employees;
const selFunds                      = state => state.entities.funds;
const selFxRatesSets                = state => state.entities.fxRatesSets;
const selGifts                      = state => state.entities.gifts;
const selGrants                     = state => state.entities.grants;
const selGroups                     = state => state.entities.groups;
const selGroupEmployees             = state => state.entities.groupEmployees;
const selGroupTypes                 = state => state.entities.groupTypes;
const selHappenings                 = state => state.entities.happenings;
const selIrsEntities                = state => state.entities.irsEntities;
const selUsers                      = state => state.entities.users;
const selMatchInquiries             = state => state.entities.matchInquiries;
const selNonprofits                 = state => state.entities.nonprofits;
const selNonprofitProfiles          = state => state.entities.nonprofitProfiles;
const selNteeCodes                  = state => state.entities.nteeCodes;
const selOfflineDonations           = state => state.entities.offlineDonations;
const selPages                      = state => state.entities.pages;
const selPayrollDeductions          = state => state.entities.payrollDeductions;
const selRecurringDonations         = state => state.entities.recurringDonations;
const selRecurringPayrollDeductions = state => state.entities.recurringPayrollDeductions;
const selSlackChannels              = state => state.entities.slackChannels;
const selSlackConnectNonces         = state => state.entities.slackConnectNonces;
const selSlackTokens                = state => state.entities.slackTokens;
const selTags                       = state => state.entities.tags;
const selTeamsChannels              = state => state.entities.teamsChannels;
const selVolEvents                  = state => state.entities.volEvents;
const selVolEventParticipants       = state => state.entities.volEventParticipants;
const selVolEventShifts             = state => state.entities.volEventShifts;
const selVolTimeEntries             = state => state.entities.volTimeEntries;
const selWalletLoads                = state => state.entities.walletLoads;

const selPlainActionById    = (state, id) => state.entities.actions[id];
const selCampaignById       = (state, id) => state.entities.campaigns[id];
const selCityById           = (state, id) => state.entities.cities[id];
const selGroupById          = (state, id) => state.entities.groups[id];
const selPlainNonprofitById = (state, id) => state.entities.nonprofits[id];
const selPlainNteeCodeId    = (state, id) => state.entities.nteeCodes[id];
const selSlackChannelById   = (state, id) => state.entities.slackChannels[id];
const selTeamsChannelById   = (state, id) => state.entities.teamsChannels[id];
const selPlainVolEventById  = (state, id) => state.entities.volEvents[id];

const selDonatables = createSelector(
  [selNonprofits, selFunds],
  (nonprofits, funds) => {
    return {...nonprofits, ...funds};
  }
);

const selActionById = createSelector(
  [selPlainActionById, selUsers, selDonatables],
  (action, users, donatables) => {
    if (!action) return null;
    return {
      ...action,
      donatable: donatables[action.donatableId],
      primaryUser: users[action.primaryUserId],
      secondaryUser: users[action.secondaryUserId],
    };
  }
);

const selVolEventById = createSelector(
  [selPlainVolEventById, selVolEventShifts, selCompanies],
  (volEvent, shifts, companies) => {
    // if (!volEvent) return null;
    const shiftIds = _.get(volEvent, 'volEventShiftIds', []);
    return {
      ...volEvent,
      volEventShifts: shiftIds.map(id => shifts[id]),
      company: companies[volEvent.companyId],
    };
  }
);

const selGetCompanyNonprofitById = createSelector(
  [selCompanyNonprofits, selNonprofits, selUsers],
  (cnMap, nonprofitMap, userMap) => (cnId) => {
    const cn = cnMap[cnId];
    if (!cn) return null;
    return {
      ...cn,
      nonprofit: nonprofitMap[cn.nonprofitId],
      approvalChangeUser: userMap[cn.approvalChangeUserId],
    };
  }
);

export default {
  actions: selActions,
  balanceTransactions: selBalanceTransactions,
  bankAccounts: selBankAccounts,
  brackets: selBrackets,
  bracketGames: selBracketGames,
  bracketLaunchDetails: selBracketLaunchDetails,
  bracketVoteRecords: selBracketVoteRecords,
  budgets: selBudgets,
  campaigns: selCampaigns,
  cards: selCards,
  cattrSets: selCattrSets,
  cities: selCities,
  collections: selCollections,
  companies: selCompanies,
  companyCharges: selCompanyCharges,
  companyDashboardDatas: selCompanyDashboardDatas,
  companyDashboards: selCompanyDashboards,
  companyNonprofits: selCompanyNonprofits,
  companyPurchases: selCompanyPurchases,
  countries: selCountries,
  creditCards: selCreditCards,
  customAttributeOptions: selCustomAttributeOptions,
  customAttributes: selCustomAttributes,
  disbursements: selDisbursements,
  donations: selDonations,
  driveDonations: selDriveDonations,
  driveDonationEntries: selDriveDonationEntries,
  driveGoods: selDriveGoods,
  employeeDonations: selEmployeeDonations,
  employees: selEmployees,
  funds: selFunds,
  fxRatesSets: selFxRatesSets,
  gifts: selGifts,
  grants: selGrants,
  groups: selGroups,
  groupEmployees: selGroupEmployees,
  groupTypes: selGroupTypes,
  happenings: selHappenings,
  irsEntities: selIrsEntities,
  users: selUsers,
  matchInquiries: selMatchInquiries,
  nonprofitProfiles: selNonprofitProfiles,
  nteeCodes: selNteeCodes,
  nonprofits: selNonprofits,
  offlineDonations: selOfflineDonations,
  pages: selPages,
  payrollDeductions: selPayrollDeductions,
  recurringDonations: selRecurringDonations,
  recurringPayrollDeductions: selRecurringPayrollDeductions,
  slackChannels: selSlackChannels,
  slackConnectNonces: selSlackConnectNonces,
  slackTokens: selSlackTokens,
  tags: selTags,
  teamsChannels: selTeamsChannels,
  volEvents: selVolEvents,
  volEventParticipants: selVolEventParticipants,
  volEventShifts: selVolEventShifts,
  volTimeEntries: selVolTimeEntries,
  walletLoads: selWalletLoads,
  donatables: selDonatables,

  actionById: selActionById,
  campaignById: selCampaignById,
  volEventById: selVolEventById,
  slackChannelById: selSlackChannelById,
  teamsChannelById: selTeamsChannelById,
  nonprofitById: selPlainNonprofitById,
  groupById: selGroupById,
  cityById: selCityById,
  plainNteeCodeId: selPlainNteeCodeId,

  getCompanyNonprofitById: selGetCompanyNonprofitById,
};
