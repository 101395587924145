import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import MillieApi from 'app/apis/millie';
import Icon from 'app/components/common/icon';
import StandardInput from 'app/components/common/standard-input';

const getImgNameFromPath = (path) => {
  if (!path) return null;
  const s3Name = path.split('/').pop();
  return s3Name.split('-').slice(1).join('-');
};

class UploadedImageInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      imagePath: null,
      failed: false,
    };

    this.refStandardInput = React.createRef();

    this.onChange = this.onChange.bind(this);
  }

  get validations() {
    if (this.state.failed) {
      return {[this.props.name]: ['Something went wrong. File may be too large.']};
    }
    return this.props.validations;
  }

  upload(file) {
    const { s3PathParts, maxImageWidth, onChange } = this.props;
    const didChange = !!this.state.imagePath;
    this.setState({uploading: true, imagePath: null, failed: false});
    if (didChange) onChange(null);
    MillieApi.imagesUpload(s3PathParts, file, maxImageWidth)
      .then(({imagePath}) => {
        this.setState({uploading: false, imagePath});
        onChange(imagePath);
      })
      .catch((error) => {
        this.setState({uploading: false, failed: true})
      });
  }

  clear() {
    this.setState({imagePath: null});
    this.props.onChange(null);
  }

  onChange(event, file) {
    // const file = _.get(this.refStandardInput, 'current.element.files[0]');
    file ? this.upload(file) : this.clear();
  }

  render() {
    const {uploading} = this.state;
    const {name, label, validations, pathValue, allowPdf} = this.props;
    const imgName = getImgNameFromPath(pathValue);
    const accept = allowPdf ? 'image/*,application/pdf' : 'image/*';

    return (
      <StandardInput
        accept={accept}
        type="file"
        name={name}
        label={label}
        value={imgName || ''}
        validations={this.validations}
        icon={<Icon.ImageFileAdd />}
        rightIcon={uploading ? <Icon.Loading /> : null}
        onChange={this.onChange}
        ref={this.refStandardInput}
      />
    );
  }

}

UploadedImageInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validations: PropTypes.object,
  allowPdf: PropTypes.bool,
  // path to image given in onChange
  pathValue: PropTypes.string,
  // array of strings that will be joined to form path to file in S3
  s3PathParts: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  // the image will be resized so that width and height both max out at this number
  maxImageWidth: PropTypes.number,
};

UploadedImageInput.defaultProps = {
  allowPdf: false,
  validations: {},
  className: '',
  maxImageWidth: 1024,
};

export default UploadedImageInput;
