import _ from 'lodash';
import { createSelector } from 'reselect';

import ToastAx      from 'app/actions/toast';
import MsTeamsDuck  from 'app/ducks/company-admin/ms-teams';
import reducerUtils from 'app/reducers/utils';
import CadminSlx    from 'app/selectors/company-admin/';



/*
 *  Actions
 */

const Types = {
  RESET: 'CADMIN_MODAL_MS_TEAMS_CHANNEL_RESET',
  SET_OP: 'CADMIN_MODAL_MS_TEAMS_CHANNEL_SET_OP',
  CREATE: 'CADMIN_MODAL_MS_TEAMS_CHANNEL_CREATE',
  UNLINK: 'CADMIN_MODAL_MS_TEAMS_CHANNEL_UNLINK',
  JOIN: 'CADMIN_MODAL_MS_TEAMS_CHANNEL_JOIN',
};

const Ax = {

  reset: () => {
    return {type: Types.RESET};
  },

  setOp: (op) => {
    return {type: Types.SET_OP, op};
  },

  create: (name, onCreate) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = dispatch(MsTeamsDuck.Ax.channelsCreate(name))
      .then(({teamsChannel}) => onCreate(teamsChannel));
    promise.catch((error) => {
      dispatch(ToastAx.error('Oops! Something went wrong while creating Teams channel.'));
    });
    return dispatch({type: Types.CREATE, promise});
  },

  unlink: (onUnlink) => (dispatch, getState) => {
    const companyId = CadminSlx.companyId(getState());
    const promise = Promise.resolve(onUnlink());
    return dispatch({type: Types.UNLINK, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  createPending: false,
  createSuccess: false,
  createValidations: null,
  unlinkPending: false,
  unlinkSuccess: false,
  op: 'create',
};

const reducer = reducerUtils.createReducer(initialState, {

  [Types.RESET]: (state, action) => {
    return {...state, ...initialState};
  },

  [Types.SET_OP]: (state, {op}) => {
    return {...state,
      op,
      createValidations: null,
    };
  },

  [`${Types.CREATE}_PENDING`]: (state, action) => {
    return {...state,
      createPending: true,
      createSuccess: false,
      createValidations: null,
    };
  },
  [`${Types.CREATE}_RESOLVED`]: (state, action) => {
    return {...state,
      createPending: false,
      createSuccess: true,
      createValidations: null,
    };
  },
  [`${Types.CREATE}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      createPending: false,
      createSuccess: false,
      createValidations: validations,
    };
  },

  [`${Types.UNLINK}_PENDING`]: (state, action) => {
    return {...state,
      unlinkPending: true,
      unlinkSuccess: false,
    };
  },
  [`${Types.UNLINK}_RESOLVED`]: (state, action) => {
    return {...state,
      unlinkPending: false,
      unlinkSuccess: true,
    };
  },
  [`${Types.UNLINK}_REJECTED`]: (state, action) => {
    return {...state,
      unlinkPending: false,
      unlinkSuccess: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selCreatePending     = state => state.companyAdmin.modalMsTeamsChannel.createPending;
  const selCreateSuccess     = state => state.companyAdmin.modalMsTeamsChannel.createSuccess;
  const selCreateValidations = state => state.companyAdmin.modalMsTeamsChannel.createValidations;
  const selUnlinkPending     = state => state.companyAdmin.modalMsTeamsChannel.unlinkPending;
  const selUnlinkSuccess     = state => state.companyAdmin.modalMsTeamsChannel.unlinkSuccess;
  const selOp                = state => state.companyAdmin.modalMsTeamsChannel.op;

  return {
    createPending: selCreatePending,
    createSuccess: selCreateSuccess,
    createValidations: selCreateValidations,
    unlinkPending: selUnlinkPending,
    unlinkSuccess: selUnlinkSuccess,
    op: selOp,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
