
import SessionStorageAx from 'app/actions/session-storage';
import CampaignsAx from 'app/actions/campaigns';
import millieApi from 'app/apis/millie';

const Types = {
  CREATE: 'DONATIONS_CREATE',
};

const Actions = {

  create: ({donatableType, donatableId, amount, currencyCode, anonymous=false, campaignId=null, balanceType, note=null}) => (dispatch, getState) => {
    const promise = millieApi.donationsCreate({donatableType, donatableId, amount, currencyCode, anonymous, campaignId, balanceType, note});
    promise.then(() => {
      if (campaignId) dispatch(CampaignsAx.get(campaignId));
      dispatch(SessionStorageAx.setDonationTarget(null));
    });
    return dispatch({ type: Types.CREATE, promise });
  },

};

export {
  Types,
};

export default Actions;
