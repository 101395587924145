import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import Checkbox             from 'app/components/common/checkbox';
import DateRangePicker      from 'app/components/common/date-range-picker';
import Icon                 from 'app/components/common/icon';
import Link                 from 'app/components/common/link';
import Pagination           from 'app/components/common/pagination';
import Popper               from 'app/components/common/popper';
import StandardSelect       from 'app/components/common/standard-select';
import EntityInput          from 'app/components/company-admin/common/entity-input';
import CadminLayout         from 'app/components/company-admin/layout/';
import GroupByCell          from 'app/components/company-admin/stats/group-by-cell';
import MetricHelp           from 'app/components/company-admin/stats/metric-help';
import ModalMetricsSelector from 'app/components/company-admin/stats/modal-metrics-selector';
import PageLoading          from 'app/components/layout/page-loading';
import config               from 'app/config';
import {
  VolEventTypes,
}                           from 'app/constants';
import PageDuck             from 'app/ducks/company-admin/page-analytics';
import Metrics              from 'app/metrics';
import paths                from 'app/paths';
import CadminSlx            from 'app/selectors/company-admin/';
import RoutingSlx           from 'app/selectors/routing';

class PageCadminStats2 extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showMetricsModal: false,
    };

    this.onSelectPage = this.onSelectPage.bind(this);
    this.onClickMetrics = this.onClickMetrics.bind(this);
    this.onCloseMsModal = this.onCloseMsModal.bind(this);
    this.onChangeMetrics = this.onChangeMetrics.bind(this);
    this.onSelectFilterBy = this.onSelectFilterBy.bind(this);
    this.onSelectDateRange = this.onSelectDateRange.bind(this);
    this.onChangeEmployee = this.onChangeEmployee.bind(this);
    this.onChangeCampaign = this.onChangeCampaign.bind(this);
    this.onChangeBracket = this.onChangeBracket.bind(this);
    this.onChangeVolEvent = this.onChangeVolEvent.bind(this);
    this.onChangeGroupEvent = this.onChangeGroupEvent.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.onChangeShowEmptyRows = this.onChangeShowEmptyRows.bind(this);
  }

  get isSystem() {
    if (!config.systemEventsCompanyId) return false;
    return this.props.company?.id === config.systemEventsCompanyId;
  }

  get filterByLabels() {
    return {
      campaignId: 'Campaign',
      employeeId: 'Employee',
      nonprofitId: 'Nonprofit',
      bracketId: 'Bracket',
      volEventId: 'Volunteer Event',
      groupEventId: 'Group Event',
      ...(this.isSystem ? {companyId: 'Company'} : {}),
    };
  }

  get groupByLabels() {
    return {
      month: 'Month',
      quarter: 'Quarter',
      year: 'Year',
      ...this.filterByLabels,
    }
  }

  get filterByOpts() {
    return Object.entries(this.filterByLabels).map(([value, label]) => ({value, label}));
  }

  onChangeEmployee(employee) {
    const {setRouteParams, groupBy} = this.props;
    const params = {page: null, ...Metrics.nullEntityParams, employeeId: (employee?.id || '*')};
    if (params.employeeId && (params.employeeId !== '*') && (groupBy === 'employeeId')) params.groupBy = 'nonprofitId';
    setRouteParams(params);
  }
  onChangeCampaign(campaign) {
    const {setRouteParams, groupBy} = this.props;
    const params = {page: null, ...Metrics.nullEntityParams, campaignId: (campaign?.id || '*')};
    if (params.campaignId && (params.campaignId !== '*') && (groupBy === 'campaignId')) params.groupBy = 'employeeId';
    setRouteParams(params);
  }
  onChangeBracket(bracket) {
    const {setRouteParams, groupBy} = this.props;
    const params = {page: null, ...Metrics.nullEntityParams, bracketId: (bracket?.id || '*')};
    if (params.bracketId && (params.bracketId !== '*') && (groupBy === 'bracketId')) params.groupBy = 'employeeId';
    setRouteParams(params);
  }
  onChangeVolEvent(volEvent) {
    const {setRouteParams, groupBy} = this.props;
    const params = {page: null, ...Metrics.nullEntityParams, volEventId: (volEvent?.id || '*')};
    if (params.volEventId && (params.volEventId !== '*') && (groupBy === 'volEventId')) params.groupBy = 'employeeId';
    setRouteParams(params);
  }
  onChangeGroupEvent(groupEvent) {
    const {setRouteParams, groupBy} = this.props;
    const params = {page: null, ...Metrics.nullEntityParams, groupEventId: (groupEvent?.id || '*')};
    if (params.groupEventId && (params.groupEventId !== '*') && (groupBy === 'groupEventId')) params.groupBy = 'employeeId';
    setRouteParams(params);
  }
  onChangeNonprofit(nonprofit) {
    const {setRouteParams, groupBy} = this.props;
    const params = {page: null, ...Metrics.nullEntityParams, nonprofitId: (nonprofit?.id || '*')};
    if (params.nonprofitId && (params.nonprofitId !== '*') && (groupBy === 'nonprofitId')) params.groupBy = 'employeeId';
    setRouteParams(params);
  }
  onChangeCompany(company) {
    const {setRouteParams, groupBy} = this.props;
    const params = {page: null, ...Metrics.nullEntityParams, companyId: (company?.id || '*')};
    if (params.companyId && (params.companyId !== '*') && (groupBy === 'companyId')) params.groupBy = 'campaignId';
    setRouteParams(params);
  }

  onSelectPage(page) {
    this.props.setRouteParams({page});
  }

  onCloseMsModal() {
    this.setState({showMetricsModal: false});
  }

  onChangeMetrics(metricKeys) {
    this.props.setMetrics(metricKeys);
  }

  onClickMetrics() {
    this.setState({showMetricsModal: true});
  }

  onSelectFilterBy(selectedFilterBy) {
    const newParams = {page: null, ...Metrics.nullEntityParams};
    if (selectedFilterBy) {
      newParams[selectedFilterBy] = '*';
    }
    this.props.setRouteParams(newParams);
  }

  onSelectDateRange({startDateStr: startDate, endDateStr: endDate}) {
    this.props.setRouteParams({startDate, endDate, page: null});
  }

  onChangeShowEmptyRows(event) {
    const showEmptyRows = event.target.checked;
    this.props.setRouteParams({showEmptyRows, page: null});
  }

  onClickHeader(sortCol) {
    const isSorted = this.props.sortCol === sortCol;
    const isDesc = this.props.sortDir === 'desc';
    const params = (() => {
      if (!isSorted) return {sortCol, sortDir: 'desc', page: null};
      if (isDesc)    return {sortCol, sortDir: 'asc',  page: null};
      return {sortCol: null, sortDir: null,  page: null};
    })();
    this.props.setRouteParams(params);
  }

  onClickPreset(preset) {
    this.props.setRouteParams({
      preset: preset.key,
      ...Metrics.nullEntityParams,
      ...preset.defaultQuery,
      showEmptyRows: null,
      include: null,
      exclude: null,
      groupBy: null,
      sortCol: null,
      sortDir: null,
      page: null,
    });
    setTimeout(() => {
      const isCustom = preset.key === 'custom';
      if (isCustom) this.onClickMetrics();
    }, 100);
  }

  onClickGroupBy(groupBy, event) {
    event.preventDefault();
    const params = {groupBy, page: null};

    // toggle the showEmptyRows field if switch from interval to non-interval or vice versa
    const prevGroupBy = this.props.groupBy;
    const wasInterval = Metrics.intervalGroupBys.includes(prevGroupBy);
    const isInterval  = Metrics.intervalGroupBys.includes(groupBy);
    if (isInterval && !wasInterval) params.showEmptyRows = true;
    if (!isInterval && wasInterval) params.showEmptyRows = false;

    this.props.setRouteParams(params);
  }

  renderFilterBy() {
    const {queryParams, filterBy, company} = this.props;
    if (!filterBy) return null;
    const value = (queryParams[filterBy] === '*') ? null : queryParams[filterBy];
    const filterComp = (() => {
      if (filterBy === 'employeeId')   return <EntityInput.Employee  employeeId={value}  onChange={this.onChangeEmployee} />;
      if (filterBy === 'campaignId')   return <EntityInput.Campaign  campaignId={value}  onChange={this.onChangeCampaign} />;
      if (filterBy === 'volEventId')   return <EntityInput.VolEvent  volEventId={value}  onChange={this.onChangeVolEvent}   eventType={VolEventTypes.VOL_OPP} />;
      if (filterBy === 'groupEventId') return <EntityInput.VolEvent  volEventId={value}  onChange={this.onChangeGroupEvent} eventType={VolEventTypes.EVENT} />;
      if (filterBy === 'bracketId')    return <EntityInput.Bracket   bracketId={value}   onChange={this.onChangeBracket} />;
      if (filterBy === 'nonprofitId')  return <EntityInput.Nonprofit nonprofitId={value} onChange={this.onChangeNonprofit} intl={!!company?.features?.international} />;
      if (filterBy === 'companyId')    return <EntityInput.Company   companyId={value}   onChange={this.onChangeCompany} />;
      return null;
    })();
    if (!filterComp) return null;
    const label = this.filterByLabels[filterBy];

    return (
      <div className="ca-main-filters-filter">
        <label className="ca-main-filters-filter-label">{label}</label>
        {filterComp}
      </div>      
    );
  }

  renderFilters() {
    const {currentFiscalYear, queryParams, filterBy} = this.props;
    return (
      <div className="ca-main-filters mtrx-filters">
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Date Range</label>
          <DateRangePicker leftAlign onSelect={this.onSelectDateRange} currentFiscalYear={currentFiscalYear} startDateStr={queryParams.startDate} endDateStr={queryParams.endDate} allowClear label="All Dates" />
        </div>
        <div className="ca-main-filters-filter">
          <label className="ca-main-filters-filter-label">Filter By</label>
          <StandardSelect options={this.filterByOpts} onSelect={this.onSelectFilterBy} value={filterBy} allowClear />
        </div>
        {this.renderFilterBy()}
      </div>
    );
  }

  renderReportHeading() {
    const {preset, entityFilterLabel, queryParams} = this.props;
    const PresetIcon = preset.icon;
    const dateLabel = (() => {
      if (!queryParams.startDate || !queryParams.endDate) return null;
      const startStr = moment(queryParams.startDate).format('MMM D, YYYY');
      const endStr = moment(queryParams.endDate).format('MMM D, YYYY');
      return `${startStr} - ${endStr}`;
    })();

    return (<>
      <div className={`ca-main-head color-${preset.color || ''}`}>
        <h1 className="ca-main-head-h1"><PresetIcon />{preset.name}</h1>
        <div className="ca-main-head-actions">
          <button className="btn secondary" onClick={this.onClickMetrics}>Customize</button>
        </div>
      </div>
      <div className="mtrx-filter-headings">
        {entityFilterLabel && <h2 className="mtrx-filter-headings-filter">{entityFilterLabel}</h2>}
        {dateLabel && <h2 className="mtrx-filter-headings-filter light">{dateLabel}</h2>}
        {/* {entityFilterLabel.map((label, i) => <h2 key={i} className="mtrx-filter-headings-filter">{label}</h2>)} */}
      </div>
    </>);
  }

  renderPresets() {
    const {preset: selectedPreset} = this.props;
    const firstRow = Object.values(Metrics.presets).slice(0, 5);
    const secondRow = Object.values(Metrics.presets).slice(5);
    return (<>
      {[firstRow, secondRow].map((row, i) => (
        <div className="mtrx-presets" key={i}>
          {row.map((preset) => {
            const isActive = preset.key === selectedPreset.key;
            const activeClass = isActive ? 'active' : '';
            const IconComp = preset.icon || Icon.CheckCircle1;
            const colorClass = preset.color || '';
            return (
              <button key={preset.key} className={`mtrx-presets-preset ${activeClass} ${colorClass}`} onClick={this.onClickPreset.bind(this, preset)}>
                <IconComp />
                {preset.name}
              </button>
            );
          })}
        </div>
      ))}
    </>);
  }

  renderTotals() {
    const {summary, selectedMetrics, preset, fetchIsPending} = this.props;
    if (!summary) return null;
    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Totals</h1>
        </div>

        <div className={`mtrx-sum ${preset.color || ''}`}>
          {selectedMetrics.map((metric) => {
            const formatFn = metric.formatSum || metric.format
            const val = fetchIsPending ? '-' : formatFn(summary[metric.key]);
            const popContent = <MetricHelp metric={metric} />;
            return (
              <Popper tagType="div" className="mtrx-sum-metric" key={metric.key} popContent={popContent} hoverDelay={500} hoverTrigger>
                <div className="mtrx-sum-metric-value">{val}</div>
                <div className="mtrx-sum-metric-label">{metric.name}</div>
              </Popper>
            );
          })}
        </div>
      </div>
    );
  }

  renderGroupByHeaderCell() {
    const {groupBy, sortCol, sortDir} = this.props;
    const label = this.groupByLabels[groupBy];
    const isInterval = ['month', 'quarter', 'year'].includes(groupBy);
    const isSorted = (sortCol === 'joincol') && isInterval;
    const sortableClass = isInterval ? 'sortable' : '';
    const sortClass = isSorted ? `sort sort-${sortDir}` : '';
    const onClick = isInterval ? this.onClickHeader.bind(this, 'joincol') : null;
    return (
      <th className={`mtrx-table-cell-header mtrx-table-cell-gby ${sortClass} ${sortableClass}`} onClick={onClick}>
        <div className="mtrx-table-cell-gby-row">
          {label}
          {isSorted && <Icon.Caret direction={sortDir === 'asc' ? 'up' : 'down'} className="mtrx-table-cell-sortind" />}
        </div>
      </th>
    );
  }

  renderTable() {
    const {rows, groupBy: selectedGroupBy, filterBy, selectedMetrics, sortCol, sortDir, fetchIsPending, pagination, showEmptyRows, queryParams, summary, csvHref} = this.props;
    if (!rows) return null;

    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Breakdown</h1>
          <div className="ca-box-header-controls">
            <div className="ca-box-header-controls-cb-con">
              <Checkbox checked={showEmptyRows} id="mtrx-show-empty-rows" onChange={this.onChangeShowEmptyRows} />
              <label htmlFor="mtrx-show-empty-rows">Include Empty Rows</label>
            </div>
            <a className="btn secondary small icon" href={csvHref}><Icon.CommonFileTextDownload /> CSV</a>
          </div>
        </div>
        <div className="mtrx-tabs">
          <div className="mtrx-tabs-tabs">
            {Object.entries(this.groupByLabels)
              .filter(([groupBy]) => {
                if (queryParams[filterBy] === '*') return true;
                return groupBy !== filterBy;
              })
              .map(([groupBy, label], i) => {
                const activeClass = (groupBy === selectedGroupBy) ? 'active' : '';
                // const activeClass = (groupBy === selectedGroupBy) ? '' : 'secondary';
                const sepClass = (i === 3) ? 'separate' : '';
                return (
                  <Link key={groupBy} className={`mtrx-tabs-tab ${activeClass} ${sepClass}`} href="#" onClick={this.onClickGroupBy.bind(this, groupBy)}>By {label}</Link>
                );
              })
            }
          </div>
        </div>
        <div className="ca-box-body">
          <div className="mtrx-table-con2">
          <div className="mtrx-table-con">
            <table className="mtrx-table">
              <thead>
                <tr>
                  {this.renderGroupByHeaderCell()}
                  {selectedMetrics.map((metric) => {
                    const isSorted = sortCol === metric.key;
                    const sortClass = isSorted ? `sort sort-${sortDir}` : '';
                    const popContent = <MetricHelp metric={metric} />;
                    return (
                      <Popper tagType="th" className={`mtrx-table-cell-header sortable ${sortClass}`} onClick={this.onClickHeader.bind(this, metric.key)} key={metric.key} popContent={popContent} hoverDelay={500} hoverTrigger usePortal>
                        <div className="mtrx-table-cell-header-row">
                          {metric.name}
                          {isSorted && <Icon.Caret direction={sortDir === 'asc' ? 'up' : 'down'} className="mtrx-table-cell-sortind" />}
                        </div>
                      </Popper>
                    );
                  })}
                  <th className="mtrx-table-cell-header">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {!fetchIsPending && (
                  <tr>
                    <td className={`mtrx-table-cell-gby`}>
                      <div className="mtrx-table-cell-gby-row">
                        <strong>Total</strong>
                      </div>
                    </td>
                    {selectedMetrics.map((metric) => {
                      return (
                        <td className="mtrx-table-cell-val" key={metric.key}>{metric.format(summary[metric.key])}</td>
                      );
                    })}
                    <td>&nbsp;</td>
                  </tr>
                )}
                {!fetchIsPending && rows.map((row) => {
                  return (
                    <tr key={row.joincol}>
                      <GroupByCell row={row} groupBy={selectedGroupBy} />
                      {selectedMetrics.map((metric) => {
                        return (
                          <td className="mtrx-table-cell-val" key={metric.key}>{metric.format(row[metric.key])}</td>
                        );
                      })}
                      <td className="mtrx-table-cell-val">&nbsp;</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {fetchIsPending && (
              <Icon.Loading className="mtrx-table-loading" />
            )}
          </div>
          </div>
          <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
        </div>
      </div>
    );
  }

  render() {
    Popper.updateAll();
    const {company, selectedMetrics, preset} = this.props;
    const {showMetricsModal} = this.state;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-mtrx" company={company} activeItem={`anal-${preset.key}`}>

        <div className="mtrx-title">
          <Icon.AnalyticsBars />
          <h1>{this.isSystem ? 'SYSTEM Analytics' : 'Analytics'}</h1>
        </div>
        {this.renderPresets()}
        {this.renderFilters()}

        {this.renderReportHeading()}
        {this.renderTotals()}
        {this.renderTable()}

        {showMetricsModal && (
          <ModalMetricsSelector onClose={this.onCloseMsModal} onChange={this.onChangeMetrics} initialKeys={selectedMetrics.map(m => m.key)} />
        )}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
  currentFiscalYear: CadminSlx.currentFiscalYear(state),

  fetchIsPending: PageDuck.Slx.fetchIsPending(state),
  summary: PageDuck.Slx.summary(state),
  pagination: PageDuck.Slx.pagination(state),
  rows: PageDuck.Slx.rows(state),
  preset: PageDuck.Slx.preset(state),
  showEmptyRows: PageDuck.Slx.showEmptyRows(state),
  groupBy: PageDuck.Slx.groupBy(state),
  filterBy: PageDuck.Slx.filterBy(state),
  sortCol: PageDuck.Slx.sortCol(state),
  sortDir: PageDuck.Slx.sortDir(state),
  selectedMetrics: PageDuck.Slx.selectedMetrics(state),
  queryParams: PageDuck.Slx.queryParams(state),
  entityFilterLabel: PageDuck.Slx.entityFilterLabel(state),
  csvHref: PageDuck.Slx.csvHref(state),
});

const dispatchToProps = (dispatch) => ({
  setMetrics: (keys) => dispatch(PageDuck.Ax.setMetrics(keys)),
  setRouteParams: (newParams) => dispatch(PageDuck.Ax.setRouteParams(newParams)),
});

export default connect(stateToProps, dispatchToProps)(PageCadminStats2);
