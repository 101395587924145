"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  createDragDropActions: true
};
exports.createDragDropActions = createDragDropActions;

var _beginDrag = require("./beginDrag");

var _publishDragSource = require("./publishDragSource");

var _hover = require("./hover");

var _drop = require("./drop");

var _endDrag = require("./endDrag");

var _types = require("./types");

Object.keys(_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _types[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _types[key];
    }
  });
});

function createDragDropActions(manager) {
  return {
    beginDrag: (0, _beginDrag.createBeginDrag)(manager),
    publishDragSource: (0, _publishDragSource.createPublishDragSource)(manager),
    hover: (0, _hover.createHover)(manager),
    drop: (0, _drop.createDrop)(manager),
    endDrag: (0, _endDrag.createEndDrag)(manager)
  };
}