
import millieApi    from 'app/apis/millie';

const Types = {
  FETCH: 'GROUPS_FETCH',
  GET: 'GROUPS_GET',
  JOIN: 'GROUPS_JOIN',
  LEAVE: 'GROUPS_LEAVE',
  GET_MEMBERSHIP: 'GROUPS_GET_MEMBERSHIP',
  TYPES_FETCH: 'GROUPS_TYPES_FETCH',
};

const Actions = {

  fetch: () => {
    const promise = millieApi.groupsFetch();
    return { type: Types.FETCH, _entities: ['groups'], promise };
  },

  get: (id) => {
    const promise = millieApi.groupsGet(id);
    return { type: Types.GET, _entities: ['group', 'nonprofits', 'campaigns', 'volEvents'], promise };
  },

  getMembership: () => {
    const promise = millieApi.groupsMembership();
    return { type: Types.GET_MEMBERSHIP, promise };
  },

  join: (id) => (dispatch, getState) => {
    const promise = millieApi.groupsJoin(id).then(() => {
      return dispatch(Actions.getMembership());
    });
    return dispatch({ type: Types.JOIN, promise });
  },

  leave: (id) => (dispatch, getState) => {
    const promise = millieApi.groupsLeave(id).then(() => {
      return dispatch(Actions.getMembership());
    });
    return dispatch({ type: Types.LEAVE, promise });
  },

  typesFetch: (id) => (dispatch, getState) => {
    const promise = millieApi.groupsTypesFetch();
    return dispatch({ type: Types.TYPES_FETCH, promise, _entities: ['groupTypes'] });
  },

};

export {
  Types,
};

export default Actions;
