import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import PageAx                from 'app/actions/company-admin/page-wallet';
import Icon                  from 'app/components/common/icon';
import Meta                  from 'app/components/common/meta';
import CadminLayout          from 'app/components/company-admin/layout/';
import BudgetEditor          from 'app/components/company-admin/wallet/budget-editor';
import DirectUploadBox       from 'app/components/company-admin/wallet/direct-upload-box';
import ModalManualBankVerify from 'app/components/company-admin/wallet/modal-manual-bank-verify';
import ModalCreditCard       from 'app/components/company-admin/wallet/modal-credit-card';
import ProgramSettings       from 'app/components/company-admin/wallet/program-settings';
import PageLoading           from 'app/components/layout/page-loading';
import format                from 'app/helpers/format';
import prompts               from 'app/prompts';
import CadminSlx             from 'app/selectors/company-admin/';
import PageSlx               from 'app/selectors/company-admin/page-wallet';
import FfSlx                 from 'app/selectors/feature-flags';

class PageCadminWallet extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      ccModalOpen: false,

      /** Set to 'true' if the 'Add an ACH Debit Bank Account' dialog is being
       *  used to accept new account details. */
      creatingManualBank: false,
      /** Set to a BankAccounts object if the 'Add an ACH Debit Bank Account'
       *  dialog is being used to enter micro deposit amounts, or 'null'
       *  otherwise. */
      manualVerifyBankAccount: null,

      /** The ID of the credit card or bank for which the 'This is the default
       *  payment method' message is displayed. This happens when the user
       *  attempts to delete a payment method that is selected in 'Gifts',
       *  'Grants', et cetera. */
      conflictId: null,
      /** The name of the Millie program that caused conflictId to be set. */
      conflictProgram: null,
    };

    this.onClickAddManualBank = this.onClickAddManualBank.bind(this);
    this.onCloseManualBankModal = this.onCloseManualBankModal.bind(this);
    
    this.onClickAddPlaidBank = this.onClickAddPlaidBank.bind(this);

    this.onClickAddCc = this.onClickAddCc.bind(this);
    this.onCloseCcModal = this.onCloseCcModal.bind(this);
  }

  onClickAddPlaidBank() {
    this.setState({conflictId: null});
    this.props.addPlaidBa();
  }

  onClickAddManualBank() {
    this.props.resetManualBa();
    this.setState({ creatingManualBank: true, conflictId: null });
  }

  onCloseManualBankModal() {
    this.setState({ creatingManualBank: false, manualVerifyBankAccount: null });
  }

  onClickAddCc() {
    this.setState({ccModalOpen: true, conflictId: null});
  }

  onCloseCcModal({didCreate} = {}) {
    this.setState({ccModalOpen: false});
    if (didCreate) this.props.fetchCcs();
  }

  async onClickRemoveCc(cc) {
    // const { paymentMethodMatchId: matchId, paymentMethodGrantId: grantId, paymentMethodGiftId: giftId } = this.props.company;
    // if (cc.id === matchId) return this.setState({conflictId: cc.id, conflictProgram: 'match'});
    // if (cc.id === grantId) return this.setState({conflictId: cc.id, conflictProgram: 'grant'});
    // if (cc.id === giftId) return this.setState({conflictId: cc.id, conflictProgram: 'gift'});
    const msg = `Are you sure you want to delete this credit card?\n${cc.name}`;
    const didConfirm = await prompts.confirm({msg, danger: 'Delete'});
    if (didConfirm) {
      this.props.deleteCc(cc.id);
    }
  }

  async onClickRemoveBa(ba) {
    // const { paymentMethodMatchId: matchId, paymentMethodGrantId: grantId, paymentMethodGiftId: giftId } = this.props.company;
    // if (ba.id === matchId) return this.setState({conflictId: ba.id, conflictProgram: 'match'});
    // if (ba.id === grantId) return this.setState({conflictId: ba.id, conflictProgram: 'grant'});
    // if (ba.id === giftId) return this.setState({conflictId: ba.id, conflictProgram: 'gift'});
    const msg = `Are you sure you want to delete this bank account?\n${ba.name}`;
    const didConfirm = await prompts.confirm({msg, danger: 'Delete'});
    if (didConfirm) {
      this.props.deleteBa(ba.id);
    }
  }

  onClickMicroDeposits(bankAccount) {
    this.props.resetManualBa();
    this.setState({ manualVerifyBankAccount: bankAccount, conflictId: null });
  }

  statusBadge(verified) {
    const classes = 'ca-wallet-pm-desc-status ca-wallet-pm-desc-' 
      + (verified ? 'linked' : 'pending');
    return (
      <div className={classes}>
        {verified ? 'Linked' : 'Pending'}
      </div> 
    );
  }

  renderPaymentMethods() {
    const { creditCards, ccsLoading, bankAccounts, basLoading } = this.props;
    const { conflictId, conflictProgram } = this.state;

    return (
      <div className="ca-box">
        <div className="ca-box-header">
          <h1 className="ca-box-header-title">Company Payment Methods</h1>
        </div>
        <div className="ca-box-body">
          {ccsLoading ? <Icon.Loading className="ca-wallet-pm-loading" /> : creditCards.map((cc) => {
            const isConflict = conflictId === cc.id;
            const expiration = moment.utc(`${cc.expYear}-${cc.expMonth}-2`, 'YYYY-M-D').format('MM/YYYY');
            return (
              <div key={cc.id} className="ca-wallet-pm ca-wallet-cc">
                <div className="ca-wallet-pm-data-contain">
                  <div className="ca-wallet-pm-type">Credit Card</div>
                  <div className="ca-wallet-pm-desc">
                    <Icon.CreditCard brand={cc.brand} />
                    <strong>{format.ccBrandName(cc.brand)} **{cc.last4}</strong>
                    <span className="faint">
                      &nbsp; exp: {expiration}
                      &nbsp; zip: {cc.addressZip}
                    </span>
                  </div>
                  {isConflict && (
                    <p className="ca-wallet-pm-conflict">This is the default payment method for your {conflictProgram} program and cannot be deleted until a new default payment is set up. Once you select and Save a different payment method below, you'll be able to delete this one.</p>
                  )}
                  <button className="ca-wallet-pm-delete" onClick={this.onClickRemoveCc.bind(this, cc)}><Icon.Bin /></button>
                </div>
              </div>
            );
          })}
          {basLoading ? <Icon.Loading className="ca-wallet-pm-loading" /> : bankAccounts.map((ba) => {
            const isConflict = conflictId === ba.id;
            return (
              <div key={ba.id} className="ca-wallet-pm ca-wallet-ba">
                <div className="ca-wallet-pm-data-contain">
                  <div className="ca-wallet-pm-type">Bank Account</div>
                  <div className="ca-wallet-pm-desc">
                    <Icon.SavingBank />
                    <strong>{ba.bankName}, {ba.label} **{ba.last4}</strong>
                    {this.statusBadge(ba.verified)}
                  </div>
                  {isConflict && (
                    <p className="ca-wallet-pm-conflict">This is the default payment method for your {conflictProgram} program and cannot be deleted until a new default payment is set up. Once you select and Save a different payment method below, you'll be able to delete this one.</p>
                  )}
                  <button className="ca-wallet-pm-delete" 
                    onClick={this.onClickRemoveBa.bind(this, ba)}>
                    <Icon.Bin />
                  </button>
                </div>
                {!ba.verified && (
                  <button className="ca-wallet-pm-micro-deposits-btn btn blue" 
                    onClick={this.onClickMicroDeposits.bind(this, ba)}>
                    Enter Micro Deposits
                  </button>
                )}
              </div>
            );
          })}
          <div className="ca-wallet-pm-buttons">
            <button className="btn slate" onClick={this.onClickAddCc}>Add Credit Card</button>
            <button className="btn slate" onClick={this.onClickAddManualBank}>Add Bank Account</button>
            <button className="btn slate" onClick={this.onClickAddPlaidBank}>Add Bank via Plaid</button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { company, creditCards, verifiedBankAccounts } = this.props;
    if (!company) return <PageLoading />;
    
    const { ccModalOpen, creatingManualBank, manualVerifyBankAccount } = this.state;

    const balanceAmount = company.balance?.amount || 0;
    const formattedBalance = format.usd(balanceAmount);

    return (
      <CadminLayout className="ca-wallet" company={company} activeItem="wallet">
        <Meta title="Wallet | Millie" />
        <div className="ca-page-header">
          <h1 className="ca-page-header-title">Company Wallet</h1>
          <div className="ca-wallet-balance">
            <div className="ca-wallet-balance-amount">{formattedBalance}</div>
            <div className="ca-wallet-balance-label">Left to Use</div>
          </div>
        </div>
        <div className="ca-wallet-cols">
          <div className="ca-wallet-cols-left">
            {this.renderPaymentMethods()}
            <p className="ca-wallet-program-settings-heading">Which payment method would you like to use for each giving type?</p>
            <ProgramSettings type="match">
              <BudgetEditor editable />
            </ProgramSettings>
            {company.features.gift && (
              <ProgramSettings type="gift" />
            )}
            <ProgramSettings type="grant" />
            {company.features.payroll && (
              <ProgramSettings type="payroll" hideBalanceFirst />
            )}
            {company.features.vol && (
              <ProgramSettings type="dfd" />
            )}
          </div>
          <div className="ca-wallet-cols-right">
            <DirectUploadBox paymentMethods={[...creditCards, ...verifiedBankAccounts]} />
          </div>
        </div>
        {(creatingManualBank || !!manualVerifyBankAccount) && (
          <ModalManualBankVerify verifyBankAccount={manualVerifyBankAccount}
            onClose={this.onCloseManualBankModal} />
        )}
        {ccModalOpen && (
          <ModalCreditCard onClose={this.onCloseCcModal} />
        )}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  creditCards: PageSlx.creditCards(state),
  ccsLoading: PageSlx.ccsLoading(state),

  bankAccounts: PageSlx.bankAccounts(state),
  basLoading: PageSlx.basLoading(state),

  verifiedBankAccounts: PageSlx.verifiedBankAccounts(state),
});

const dispatchToProps = (dispatch) => ({
  fetchCcs: () => dispatch(PageAx.fetchCreditCards()),
  deleteCc: (ccId) => dispatch(PageAx.deleteCreditCard(ccId)),
  deleteBa: (baId) => dispatch(PageAx.deleteBankAccount(baId)),
  addPlaidBa: () => dispatch(PageAx.addPlaidBankAccount()),
  resetManualBa: () => dispatch(PageAx.resetManualBankAccount()),
});

export default connect(stateToProps, dispatchToProps)(PageCadminWallet);
