import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import Icon   from 'app/components/common/icon';
import Select from 'app/components/common/select';
import {
  PaymentMethodTypes,
}             from 'app/constants';


class PaymentMethodInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.renderPaymentMethodOption = this.renderPaymentMethodOption.bind(this);
  }

  renderPaymentMethodOption(pm, isSelected) {
    if (!pm) return (
      <span className="faint">Payment Method...</span>
    );
    const isCc = pm.type === PaymentMethodTypes.CREDIT_CARD;
    const PmIcon = isCc ? Icon.CreditCard : Icon.SavingBank;
    return (
      <div className={`payment-select-option ${isSelected ? 'selected' : ''}`}>
        <PmIcon brand={pm.brand} />
        <span>{pm.name}</span>
      </div>
    );
  }

  render() {
    const { paymentMethods, className, onSelect, selectedPaymentMethod: selectedPm } = this.props;

    return (
      <Select
        className={`payment-select ${className}`}
        options={paymentMethods}
        renderOption={this.renderPaymentMethodOption}
        onSelect={onSelect}
        selectedOption={selectedPm}
      />
    );
  }

}

PaymentMethodInput.propTypes = {
  className: PropTypes.string,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPaymentMethod: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};

PaymentMethodInput.defaultProps = {
  className: '',
  selectedPaymentMethod: null,
};

export default PaymentMethodInput;
