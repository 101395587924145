
import GroupsAx    from 'app/actions/company-admin/groups';
import ToastAx     from 'app/actions/toast';
import history     from 'app/history';
import paths       from 'app/paths';
import CadminSlx   from 'app/selectors/company-admin/';
import BuilderSlx  from 'app/selectors/company-admin/builder-group';

const Types = {
  SET_GROUP: 'CADMIN_GROUP_BUILDER_SET_GROUP',
  SET_GROUP_ID: 'CADMIN_GROUP_BUILDER_SET_GROUP_ID',
  SET_KEY_VAL: 'CADMIN_GROUP_BUILDER_SET_KEY_VAL',
  SET_KEY_VALS: 'CADMIN_GROUP_BUILDER_SET_KEY_VALS',
  ADD_HIGHLIGHT: 'CADMIN_GROUP_BUILDER_ADD_HIGHLIGHT',
  REMOVE_HIGHLIGHT: 'CADMIN_GROUP_BUILDER_REMOVE_HIGHLIGHT',
  UPDATE_HIGHLIGHT: 'CADMIN_GROUP_BUILDER_UPDATE_HIGHLIGHT',
  ADD_STAT: 'CADMIN_GROUP_BUILDER_ADD_STAT',
  REMOVE_STAT: 'CADMIN_GROUP_BUILDER_REMOVE_STAT',
  UPDATE_STAT: 'CADMIN_GROUP_BUILDER_UPDATE_STAT',
  ADD_RESOURCE: 'CADMIN_GROUP_BUILDER_ADD_RESOURCE',
  REMOVE_RESOURCE: 'CADMIN_GROUP_BUILDER_REMOVE_RESOURCE',
  UPDATE_RESOURCE: 'CADMIN_GROUP_BUILDER_UPDATE_RESOURCE',
  SET_NONPROFIT: 'CADMIN_GROUP_BUILDER_SET_NONPROFIT',
  REMOVE_NONPROFIT: 'CADMIN_GROUP_BUILDER_REMOVE_NONPROFIT',
  SET_VOL_EVENT: 'CADMIN_GROUP_BUILDER_SET_VOL_EVENT',
  REMOVE_VOL_EVENT: 'CADMIN_GROUP_BUILDER_REMOVE_VOL_EVENT',
  SET_CAMPAIGN: 'CADMIN_GROUP_BUILDER_SET_CAMPAIGN',
  REMOVE_CAMPAIGN: 'CADMIN_GROUP_BUILDER_REMOVE_CAMPAIGN',
  SAVE: 'CADMIN_GROUP_BUILDER_SAVE',
  SET_HAS_SUBMITTED: 'CADMIN_GROUP_BUILDER_SET_HAS_SUBMITTED',
};

const Actions = {

  setGroupId: (groupId) => {
    return { type: Types.SET_GROUP_ID, groupId };
  },

  setGroup: (group, hasTouched=false) => {
    return { type: Types.SET_GROUP, group, hasTouched };
  },

  setKeyVal: (key, val) => {
    return { type: Types.SET_KEY_VAL, key, val };
  },

  setKeyVals: (keyVals) => {
    return { type: Types.SET_KEY_VALS, keyVals };
  },

  setNonprofit: (nonprofit, index) => {
    return { type: Types.SET_NONPROFIT, nonprofit, index };
  },
  removeNonprofit: (index) => {
    return { type: Types.REMOVE_NONPROFIT, index };
  },

  setVolEvent: (volEvent, index) => {
    return { type: Types.SET_VOL_EVENT, volEvent, index };
  },
  removeVolEvent: (index) => {
    return { type: Types.REMOVE_VOL_EVENT, index };
  },

  setCampaign: (campaign, index) => {
    return { type: Types.SET_CAMPAIGN, campaign, index };
  },
  removeCampaign: (index) => {
    return { type: Types.REMOVE_CAMPAIGN, index };
  },

  addHighlight: () => {
    return { type: Types.ADD_HIGHLIGHT };
  },
  removeHighlight: (index) => {
    return { type: Types.REMOVE_HIGHLIGHT, index };
  },
  updateHighlight: (index, highlight) => {
    return { type: Types.UPDATE_HIGHLIGHT, index, highlight };
  },

  addStat: () => {
    return { type: Types.ADD_STAT };
  },
  removeStat: (index) => {
    return { type: Types.REMOVE_STAT, index };
  },
  updateStat: (index, stat) => {
    return { type: Types.UPDATE_STAT, index, stat };
  },

  addResource: () => {
    return { type: Types.ADD_RESOURCE };
  },
  removeResource: (index) => {
    return { type: Types.REMOVE_RESOURCE, index };
  },
  updateResource: (index, resource) => {
    return { type: Types.UPDATE_RESOURCE, index, resource };
  },

  setHasSubmitted: (hasSubmitted) => {
    return { type: Types.SET_HAS_SUBMITTED, hasSubmitted };
  },

  save: () => (dispatch, getState) => {
    dispatch(Actions.setHasSubmitted(true));
    const state = getState();
    const isValid = BuilderSlx.isValid(state);
    if (!isValid) return;
    const attrs = BuilderSlx.saveAttrs(state);
    const groupId = BuilderSlx.groupId(state);
    const company = CadminSlx.company(state);
    const promise = groupId
      ? dispatch(GroupsAx.update(company.id, groupId, attrs))
      : dispatch(GroupsAx.create(company.id, attrs));
    // redirect to edit page (will trigger a re-fetch)
    promise.then(({group}) => {
      if (groupId) {
        history.push(paths.cadminGroupsEdit(company.slug, group.id));
        dispatch(ToastAx.success('Changes saved.'));
      } else {
        history.push(paths.cadminGroups(company.slug));
        dispatch(GroupsAx.setCreatedId(group.id));
      }
    });
    return dispatch({ type: Types.SAVE, promise, groupId });
  },

};

export {
  Types,
};

export default Actions;
